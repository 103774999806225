import { useDispatch, useSelector } from "react-redux";

import { ActionDropdown } from "../../dropdown";
import { BaseProfileRoute } from "../../../routes/Paths";
import { CloseSideNav } from "../../../redux/NavigationSlice";
import { LogOut } from "../../../redux/UserSlice";
import NavLinkItem from "./NavigationItem";
import { Text } from "../../typography";
import img from "../../../assets/imgs/extreme_logo.svg";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const NavigationAction = () => {
  const { data } = useSelector((state) => state.user);
  const { firstName } = data.user;
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const onLogoOut = () => {
    navigation("/logout");
    dispatch(LogOut());
    dispatch(CloseSideNav());
  };

  return (
    <ActionDropdown
      iconTitle={<Img src={img} alt={`${firstName} profile`} />}
      title="Account"
    >
      <NavLinkItem path={BaseProfileRoute} name="View your profile" />
      <a href="http://www.excleanenviro.com" target="_blank" rel="noreferrer">
        Go to website
      </a>
      <Text onClick={onLogoOut}>Log Out</Text>
    </ActionDropdown>
  );
};

export default NavigationAction;

const Img = styled.img`
  background-color: white;
`;
