import { Layout, Section } from "../../components/layout";

import { AppLinks } from "../../components/links";
import { Title } from "../../components/typography";

const LogoutPage = () => {
  return (
    <Layout>
      <Section>
        <Title number={2}>Logout Successful</Title>
      </Section>
      <AppLinks to="/">Return to login page</AppLinks>
    </Layout>
  );
};

export default LogoutPage;
