import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { MediaBaseRouteDelete } from "./MediaSlice";
import { eces } from "../api";
import { onAlert } from "../utils/onAlerts";

const BASEPATH = "companyapi/api/v1/staffs";
const INITIALSTATE = {
  isSuccess: false,
  errMessage: "",
  loading: true,
  data: [],
};

export const FetchStaffMembers = createAsyncThunk(
  "staff/fetches",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.get(BASEPATH);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const CreateStaff = createAsyncThunk(
  "staff/create",
  async (args, { rejectWithValue }) => {
    const {
      firstName,
      middleName,
      lastName,
      title,
      mainContent,
      phone,
      email,
      files,
    } = args;

    const formData = new FormData();
    formData.append("firstName", firstName || "");
    formData.append("middleName", middleName || "");
    formData.append("lastName", lastName || "");
    formData.append("title", title || "");
    formData.append("mainContent", mainContent || "");
    formData.append("phone", phone || "");
    formData.append("email", email || "");
    formData.append("mediaType", "IMAGE");
    if (files) {
      for (const file of files) {
        formData.append("files", file.file);
      }
    }

    try {
      const response = await eces.post(BASEPATH, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const FetchStaff = createAsyncThunk(
  "staff/fetch",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.get(`${BASEPATH}/${args.id}`, args.value);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const EditStaff = createAsyncThunk(
  "staff/update",
  async (args, { rejectWithValue }) => {
    const {
      firstName,
      middleName,
      lastName,
      title,
      mainContent,
      phone,
      email,
      files,
    } = args.values;

    const formData = new FormData();
    formData.append("firstName", firstName || "");
    formData.append("middleName", middleName || "");
    formData.append("lastName", lastName || "");
    formData.append("title", title || "");
    formData.append("mainContent", mainContent || "");
    formData.append("phone", phone || "");
    formData.append("email", email || "");
    formData.append("mediaType", "IMAGE");
    if (files) {
      for (const file of files) {
        formData.append("files", file.file);
      }
    }

    try {
      const response = await eces.patch(`${BASEPATH}/${args.id}`, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const DeleteStaff = createAsyncThunk(
  "staff/delete",
  async (args, { rejectWithValue, getState }) => {
    try {
      const response = await eces.delete(`${BASEPATH}/${args}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);
export const DeleteStaffPictures = createAsyncThunk(
  "staff/delete/pictures",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.delete(
        MediaBaseRouteDelete(args.name, args.fileName)
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const staffReducer = createSlice({
  name: "staff",
  initialState: INITIALSTATE,
  extraReducers: {
    [FetchStaffMembers.pending]: (state) => {
      state.loading = true;
    },
    [FetchStaffMembers.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.isSuccess = true;
    },
    [FetchStaffMembers.rejected]: (state, action) => {
      state.loading = false;
      state.isSuccess = false;
      state.errMessage = action.payload;
    },
    [FetchStaff.pending]: (state) => {
      state.loading = true;
    },
    [FetchStaff.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.isSuccess = true;
    },
    [FetchStaff.rejected]: (state, action) => {
      state.loading = false;
      state.isSuccess = false;
      state.errMessage = action.payload;
    },
    [CreateStaff.fulfilled]: (state, action) => {
      state.loading = false;
      state.isSuccess = true;
      state.errMessage = action.payload;
    },
    [EditStaff.fulfilled]: (state, action) => {
      state.loading = false;
      state.isSuccess = true;
      state.errMessage = action.payload;
    },
    [DeleteStaff.fulfilled]: (state) => {
      state.loading = false;
      state.isSuccess = true;
    },
    [DeleteStaffPictures.fulfilled]: (state) => {
      state.loading = false;
      state.isSuccess = true;
      onAlert("success", "Image Deleted");
    },
  },
});

export default staffReducer.reducer;
