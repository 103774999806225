import { CardItem, Cards } from "../../components/cards";
import {
  CheckboxInput,
  CustomTextArea,
  FormButton,
  FormContainer,
  Input,
  Label,
  RichTextField,
} from "../../components/forms";
import { Field, Form } from "react-final-form";
import { useLocation, useNavigate } from "react-router-dom";

import { Grid } from "../../components/layout";
import { ImageUploadComponent } from "../../components/imageUpload";
import { PartnerBaseRoute } from "../../routes/Paths";

const CreateForm = (props) => {
  const { onFormSubmit, initialValues, onHandleImageDelete } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const onSubmit = (values) => {
    onFormSubmit(values);
  };

  const onCloseandReset = (reset) => {
    reset();

    const direction = location.pathname.split("/").includes("edit")
      ? navigate(-1, { replace: true })
      : navigate(PartnerBaseRoute, { replace: true });
    return direction;
  };

  return (
    <>
      <Form onSubmit={onSubmit} initialValues={initialValues}>
        {({ handleSubmit, pristine, submitting, invalid, form: { reset } }) => (
          <form onSubmit={handleSubmit}>
            <Grid col="l15">
              <Cards title="Partner and Industry Form" version="2">
                <CardItem version="5">
                  <FormContainer>
                    <Field
                      name="type"
                      type="text"
                      component={Input}
                      label="Industry"
                    />
                  </FormContainer>
                  <FormContainer>
                    <Field
                      label="Describe Industry"
                      name="company"
                      type="text"
                      component={RichTextField}
                    />
                  </FormContainer>
                  <FormContainer>
                    <Field
                      label="Describe Organization Relationship"
                      name="subContent"
                      type="text"
                      component={CustomTextArea}
                    />
                  </FormContainer>
                  <FormContainer>
                    <Field
                      label="Descirbe the value you bring to this industry"
                      name="values"
                      type="text"
                      component={CustomTextArea}
                    />
                  </FormContainer>
                  <FormContainer>
                    <Field
                      label="Content"
                      name="mainContent"
                      type="text"
                      component={CustomTextArea}
                    />
                  </FormContainer>
                  <FormContainer>
                    <Field
                      label="Featured"
                      name="featured"
                      type="checkbox"
                      component={CheckboxInput}
                    />
                  </FormContainer>{" "}
                </CardItem>
              </Cards>
              <FormContainer>
                <Label>Upload Images</Label>
                <Field name="files">
                  {(props) => {
                    return (
                      <ImageUploadComponent
                        name={props.input.name}
                        files={props.input.value}
                        onupdatefiles={props.input.onChange}
                        onremoveImage={onHandleImageDelete}
                        extraProp={initialValues?.media}
                        maxUpload={1}
                      />
                    );
                  }}
                </Field>
              </FormContainer>
            </Grid>
            <FormButton
              disabled={submitting || pristine || invalid}
              onCancelFunc={() => onCloseandReset(reset)}
              btnMessage={submitting ? "Saving" : "Save"}
            />
          </form>
        )}
      </Form>
    </>
  );
};

export default CreateForm;
