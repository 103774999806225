import { StaffBaseRouteId, StaffCreateRoute } from "../../routes/Paths";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "../../components/button";
import { FetchStaffMembers } from "../../redux/StaffSlice";
import { ImageCircle } from "../../components/images";
import { IoPencilOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { Loader } from "../../components/loader";
import { Section } from "../../components/layout";
import { Table } from "../../components/table";
import styled from "styled-components";
import { useEffect } from "react";

const StaffListPage = () => {
  const { data, loading } = useSelector((state) => state.staff);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchStaffMembers());
  }, [dispatch]);

  if (loading) {
    return <Loader />;
  }

  const columns = [
    {
      cell: (row) => (
        <ImageCircle
          img={
            row.media &&
            row.media.mediaFiles &&
            row.media.mediaFiles[0] &&
            row.media.mediaFiles[0].url
          }
        />
      ),
      allowOverflow: true,
      button: false,
      width: "56px",
    },
    {
      name: "Staff",
      sortable: true,
      selector: (row) => ` ${row.firstName}  ${row.lastName}`,
    },
    {
      name: "Title",
      sortable: true,
      selector: (row) => `${row.title}`,
    },
    {
      name: "Email",
      sortable: true,

      selector: (row) => row.email,
    },
    {
      name: "Phone",
      sortable: true,

      selector: (row) => row.phone,
    },
    {
      cell: (row) => (
        <DataRowActionsContainer>
          <Link to={StaffBaseRouteId(row.id)} state={{ user: row }}>
            <IoPencilOutline />
          </Link>
        </DataRowActionsContainer>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];

  return (
    <>
      <Section>
        <SubNav>
          <Button type="link" path={StaffCreateRoute}>
            Add Staff
          </Button>
        </SubNav>
        <Table data={data} columns={columns} defaultSortFieldId="createdDate" />
      </Section>
    </>
  );
};

export default StaffListPage;

const DataRowActionsContainer = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0;
    padding: 0;
  }

  svg {
    margin-right: 1rem;
    cursor: pointer;
  }
`;

const SubNav = styled.nav`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 2rem;

  li {
    margin-left: 1.5rem;
    display: flex;
    align-items: center;
  }
`;
