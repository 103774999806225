import {
  PartnerEditPage,
  PartnerListPage,
  PartnerPages,
  PartnerProfileCreate,
} from "../pages";

import { Route } from "react-router-dom";

export const PartnersRoutes = () => {
  return (
    <Route path="/auth/industry-partners" element={<PartnerPages />}>
      <Route index element={<PartnerListPage />} />
      <Route path="create" element={<PartnerProfileCreate />} />
      <Route path="edit/:id" element={<PartnerEditPage />} />
    </Route>
  );
};
