import { Text, Title } from "../typography";

import { ImageSquare } from "../images";
import { colors } from "../../assets/styles/parameters";
import styled from "styled-components";

export const Profile = ({ image, name, title, badge, department }) => {
  return (
    <BaseProfile>
      <ImageSquare img={image} imageAlt="Officer Headshot" />
      <div>
        <ProfileMeta>
          <Title number={6}>{name}</Title>
          <Text scale="sm">
            {title} | Badge # {badge}
          </Text>
        </ProfileMeta>
        <ProfileMeta>
          <Text scale="sm">{department}</Text>
        </ProfileMeta>
        <ProfileMeta>
          <Text scale="sm">Number of Reports: 30</Text>
        </ProfileMeta>
      </div>
    </BaseProfile>
  );
};

const BaseProfile = styled.div`
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  background-color: ${colors.white};
  border-radius: 10px;

  img {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const ProfileMeta = styled.div`
  border-bottom: 1px solid ${colors.gray300};
  padding: 1rem 1rem;

  p {
    margin: 0;
    padding: 0;
  }
`;
