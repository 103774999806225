import { Layout, PageHeader } from "../../components/layout";

import { Outlet } from "react-router-dom";

const TestimonyPage = () => {
  return (
    <>
      <PageHeader title="Testimonials" />
      <Layout>
        <Outlet />
      </Layout>
    </>
  );
};

export default TestimonyPage;
