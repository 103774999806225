import { Container } from "../../components/layout";
import { Link } from "react-router-dom";
import { Title } from "../../components/typography";
import logo from "../../assets/imgs/extreme_logo.svg";
import styled from "styled-components";

const Page404 = () => {
  return (
    <MainError>
      <Container width={70}>
        <Title number={1} />
        <Link to="/">Something bad happened! Click Here to Go Home.</Link>
      </Container>
    </MainError>
  );
};

export default Page404;

const MainError = styled.main`
  background: url(${logo}) no-repeat;
  background-size: cover;
  background-position: bottom left;
  position: absolute;
  padding: 4rem 4rem;
  background-color: white;
  height: 100vh;
  width: 100%;
  z-index: 1000;

  * {
    margin: 0;
    padding: 0;
  }
`;
