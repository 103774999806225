import { Container, Grid } from "../../components/layout";
import { Field, Form } from "react-final-form";
import { FormButton, FormContainer, Input } from "../../components/forms";
import { useLocation, useNavigate } from "react-router-dom";

import { Cards } from "../../components/cards";

export const ProfileForm = (props) => {
  const { onFormSubmit, initialValues } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const onCloseandReset = (reset) => {
    reset();

    const direction = location.pathname.split("/").includes("edit")
      ? navigate(-1, { replace: true })
      : navigate("/auth/profile", { replace: true });
    return direction;
  };
  return (
    <Form onSubmit={onFormSubmit} initialValues={initialValues}>
      {({
        handleSubmit,
        submitting,
        pristine,
        invalid,
        form: {
          reset,
          mutators: { push },
        },
      }) => (
        <form onSubmit={handleSubmit}>
          <Cards version="2">
            <Container width="60">
              <Grid>
                <FormContainer>
                  <Field
                    name="firstName"
                    type="text"
                    component={Input}
                    label="First Name"
                  />
                </FormContainer>
                <FormContainer>
                  <Field
                    name="lastName"
                    type="text"
                    component={Input}
                    label="Last Name"
                  />
                </FormContainer>
                <FormContainer>
                  <Field
                    name="email"
                    type="email"
                    component={Input}
                    label="Email"
                  />
                </FormContainer>
              </Grid>
              <FormButton
                disabled={submitting || pristine || invalid}
                onCancelFunc={() => onCloseandReset(reset)}
                btnMessage={submitting ? "Saving" : "Save"}
              />
            </Container>
          </Cards>
        </form>
      )}
    </Form>
  );
};
