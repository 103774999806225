import { CreateCompany } from "../../redux/CompanySlice";
import { OrgBaseRoute } from "../../routes/Paths";
import OrgForm from "./OrgForm";
import { Section } from "../../components/layout";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const OrgProfileCreate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onFormSubmit = (values) => {
    if (window.confirm(`Are you sure you want save this information`)) {
      dispatch(CreateCompany(values));
      setTimeout(() => {
        navigate(OrgBaseRoute);
      }, 2000);
    }
  };

  return (
    <Section>
      <OrgForm onFormSubmit={onFormSubmit} />
    </Section>
  );
};

export default OrgProfileCreate;
