import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { MediaBaseRouteDelete } from "./MediaSlice";
import { eces } from "../api";
import { onAlert } from "../utils/onAlerts";

const BASEPATH = "companyapi/api/v1/partners";
const INITIALSTATE = {
  isSuccess: false,
  errMessage: "",
  loading: true,
  data: [],
};

export const FetchPartners = createAsyncThunk(
  "partners/fetches",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.get(BASEPATH);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const CreatePartner = createAsyncThunk(
  "partner/create",
  async (args, { rejectWithValue, getState }) => {
    const { files, type, company, subContent, mainContent, values, featured } =
      args;

    const formData = new FormData();
    formData.append("type", type || "");
    formData.append("company", company || "");
    formData.append("subContent", subContent || "");
    formData.append("values", values || "");
    formData.append("mainContent", mainContent || "");
    formData.append("featured", featured || false);
    formData.append("mediaType", "IMAGE");
    if (files) {
      for (const file of files) {
        formData.append("files", file.file);
      }
    }

    try {
      const response = await eces.post(`${BASEPATH}`, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const FetchPartner = createAsyncThunk(
  "partner/fetch",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.get(`${BASEPATH}/${args}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const EditPartner = createAsyncThunk(
  "partner/update",
  async (args, { rejectWithValue }) => {
    const { files, type, company, subContent, mainContent, values, featured } =
      args.values;

    const formData = new FormData();
    formData.append("type", type || "");
    formData.append("company", company || "");
    formData.append("subContent", subContent || "");
    formData.append("values", values || "");
    formData.append("mainContent", mainContent || "");
    formData.append("featured", featured);
    formData.append("mediaType", "IMAGE");
    if (files) {
      for (const file of files) {
        formData.append("files", file.file);
      }
    }

    try {
      const response = await eces.patch(`${BASEPATH}/${args.id}`, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const DeletePartner = createAsyncThunk(
  "partner/delete",
  async (args, { rejectWithValue, getState }) => {
    try {
      const response = await eces.delete(`${BASEPATH}/${args}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const DeletePartnerPictures = createAsyncThunk(
  "partner/delete/pictures",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.delete(
        MediaBaseRouteDelete(args.name, args.fileName)
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const partnerSlice = createSlice({
  name: "partners",
  initialState: INITIALSTATE,
  extraReducers: {
    [FetchPartners.pending]: (state) => {
      state.loading = true;
    },
    [FetchPartners.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.isSuccess = true;
    },
    [FetchPartners.rejected]: (state, action) => {
      state.loading = false;
      state.isSuccess = false;
      state.errMessage = action.payload;
    },
    [FetchPartner.pending]: (state) => {
      state.loading = true;
    },
    [FetchPartner.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.isSuccess = true;
    },
    [FetchPartner.rejected]: (state, action) => {
      state.loading = false;
      state.isSuccess = false;
      state.errMessage = action.payload;
    },
    [CreatePartner.fulfilled]: (state, action) => {
      state.loading = false;
      state.isSuccess = true;
      state.errMessage = action.payload;
      onAlert("success", "Partner Created");
    },
    [EditPartner.fulfilled]: (state, action) => {
      state.loading = false;
      state.isSuccess = true;
      state.errMessage = action.payload;
      onAlert("success", "Partner Info Updated");
    },
    [DeletePartner.fulfilled]: (state) => {
      state.loading = false;
      state.isSuccess = true;
      onAlert("success", "Partner Info Deleted");
    },
    [DeletePartnerPictures.fulfilled]: (state) => {
      state.loading = false;
      state.isSuccess = true;
      onAlert("success", "Image Deleted");
    },
  },
});

export default partnerSlice.reducer;
