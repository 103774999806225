import { CardItem, Cards } from "../../components/cards";
import { CredBaseRoute, CredEditRoute } from "../../routes/Paths";
import { useDispatch, useSelector } from "react-redux";

import { AppLinks } from "../../components/links";
import { FetchCreds } from "../../redux/CompCredSlice";
import { Text } from "../../components/typography";
import { useEffect } from "react";

const CredentialsElement = () => {
  const { creds, loading } = useSelector((state) => state.cred);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchCreds());
  }, [dispatch]);

  return (
    <Cards
      title="Credentials & Recognitions"
      version="2"
      action={<AppLinks to={CredBaseRoute}>View All</AppLinks>}
    >
      {loading ? (
        <Text align="center">Loading...</Text>
      ) : (
        creds
          ?.filter((s, i) => s && i < 5)
          ?.map((s) => {
            const { id, title, media } = s;
            return (
              <CardItem
                key={id}
                version="2"
                text={title}
                img={media?.mediaFiles[0]?.url}
                path={CredEditRoute(id)}
                state={{ cred: s }}
              />
            );
          })
      )}
    </Cards>
  );
};

export default CredentialsElement;
