import { Grid, Section } from "../../components/layout";

const SettingsInfoPage = () => {
  return (
    <Section>
      <Grid col="l14">
        <div>Lorem10</div>
        <div>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae,
          necessitatibus!
        </div>
      </Grid>
      <div></div>
    </Section>
  );
};

export default SettingsInfoPage;
