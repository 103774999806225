import { colors } from "../../assets/styles/parameters";
import styled from "styled-components";

export const TableExpandContainer = ({ main, sub }) => {
  return (
    <DataTableExpandableContainer>
      <>{main}</>

      <BaseExpandeableActionsContainer>{sub}</BaseExpandeableActionsContainer>
    </DataTableExpandableContainer>
  );
};

export default TableExpandContainer;

const DataTableExpandableContainer = styled.div`
  padding: 2rem 3.5rem;
`;

const BaseExpandeableActionsContainer = styled.div`
  border-top: 1px solid ${colors.gray300};
  padding-top: 0.5rem;
  margin-top: 1rem;

  & > div {
    display: flex;
    cursor: pointer;

    div {
      margin-right: 1.5rem;
      font-size: 0.8rem;
    }
  }
`;
