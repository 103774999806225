import styled from "styled-components";

export const RowActionsContainer = ({ children, ...otherProps }) => {
  return (
    <DataRowActionsContainer {...otherProps}>
      {children}
    </DataRowActionsContainer>
  );
};

export default RowActionsContainer;

const DataRowActionsContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 1rem;
    cursor: pointer;

    &:is(:hover, :focus) {
      color: red;
    }
  }
`;
