import { CardItem, Cards } from "../../components/cards";
import {
  CheckboxInput,
  CustomTextArea,
  FormButton,
  FormContainer,
  Input,
} from "../../components/forms";
import { Field, Form } from "react-final-form";
import { useLocation, useNavigate } from "react-router-dom";

import { Grid } from "../../components/layout";
import { ImageUploadComponent } from "../../components/imageUpload";
import { Label } from "../../components/forms";

const GalleryForm = (props) => {
  const { onFormSubmit, initialValues, onHandleImageDelete } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const onSubmit = (values) => {
    onFormSubmit(values);
  };

  const onCloseandReset = (reset) => {
    reset();

    const direction = location.pathname.split("/").includes("edit")
      ? navigate(-1, { replace: true })
      : navigate("/auth/gallery", { replace: true });
    return direction;
  };

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit, pristine, submitting, invalid, form: { reset } }) => (
        <form onSubmit={handleSubmit}>
          <Grid col="l15">
            <FormContainer>
              <Label>Upload Images</Label>
              <Field name="files">
                {(props) => {
                  return (
                    <ImageUploadComponent
                      name={props.input.name}
                      files={props.input.value}
                      onupdatefiles={props.input.onChange}
                      onremoveImage={onHandleImageDelete}
                      extraProp={initialValues?.media}
                    />
                  );
                }}
              </Field>
            </FormContainer>
            <Cards title="Gallery Post Form" version="2">
              <CardItem version="5">
                <FormContainer>
                  <Field
                    name="name"
                    type="text"
                    component={Input}
                    label="Project Name"
                    placeholder="What is the project name?"
                  />
                </FormContainer>
                <FormContainer>
                  <Field
                    name="description"
                    type="text"
                    component={CustomTextArea}
                    label="Project Desciption"
                    placeholder="Describe this project."
                  />
                </FormContainer>
                <FormContainer>
                  <Field
                    label="Featured"
                    name="featured"
                    type="checkbox"
                    component={CheckboxInput}
                  />
                </FormContainer>
              </CardItem>
            </Cards>
          </Grid>
          <FormButton
            disabled={submitting || pristine || invalid}
            onCancelFunc={() => onCloseandReset(reset)}
            btnMessage={submitting ? "Saving" : "Save"}
          />
        </form>
      )}
    </Form>
  );
};

export default GalleryForm;
