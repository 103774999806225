import {
  DeleteGalleryPictures,
  DeleteGalleryProject,
  EditGalleryProject,
} from "../../redux/GallerySlice";

import GalleryForm from "./GalleryForm";
import { IoTrash } from "react-icons/io5";
import { Section } from "../../components/layout";
import { Text } from "../../components/typography";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const GalleryEditPage = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();

  const onFormSubmit = (values) => {
    if (window.confirm(`Are you sure you want to save these edits?`)) {
      const args = {
        id: state.gallery.id,
        values: values,
      };
      dispatch(EditGalleryProject(args));
    }
  };

  const onDeleteSubmit = () => {
    if (
      window.confirm(`Are you sure you want to delete ${state.gallery.title}?`)
    ) {
      dispatch(DeleteGalleryProject(state.gallery.id));
    }
  };

  const onHandleImageDelete = (fileName) => {
    console.log(state);
    const arg = {
      name: state.gallery?.media?.name,
      fileName: fileName,
    };
    dispatch(DeleteGalleryPictures(arg));
  };

  return (
    <Section>
      <SubNav>
        <li onClick={onDeleteSubmit}>
          <IoTrash />
          <Text>Delete</Text>
        </li>
      </SubNav>
      <GalleryForm
        initialValues={state.gallery}
        onFormSubmit={onFormSubmit}
        onHandleImageDelete={onHandleImageDelete}
      />
    </Section>
  );
};

export default GalleryEditPage;

const SubNav = styled.nav`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 2rem;

  li {
    margin-left: 1.5rem;
    display: flex;
    align-items: center;
    color: red;

    p {
      margin: 0;
      padding: 0 0 0 0.5rem;
      color: red;
    }

    &:hover {
      cursor: pointer;
    }
  }
`;
