import { EditService, FetchServices } from "../../redux/ServiceSlice";
import { IoCheckboxOutline, IoCloseSharp } from "react-icons/io5";
import { ServiceCreateRoute, ServiceEditRoute } from "../../routes/Paths";
import { useDispatch, useSelector } from "react-redux";

import { AppLinks } from "../../components/links";
import { Button } from "../../components/button";
import { IoPencilOutline } from "react-icons/io5";
import { Section } from "../../components/layout";
import { Table } from "../../components/table";
import { onFormatDateTime } from "../../utils/onFormatDateTime";
import styled from "styled-components";
import { useEffect } from "react";

const ServiceListPage = () => {
  const { data } = useSelector((state) => state.services);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchServices());
  }, [dispatch]);

  const onUpdatedFeature = (values) => {
    const id = values.id;
    const featured = values.featured;

    const value = { ...values, featured: !featured };
    dispatch(EditService({ id, value }));
  };

  const columns = [
    {
      name: "Contacted Date",
      selector: ({ createdDate }) => onFormatDateTime(createdDate),
      sortable: true,
      width: "200px",
    },
    {
      name: "Service",
      sortable: true,
      selector: (row) => row.title,
      maxWidth: "500px",
    },

    {
      name: "Featured",
      sortable: true,
      center: true,
      selector: (row) => (
        <div onClick={() => onUpdatedFeature(row)}>
          {row.featured ? <IoCheckboxOutline /> : <IoCloseSharp />}
        </div>
      ),
    },
    {
      cell: (row) => (
        <DataRowActionsContainer>
          <AppLinks to={ServiceEditRoute(row.id)} state={{ service: row }}>
            <IoPencilOutline />
          </AppLinks>
        </DataRowActionsContainer>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];
  return (
    <>
      <Section>
        <SubNav>
          <Button type="link" path={ServiceCreateRoute}>
            Add Service
          </Button>
        </SubNav>
        <Table data={data} columns={columns} defaultSortFieldId="createdDate" />
      </Section>
    </>
  );
};

export default ServiceListPage;

const DataRowActionsContainer = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0;
    padding: 0;
  }

  svg {
    margin-right: 1rem;
    cursor: pointer;
  }
`;

const SubNav = styled.nav`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 2rem;

  li {
    margin-left: 1.5rem;
  }
`;
