import styled from "styled-components";

export const ImageCircle = ({ img, description }) => {
  return (
    <BaseImageCircle>
      <img src={img} alt={description} />
    </BaseImageCircle>
  );
};

const BaseImageCircle = styled.div`
  img {
    display: block;
    width: 40px;
    clip-path: circle();
  }
`;

export const ImageSquare = ({ img, imageAlt, scale }) => {
  return (
    <BaseFigure scale={scale}>
      <img src={img} alt={imageAlt} />
    </BaseFigure>
  );
};

const BaseFigure = styled.figure`
  border-radius: 10px;
  overflow: hidden;

  img {
    height: 258px;
    object-fit: cover;
  }
`;
