import { CardItem, Cards } from "../../components/cards";
import { Grid, Section } from "../../components/layout";
import { Text, Title } from "../../components/typography";
import { useDispatch, useSelector } from "react-redux";

import { AppLinks } from "../../components/links";
import { BookingEditRoute } from "../../routes/Paths";
import { FetchBooking } from "../../redux/BookingSlice";
import { Loader } from "../../components/loader";
import { Status } from "../../components/status";
import { onFormatDateTime } from "../../utils/onFormatDateTime";
import styled from "styled-components";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const BookingDetailPage = () => {
  let dispatch = useDispatch();
  let params = useParams();

  const { booking, loading } = useSelector((state) => state.bookings);

  useEffect(() => {
    dispatch(FetchBooking(params.id));
  }, [dispatch, params.id]);

  if (loading) {
    return <Loader />;
  }

  const {
    client,
    additionalComments,
    date,
    time,
    bookingId,
    location,
    services,
    status,
  } = booking;

  return (
    <Section>
      <BaseHeader>
        {status && (
          <Status
            text={status}
            color={
              status === "PENDING"
                ? "red"
                : status === "IN_PROGRESS"
                ? "yellow"
                : "green"
            }
          />
        )}
      </BaseHeader>
      <Grid col="l15">
        <Cards
          version="2"
          title={`Order Detail. No. ${bookingId}`}
          action={
            <BaseSpace>
              {booking !== {} && (
                <AppLinks
                  to={BookingEditRoute(booking.id)}
                  state={{ booking: booking }}
                >
                  Edit
                </AppLinks>
              )}
            </BaseSpace>
          }
        >
          <CardItem>
            <Title number={6}>Selected Services</Title>
            {services &&
              services.map((s, i) => {
                return <Text key={i}>{s}</Text>;
              })}
          </CardItem>
          <Grid>
            <CardItem>
              <Title number={6}>Project Date</Title>

              {onFormatDateTime(date)}
            </CardItem>
            <CardItem>
              <Title number={6}>Project Time</Title>
              {onFormatDateTime(time, "time")}
            </CardItem>
          </Grid>
          {location && (
            <CardItem>
              <Title number={6}>Project Location</Title>
              {location.street && location.street}
              <br />
              {`${location.city && location.city}, ${
                location.state && location.state
              } ${location.zipcode && location.zipcode}`}
            </CardItem>
          )}
          <CardItem>
            <Title number={6}>Project Note</Title>
            {additionalComments}
          </CardItem>
        </Cards>

        <div>
          {client && (
            <>
              <Cards version="2" title="Customer Detail">
                <CardItem version="5">
                  <Text>Name: {`${client.firstName} ${client.lastName}`}</Text>
                  {client.organization && (
                    <Text>Organization: {client.organization}</Text>
                  )}
                  {client.title && <Text>Title: {client.title}</Text>}
                  <Text>
                    Email: <a href={`mailto:${client.email}`}>{client.email}</a>
                  </Text>
                  <Text>
                    Phone Number:{" "}
                    <a href={`tel:+${client.phone}`}>{client.phone}</a>
                  </Text>
                </CardItem>
              </Cards>
              <Cards version="2" title="Client Note">
                <CardItem version="5">
                  <Text>{client.notes}</Text>
                </CardItem>
              </Cards>
            </>
          )}
        </div>
      </Grid>
    </Section>
  );
};

export default BookingDetailPage;

// const BaseList = styled.div`
//   p {
//     margin: 0;
//     font-size: 0.7rem;
//   }
//   div {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//   }
// `;

const BaseSpace = styled.div`
  a:first-child {
    margin-right: 1rem;
  }
`;

const BaseHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  font-size: 0.6rem;
`;
