import { toast } from "react-toastify";

export const onAlert = (type, message, time) => {
  toast(message, {
    type: type,
    position: "bottom-right",
    autoClose: () => (time ? time : 1000),
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};
