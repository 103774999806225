import { Text, Title } from "../../typography";
import { colors, responsiveness } from "../../../assets/styles/parameters";

import { DashCardItem } from "../../cards";
import Navigation from "../navigation/Navigation";
import styled from "styled-components";

const Header = ({ data }) => {
  const { firstName } = data.user && data.user.data.user;

  return (
    <BaseHeader>
      <Navigation />
      <HeaderContent>
        <Title number={1}>
          Hi, {data.user.data.user && firstName ? firstName : "there"}
        </Title>
        <Text scale="small">Welcome to your Extreme Clean dashboard</Text>
      </HeaderContent>
      <HeaderSubContent>
        <Title number={4}>Summary</Title>
        <ul>
          {data.contact.contacts && (
            <DashCardItem
              title="Messages"
              data={data.contact.contacts.length}
            />
          )}
          {data.bookings.bookings && (
            <DashCardItem
              title="Bookings Requests"
              data={data.bookings.bookings.length}
            />
          )}
          {data.clients.data && (
            <DashCardItem title="Contacts" data={data.clients.data.length} />
          )}
          {data.testimony.testimonies && (
            <DashCardItem
              title="Testimonials"
              data={data.testimony.testimonies.length}
            />
          )}
        </ul>
      </HeaderSubContent>
    </BaseHeader>
  );
};

export default Header;

const BaseHeader = styled.header`
  grid-area: header;
  background-color: ${colors.blueDark2};
  height: 47.1vh;
  padding: 0.5rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (max-width: ${responsiveness.ipadMin}) {
    padding: 0.5rem 1rem 2rem;
    height: 100%;
  }
`;

const HeaderContent = styled.div`
  * {
    color: ${colors.white};
  }

  @media only screen and (max-width: ${responsiveness.ipadMin}) {
    margin: 4rem 0 2rem;
  }
`;

const HeaderSubContent = styled.div`
  display: flex;
  width: 50%;

  justify-content: space-between;
  color: ${colors.white};

  ul {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  @media only screen and (max-width: ${responsiveness.mobile1}) {
    width: 100%;
    margin: 0;
    flex-wrap: wrap;

    ul {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;
