import { Layout, PageHeader } from "../../components/layout";

import { Outlet } from "react-router-dom";

const FAQPage = () => {
  return (
    <>
      <PageHeader title="FAQ" />
      <Layout>
        <Outlet />
      </Layout>
    </>
  );
};

export default FAQPage;
