import { DeleteBooking, EditBooking } from "../../redux/BookingSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import BookingForm from "./BookingForm";
import { FetchServices } from "../../redux/ServiceSlice";
import { IoTrash } from "react-icons/io5";
import { Section } from "../../components/layout";
import { ServiceBaseRoute } from "../../routes/Paths";
import { Text } from "../../components/typography";
import styled from "styled-components";
import { useEffect } from "react";

const BookingEditPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { services } = useSelector((state) => state);

  useEffect(() => {
    dispatch(FetchServices());
  }, [dispatch]);

  const onFormSubmit = (values) => {
    if (window.confirm(`Are you sure you want to save these edits?`)) {
      const newValues = {
        client: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phone: values.phone,
          title: values.title,
          organization: values.organization,
        },
        location: {
          street: values.street,
          city: values.city,
          state: values.state,
          zipcode: values.zipcode,
        },
        services: values.service,
        date: values.date,
        status: values.status,
        additionalComments: values.additionalComments,
      };
      dispatch(EditBooking({ id: state.booking.id, value: newValues }));
    }
  };

  const onDeleteSubmit = (id) => {
    if (window.confirm(`Are you sure you want to delete this booking? `)) {
      dispatch(DeleteBooking(id));
    }
  };

  const { location, client } = state.booking;
  const InitialValues = {
    ...state.booking,
    firstName: client?.firstName,
    lastName: client?.lastName,
    organization: client?.organization,
    title: client?.title,
    email: client?.email,
    phone: client?.phone,
    street: location?.street,
    city: location?.city,
    state: location?.state,
    zipcode: location?.zipcode,
  };

  return (
    <Section>
      <SubNav>
        <li onClick={() => onDeleteSubmit(state.booking.id)}>
          <IoTrash />
          <Text>Delete</Text>
        </li>
      </SubNav>
      <BookingForm
        initialValues={InitialValues}
        onServicesList={services.data}
        onFormSubmit={onFormSubmit}
        onCancel={() => navigate(ServiceBaseRoute)}
      />
    </Section>
  );
};

export default BookingEditPage;

const SubNav = styled.nav`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 2rem;

  li {
    margin-left: 1.5rem;
    display: flex;
    align-items: center;
    color: red;

    p {
      margin: 0;
      padding: 0 0 0 0.5rem;
      color: red;
    }

    &:hover {
      cursor: pointer;
    }
  }
`;
