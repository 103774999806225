import {
  BlogCreateRoute,
  BookingCreateRoute,
  ClientCreateRoute,
  PartnerCreateRoute,
  StaffCreateRoute,
  TestimonyCreateRoute,
} from "../../../routes/Paths";

import { ActionDropdownLarge } from "../../dropdown";
import { AppLinks } from "../../links";
import { responsiveness } from "../../../assets/styles/parameters";
import styled from "styled-components";

export const NavigationQuickActions = () => {
  return (
    <>
      <ActionDropdownLarge iconTitle="Quick Actions" title="Quick Actions">
        <QuickActions>
          <li>
            <AppLinks to={BookingCreateRoute}>Add Booking</AppLinks>
          </li>
          <li>
            <AppLinks to={BlogCreateRoute}>Add Blog Post</AppLinks>
          </li>
          <li>
            <AppLinks to={TestimonyCreateRoute}>Add Testimonial</AppLinks>
          </li>
          <li>
            <AppLinks to={ClientCreateRoute}>Add Client</AppLinks>
          </li>
          <li>
            <AppLinks to={PartnerCreateRoute}>Add Partner</AppLinks>
          </li>
          <li>
            <AppLinks to={StaffCreateRoute}>Add Staff</AppLinks>
          </li>
        </QuickActions>
      </ActionDropdownLarge>
    </>
  );
};

export default NavigationQuickActions;

const QuickActions = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 0 0 1rem;
  li {
    margin: 0.7rem 0;
  }

  @media only screen and (max-width: ${responsiveness.ipadMin}) {
    grid-template-columns: repeat(1, 1fr);

    li {
      margin: 1rem 0;
    }
  }
`;
