import { MainPaths, SecondaryPaths } from "./Paths";
import { Text, Title } from "../../typography";
import {
  colors,
  fontSize,
  responsiveness,
  shadow,
} from "../../../assets/styles/parameters";
import { useDispatch, useSelector } from "react-redux";

import { AppLinks } from "../../links";
import { BaseProfileRoute } from "../../../routes/Paths";
import { CloseSideNav } from "../../../redux/NavigationSlice";
import { IoCloseOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import styled from "styled-components";

const Sidebar = () => {
  const { navOpen } = useSelector((state) => state.sideNav);
  const { data } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const open = navOpen;

  if (data.user) {
    const { firstName, lastName } = data.user;

    const variants = {
      open: {
        display: "flex",
        opacity: 1,
        left: 0,
        transition: { duration: 1, ease: "easeInOut" },
      },
      closed: {
        display: "none",
        opacity: 0,
        left: -1000,
        transition: { duration: 0.5, ease: "easeInOut" },
      },
    };

    return (
      <>
        {open && (
          <SideNav
            variants={variants}
            animate="open"
            initial="closed"
            exit="closed"
          >
            <div>
              <Title number={5} onClick={() => dispatch(CloseSideNav())}>
                Extreme Clean
              </Title>
              <IoCloseOutline onClick={() => dispatch(CloseSideNav())} />
            </div>
            <ul>
              {MainPaths.map((s, index) => {
                return (
                  <SidbarNavItem
                    key={index}
                    onClick={() => dispatch(CloseSideNav())}
                  >
                    <Link to={s.path}>
                      <Text>{s.name}</Text>
                      {s.number && <span>14</span>}
                    </Link>
                  </SidbarNavItem>
                );
              })}
            </ul>
            <ul>
              {SecondaryPaths.map((s, index) => {
                return (
                  <SidbarNavItem
                    key={index}
                    onClick={() => dispatch(CloseSideNav())}
                  >
                    <Link to={s.path}>
                      <Text>{s.name}</Text>
                      {s.number && <span>14</span>}
                    </Link>
                  </SidbarNavItem>
                );
              })}
            </ul>
            <AppLinks
              to={BaseProfileRoute}
              onClick={() => dispatch(CloseSideNav())}
            >
              {/* <Img
                src={(media.mediaFiles && media.mediaFiles[0].url) || img}
                alt={`${firstName} profile`}
              /> */}
              <span>
                {firstName} {lastName}
              </span>
            </AppLinks>
          </SideNav>
        )}
      </>
    );
  }
};

export default Sidebar;

const SideNav = styled(motion.nav)`
  background-color: white;
  min-height: 100vh;
  width: 20%;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  box-shadow: ${shadow.light};
  z-index: 10001;

  div:first-child {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    h5 {
      font-size: ${fontSize.h6};
      margin: 0;
    }

    :hover {
      svg {
        cursor: pointer;
        color: red;
      }
    }
  }
  a:last-child {
    display: flex;
    align-self: flex-start;

    img {
      margin-right: 1rem;
    }
  }

  div:last-child {
    justify-content: flex-start;
    span {
      margin-left: 1rem;
    }
  }

  ul {
    width: 100%;
    margin: auto;
  }

  img {
    display: block;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    object-fit: cover;
  }

  p:last-child {
    display: flex;
    align-items: center;
    margin: 0;

    span {
      margin: 0 1rem;
    }
  }

  @media only screen and (max-width: ${responsiveness.mobile1}) {
    width: 70%;
  }
`;

const SidbarNavItem = styled.li`
  border-radius: 5px;
  margin: 0.5rem 0;
  line-height: 1;
  padding: 0.4rem 0.9rem;

  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  p {
    margin: 0 0.5rem;
  }

  span {
    background-color: ${colors.blue};
    color: white;
    padding: 0.2rem 0.5rem;
    border-radius: 5px;
  }

  &:hover {
    background-color: ${colors.blueDark2};
    color: ${colors.white};

    p,
    span {
      color: ${colors.white};
    }
  }
`;
