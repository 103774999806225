import styled from "styled-components";

const ImageItem = ({ position, background }) => {
  return <BaseImageItem position={position} background={background} />;
};

export default ImageItem;

const BaseImageItem = styled.img`
  position: absolute;
  height: ${({ position }) => {
    return position.height;
  }};
  width: ${({ position }) => {
    return position.width;
  }};
  border-radius: 10px;
  background: url(${({ background }) => background}) no-repeat;
  background-size: cover;

  right: ${({ position }) => {
    return `${position.right}%`;
  }};
  top: ${({ position }) => {
    return `${position.top}%`;
  }}; ;
`;
