import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { eces } from "../api";
import { onAlert } from "../utils/onAlerts";

const BASEPATH = "bookingapi/api/v1/bookings";

const INITIALSTATE = {
  error: "",
  loading: true,
  bookings: [],
  booking: {},
};

export const FetchBookings = createAsyncThunk(
  "bookings/fetches",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.get(BASEPATH);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const CreateBooking = createAsyncThunk(
  "bookings/create",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.post(BASEPATH, args);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const FetchBooking = createAsyncThunk(
  "bookings/fetch",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.get(`${BASEPATH}/${args}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const EditBooking = createAsyncThunk(
  "bookings/update",
  async (args, { rejectWithValue }) => {
    try {
      console.log("Edit Values:", args.value);
      const response = await eces.patch(`${BASEPATH}/${args.id}`, args.value);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const DeleteBooking = createAsyncThunk(
  "bookings/delete",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.delete(`${BASEPATH}/${args}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const staffReducer = createSlice({
  name: "bookings",
  initialState: INITIALSTATE,
  extraReducers: {
    [FetchBookings.pending]: (state) => {
      state.loading = true;
    },
    [FetchBookings.fulfilled]: (state, action) => {
      state.loading = false;
      state.bookings = action.payload;
    },
    [FetchBookings.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [FetchBooking.pending]: (state) => {
      state.loading = true;
    },
    [FetchBooking.fulfilled]: (state, action) => {
      state.loading = false;
      state.booking = action.payload;
    },
    [FetchBooking.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [CreateBooking.pending]: (state) => {
      state.loading = true;
    },
    [CreateBooking.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      onAlert("success", "Booking created");
    },
    [CreateBooking.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      onAlert("error", "Booking failed");
    },
    [EditBooking.pending]: (state) => {
      state.loading = true;
    },
    [EditBooking.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      onAlert("success", "Edit successful");
    },
    [EditBooking.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      onAlert("error", "Edit failed");
    },
    [DeleteBooking.pending]: (state) => {
      state.loading = true;
    },
    [DeleteBooking.fulfilled]: (state) => {
      state.loading = false;
      onAlert("success", "Delete successful");
    },
    [DeleteBooking.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      onAlert("error", "Delete failed");
    },
  },
});

export default staffReducer.reducer;
