import {
  StaffCreatePage,
  StaffListPage,
  StaffPages,
  StaffProfileEdit,
} from "../pages";

import { Route } from "react-router-dom";

export const StaffRoutes = () => {
  return (
    <Route path="/auth/staff" element={<StaffPages />}>
      <Route index element={<StaffListPage />} />{" "}
      <Route path="edit/:id" element={<StaffProfileEdit />} />
      <Route path="create" element={<StaffCreatePage />} />
    </Route>
  );
};
