import { BlogCreateRoute, BlogEditRoute } from "../../routes/Paths";
import { IoCheckboxOutline, IoCloseSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "../../components/button";
import { FetchBlogArticles } from "../../redux/BlogSlice";
import { IoPencilOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { Section } from "../../components/layout";
import { Table } from "../../components/table";
import { onFormatDateTime } from "../../utils/onFormatDateTime";
import styled from "styled-components";
import { useEffect } from "react";

const BlogListPage = () => {
  const { blogs, loading, error } = useSelector((state) => state.blog);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchBlogArticles());
  }, [dispatch]);

  const columns = [
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Author",
      selector: (row) => row.author,
      sortable: true,
      hide: "sm",
    },
    {
      name: "Published Date",
      selector: ({ createdDate }) => onFormatDateTime(createdDate),
      sortable: true,
      right: true,
      hide: "md",
    },
    {
      name: "Updated Date",
      selector: ({ lastModifiedDate }) => onFormatDateTime(lastModifiedDate),
      sortable: true,
      right: true,
      hide: "md",
    },
    {
      name: "Featured",
      sortable: true,
      center: true,
      selector: (row) => (
        <div>{row.featured ? <IoCheckboxOutline /> : <IoCloseSharp />}</div>
      ),
      hide: "md",
    },
    {
      cell: (row) => (
        <DataRowActionsContainer>
          <Link to={BlogEditRoute(row.id)} state={{ blog: row }}>
            <IoPencilOutline />
          </Link>
        </DataRowActionsContainer>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];

  return (
    <Section>
      <SubNav>
        <Button type="link" path={BlogCreateRoute}>
          Add New Article
        </Button>
      </SubNav>
      <Table data={blogs} columns={columns} loading={loading} />
    </Section>
  );
};

export default BlogListPage;

const SubNav = styled.nav`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 2rem;

  li {
    margin-left: 1.5rem;
    display: flex;
    align-items: center;
  }
`;
const DataRowActionsContainer = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0;
    padding: 0;
  }

  svg {
    margin-right: 1rem;
    cursor: pointer;
  }
`;
