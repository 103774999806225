import { CardItem, Cards } from "../../components/cards";
import { OrgCreateRoute, OrgEditRoute } from "../../routes/Paths";

import { AppLinks } from "../../components/links";
import { Text } from "../../components/typography";
import parse from "html-react-parser";
import styled from "styled-components";

const CompanyDetailElement = (props) => {
  const { data, loading } = props;

  return (
    <div>
      <Cards
        version="2"
        title="About"
        action={
          <BaseSpace>
            {data.length !== 0 && (
              <AppLinks to={OrgEditRoute} state={{ org: data[0] }}>
                Edit
              </AppLinks>
            )}
            {data.length === 0 && (
              <AppLinks to={OrgCreateRoute} state={{ org: data[0] }}>
                Create Company
              </AppLinks>
            )}
          </BaseSpace>
        }
      >
        {loading ? (
          <Text>Loading</Text>
        ) : (
          data &&
          data?.map((s, i) => {
            return (
              <div key={i}>
                {s?.about && <CardItem version="4" text={parse(s.about)} />}
              </div>
            );
          })
        )}
      </Cards>

      <Cards version="2" title="Mission">
        {loading ? (
          <Text>Loading</Text>
        ) : (
          data &&
          data?.map((s, i) => {
            const { mission } = s;
            return (
              <div key={i}>
                {mission && <CardItem version="4" text={mission} />}
              </div>
            );
          })
        )}
      </Cards>
      <Cards version="2" title="Vision">
        {loading ? (
          <Text>Loading</Text>
        ) : (
          data &&
          data?.map((s, i) => {
            const { vision } = s;
            return (
              <div key={i}>
                {vision && <CardItem version="4" text={vision} />}
              </div>
            );
          })
        )}
      </Cards>
    </div>
  );
};

export default CompanyDetailElement;

const BaseSpace = styled.div`
  a:first-child {
    margin-right: 1rem;
  }
`;
