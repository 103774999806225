import { DeleteMessage, EditMessage } from "../../redux/ContactSlice";
import { Grid, Section } from "../../components/layout";
import { IoMailOpenOutline, IoMailOutline } from "react-icons/io5";
import {
  RowActionsContainer,
  Table,
  TableExpandContainer,
} from "../../components/table";
import { Text, Title } from "../../components/typography";
import { useDispatch, useSelector } from "react-redux";

import { FetchMessages } from "../../redux/ContactSlice";
import { IoTrashOutline } from "react-icons/io5";
import Moment from "moment";
import { onFormatDateTime } from "../../utils/onFormatDateTime";
import { useEffect } from "react";

const ContactPage = () => {
  const { contacts } = useSelector((state) => state.contacts);
  const dispatch = useDispatch();

  console.log("Fetched Data:", contacts);

  const onDeleteMessage = (data) => {
    console.log("Delete Data:", data);
    const value = {
      id: data.id,
      data: data,
    };
    if (window.confirm("Are you sure you want to delete?")) {
      dispatch(DeleteMessage(value));
    }
  };

  const onUpdateReadStatus = (value) => {
    console.log("Update Data:", value);

    const newValue = {
      ...value,
      metadata: { ...value.metadata, messageRead: !value.metadata.messageRead },
    };
    dispatch(EditMessage(newValue));
  };

  const ExpandeableActionsContainer = (props) => {
    const { data, onUpdateReadStatus, ...otherProps } = props;

    const onReadTimer = () => {
      setTimeout(onUpdateReadStatus(data, "read"), 100000);
    };

    return (
      <TableExpandContainer
        {...otherProps}
        onClick={() => onReadTimer()}
        main={
          <Grid>
            <div>
              <Text scale="small" color="gray">
                Contacted Date: {onFormatDateTime(data.createdDate)}
              </Text>
              <Title number={5}>
                {data.firstName} {data.lastName}
              </Title>
              <address>
                <a href={`tel:${data.phone}`}>{data.phone}7</a>
                <br />
                <a href={`mailto:${data.email}`}>{data.email}</a>
              </address>
            </div>
            <div>
              <Text scale="small" color="gray">
                Message
              </Text>
              <Text>{data.message}</Text>
            </div>
          </Grid>
        }
        sub={
          <>
            <Text scale="small" color="gray">
              Actions
            </Text>
            <div>
              <div onClick={() => onDeleteMessage(data)}>
                <IoTrashOutline />
                <Text>Delete</Text>
              </div>
              <div onClick={() => onUpdateReadStatus(data)}>
                {data.metadata.messageRead ? (
                  <div>
                    <IoMailOpenOutline /> <Text>Read</Text>
                  </div>
                ) : (
                  <div>
                    <IoMailOutline /> <Text>Unread</Text>
                  </div>
                )}
              </div>
            </div>
          </>
        }
      />
    );
  };

  const columns = [
    {
      id: "createdDate",
      name: "Contacted Date",
      selector: ({ createdDate }) => Moment(createdDate).format("MMM DD, YYYY"),
      sortable: true,
      reorder: true,
      hide: "sm",
    },
    {
      id: "createdTime",
      name: "Contacted Time",
      selector: ({ createdDate }) => Moment(createdDate).format("h:mm a"),
      sortable: true,
      reorder: true,
      hide: "md",
    },
    {
      name: "First Name",
      selector: (row) => row.firstName,
      sortable: true,
      reorder: true,
    },
    {
      name: "Last Name",
      selector: (row) => row.lastName,
      sortable: true,
      reorder: true,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      reorder: true,
      hide: "sm",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      reorder: true,
      hide: "md",
    },
    {
      cell: (row) => (
        <>
          <RowActionsContainer onClick={() => onUpdateReadStatus(row)}>
            {row.metadata.messageRead ? (
              <IoMailOpenOutline />
            ) : (
              <IoMailOutline />
            )}
          </RowActionsContainer>
          <RowActionsContainer>
            <IoTrashOutline onClick={() => onDeleteMessage(row)} />
          </RowActionsContainer>
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
      name: "Actions",
    },
  ];

  useEffect(() => {
    dispatch(FetchMessages());
  }, [dispatch]);

  return (
    <Section>
      <Table
        data={contacts}
        columns={columns}
        defaultSortFieldId="createdDate"
        expandableRowsComponent={({ data }) => (
          <ExpandeableActionsContainer
            data={data}
            onUpdateReadStatus={onUpdateReadStatus}
          />
        )}
      />
    </Section>
  );
};

export default ContactPage;
