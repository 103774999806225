import { TestimonyListPage, TestimonyPage } from "../pages";

import { Route } from "react-router-dom";
import { TestimonyCreatePage } from "../pages";

export const TestimonyRoutes = () => {
  return (
    <Route path="/auth/testimonies" element={<TestimonyPage />}>
      <Route index element={<TestimonyListPage />} />
      <Route path="create" element={<TestimonyCreatePage />} />
    </Route>
  );
};
