import { ProfileDetailPage, ProfileEditPage, ProfilePage } from "../pages";

import { Route } from "react-router-dom";

export const ProfileRoute = () => {
  return (
    <Route path="/auth/profile" element={<ProfilePage />}>
      <Route index element={<ProfileDetailPage />} />
      <Route path="edit/:name" element={<ProfileEditPage />} />
    </Route>
  );
};
