import moment from "moment";

export const onFormatDateTime = (dateTime, format) => {
  switch (format) {
    case "time":
      return moment(dateTime).format(" h:mm a");

    default:
      return moment(dateTime).format("MMM DD, YYYY");
  }
};
