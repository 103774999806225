import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { MediaBaseRouteDelete } from "./MediaSlice";
import { eces } from "../api";
import { onAlert } from "../utils/onAlerts";

const BASEPATH = "blogapi/api/v1/post";
const INITIALSTATE = {
  blogs: [],
  blog: {},
  error: "",
  loading: true,
};

export const DeleteBlogPictures = createAsyncThunk(
  "blog/delete/pictures",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.delete(
        MediaBaseRouteDelete(args.name, args.fileName)
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const FetchBlogArticles = createAsyncThunk(
  "blog/fetches",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.get(BASEPATH);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const FetchBlog = createAsyncThunk(
  "blog/fetch",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.get(`${BASEPATH}/${args.id}`, args.value);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const CreateBlog = createAsyncThunk(
  "blog/create",
  async (args, { rejectWithValue }) => {
    try {
      const { content, title, summary, featured, author, files } = args;

      const formData = new FormData();
      const newData = {
        title: title || "",
        content: content || "",
        summary: summary || "",
        author: author || "",
        featured,
        mediaType: "IMAGE",
      };

      const blob = new Blob([JSON.stringify(newData)], {
        type: "application/json",
      });

      if (files) {
        for (const file of files) {
          formData.append("files", file.file);
        }
      }

      formData.append("data", blob);

      const response = await eces.post(BASEPATH, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const EditBlog = createAsyncThunk(
  "blog/update",
  async (args, { rejectWithValue }) => {
    try {
      const { content, title, summary, featured, author, files } = args.values;

      const formData = new FormData();
      const newData = {
        title: title || "",
        content: content || "",
        summary: summary || "",
        author: author || "",
        featured,
        mediaType: "IMAGE",
      };

      const blob = new Blob([JSON.stringify(newData)], {
        type: "application/json",
      });

      if (files) {
        for (const file of files) {
          formData.append("files", file.file);
        }
      }

      formData.append("data", blob);

      const response = await eces.patch(`${BASEPATH}/${args.id}`, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const DeleteBlog = createAsyncThunk(
  "blog/delete",
  async (args, { rejectWithValue, getState }) => {
    try {
      // Current blogs
      const newList = getState().blog.blogs.filter((s) => s.id !== args);

      // Filter out deleted blog from current blog
      await eces.delete(`${BASEPATH}/${args}`);

      return newList;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const blogReducer = createSlice({
  name: "bookings",
  initialState: INITIALSTATE,
  extraReducers: {
    // Fetch All Blog State
    [FetchBlogArticles.pending]: (state) => {
      state.loading = true;
    },
    [FetchBlogArticles.fulfilled]: (state, action) => {
      state.loading = false;
      state.blogs = action.payload;
    },
    [FetchBlogArticles.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    // Fetch  Blog State
    [FetchBlog.pending]: (state) => {
      state.loading = true;
    },
    [FetchBlog.fulfilled]: (state, action) => {
      state.loading = false;
      state.blog = action.payload;
    },
    [FetchBlog.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    // Create Blog State
    [CreateBlog.pending]: (state) => {
      state.loading = true;
    },
    [CreateBlog.fulfilled]: (state, action) => {
      state.loading = false;
      state.blog = action.payload;
      onAlert("success", "Article created");
    },
    [CreateBlog.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      onAlert("error", "Try Again! Saving failed");
    },
    // Edit Blog State
    [EditBlog.pending]: (state) => {
      state.loading = true;
    },
    [EditBlog.fulfilled]: (state, action) => {
      state.loading = false;
      state.blog = action.payload;
      onAlert("success", "Article updated");
    },
    [EditBlog.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      onAlert("error", "Try Again! Saving failed");
    },
    // Edit Blog State
    [DeleteBlog.pending]: (state) => {
      state.loading = true;
    },
    [DeleteBlog.fulfilled]: (state, action) => {
      state.loading = false;
      state.blogs = action.payload;
      onAlert("success", "Article Deleted");
    },
    // Delete Blog State
    [DeleteBlogPictures.fulfilled]: (state) => {
      state.loading = false;
      state.isSuccess = true;
      onAlert("success", "Image Deleted");
    },
  },
});

export default blogReducer.reducer;
