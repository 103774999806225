import { CardItem, Cards } from "../../components/cards";
import {
  CustomTextArea,
  FormButton,
  FormContainer,
  Input,
} from "../../components/forms";
import { Field, Form } from "react-final-form";
import { useLocation, useNavigate } from "react-router-dom";

import { Grid } from "../../components/layout";
import { ImageUploadComponent } from "../../components/imageUpload";
import { Label } from "../../components/forms";
import { Title } from "../../components/typography";

const StaffForm = (props) => {
  const { onFormSubmit, initialValues, onHandleImageDelete } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const onSubmit = (values) => {
    onFormSubmit(values);
  };

  const onCloseandReset = (reset) => {
    reset();

    const direction = location.pathname.split("/").includes("edit")
      ? navigate(-1, { replace: true })
      : navigate("/auth/staff", { replace: true });
    return direction;
  };

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({
        handleSubmit,
        pristine,
        submitting,
        invalid,
        form: {
          reset,
          mutators: { push },
        },
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid col="l15">
            <Cards title="Staff Information" version="2">
              <CardItem version="5">
                <FormContainer>
                  <Grid col={3}>
                    <Field
                      name="firstName"
                      type="text"
                      component={Input}
                      label="First Name"
                    />
                    <Field
                      name="middleName"
                      type="text"
                      component={Input}
                      label="Middle Name"
                    />
                    <Field
                      name="lastName"
                      type="text"
                      component={Input}
                      label="Last Name"
                    />
                  </Grid>
                  <Field
                    name="title"
                    type="text"
                    component={Input}
                    label="Title"
                  />
                </FormContainer>
                <FormContainer>
                  <div>
                    <Title number={6}>Biography</Title>
                    <Field
                      label="About Staff"
                      name="mainContent"
                      type="text"
                      component={CustomTextArea}
                    />
                  </div>
                </FormContainer>
                <FormContainer>
                  <Title number={6}>Contact Information</Title>
                  <Grid>
                    <Field
                      label="Phone Number"
                      name="phone"
                      type="phone"
                      component={Input}
                    />
                    <Field
                      label="Email"
                      name="email"
                      type="email"
                      component={Input}
                    />
                  </Grid>
                </FormContainer>
              </CardItem>
            </Cards>
            <FormContainer>
              <Label>Upload Images</Label>
              <Field name="files">
                {(props) => {
                  return (
                    <ImageUploadComponent
                      name={props.input.name}
                      files={props.input.value}
                      onupdatefiles={props.input.onChange}
                      onremoveImage={onHandleImageDelete}
                      extraProp={initialValues?.media}
                      maxUpload={1}
                    />
                  );
                }}
              </Field>
            </FormContainer>
          </Grid>
          <FormButton
            disabled={submitting || pristine || invalid}
            onCancelFunc={() => onCloseandReset(reset)}
            btnMessage={submitting ? "Saving" : "Save"}
          />
        </form>
      )}
    </Form>
  );
};

export default StaffForm;
