import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { eces } from "../api";
import { onAlert } from "../utils/onAlerts";

const BASEPATH = "companyapi/api/v1/faqs";
const INITIALSTATE = {
  loading: true,
  faqs: [],
  faq: {},
  error: "",
};

export const FetchFAQs = createAsyncThunk(
  "faq/fetch",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.get(BASEPATH);
      return {
        data: response.data,
      };
    } catch (error) {
      return rejectWithValue({
        error:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  }
);

export const CreateFAQ = createAsyncThunk(
  "faq/create",
  async (args, { rejectWithValue, getState }) => {
    try {
      const response = await eces.post(`${BASEPATH}`, args);
      return {
        data: response.data,
      };
    } catch (error) {
      return rejectWithValue({
        error:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  }
);

export const EditFAQ = createAsyncThunk(
  "faq/edit",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.patch(`${BASEPATH}/${args.id}`, args.values);
      return {
        data: response.data,
      };
    } catch (error) {
      return rejectWithValue({
        error:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  }
);

export const DeleteFAQ = createAsyncThunk(
  "faq/delete",
  async (args, { rejectWithValue, getState }) => {
    const faqs = getState().faq?.faqs;
    const data = faqs.filter((item) => item !== args.data);
    try {
      await eces.delete(`${BASEPATH}/${args.id}`);
      return {
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        error:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  }
);

export const FAQSlice = createSlice({
  name: "faq",
  initialState: INITIALSTATE,
  extraReducers: (builder) => {
    builder
      .addCase(FetchFAQs.pending, (state) => {
        state.loading = true;
      })
      .addCase(FetchFAQs.fulfilled, (state, action) => {
        state.loading = false;
        state.faqs = action.payload.data;
      })
      .addCase(FetchFAQs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
        onAlert("error", action.payload.error);
      })
      .addCase(CreateFAQ.pending, (state) => {
        state.loading = true;
      })
      .addCase(CreateFAQ.fulfilled, (state, action) => {
        state.loading = false;
        state.faq = action.payload.data;
        onAlert("success", "FAQs Created");
      })
      .addCase(CreateFAQ.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
        onAlert("error", action.payload.error);
      })
      .addCase(EditFAQ.pending, (state) => {
        state.loading = true;
      })
      .addCase(EditFAQ.fulfilled, (state, action) => {
        state.loading = false;
        state.faq = action.payload.data;

        onAlert("success", "FAQ Edited");
      })
      .addCase(EditFAQ.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
        onAlert("error", action.payload.error);
      })
      .addCase(DeleteFAQ.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(DeleteFAQ.fulfilled, (state, action) => {
        state.loading = false;
        state.faqs = action.payload.data;
        onAlert("success", "FAQ Deleted");
      })
      .addCase(DeleteFAQ.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
        onAlert("error", action?.payload?.error);
      });
  },
});

export default FAQSlice.reducer;
