import {
  CustomTextArea,
  FormButton,
  FormContainer,
  Input,
} from "../../components/forms";
import { Field, Form } from "react-final-form";
import { useLocation, useNavigate } from "react-router-dom";

const FAQForm = (props) => {
  const { onFormSubmit, initialValues } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const onCloseandReset = (reset) => {
    reset();

    const direction = location.pathname.split("/").includes("edit")
      ? navigate(-1, { replace: true })
      : navigate("/auth/faq", { replace: true });
    return direction;
  };

  return (
    <Form
      onSubmit={onFormSubmit}
      initialValues={initialValues}
      validate={(formValues) => {
        const errors = {};
        if (!formValues.question || formValues.question.trim().length === 0) {
          errors.question = "Question is required";
        }
        if (!formValues.answer || formValues.answer.trim().length === 0) {
          errors.answer = "Answer is required";
        }
        return errors;
      }}
    >
      {({ handleSubmit, pristine, submitting, invalid, form: { reset } }) => (
        <form onSubmit={handleSubmit}>
          <FormContainer>
            <Field
              name="question"
              type="text"
              component={Input}
              label="Question"
            />

            <Field
              name="answer"
              type="text"
              component={CustomTextArea}
              label="Answer"
            />
          </FormContainer>
          <FormButton
            disabled={submitting || pristine || invalid}
            onCancelFunc={() => onCloseandReset(reset)}
            btnMessage={submitting ? "Saving FAQ....." : "Save FAQ"}
          />
        </form>
      )}
    </Form>
  );
};

export default FAQForm;
