import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { MediaBaseRouteDelete } from "./MediaSlice";
import { eces } from "../api";
import { onAlert } from "../utils/onAlerts";

const BASEPATH = "companyapi/api/v1/clients";
const INITIALSTATE = {
  data: [],
  isSuccess: false,
  errMessage: "",
  loading: true,
};

export const FetchClients = createAsyncThunk(
  "clients/fetches",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.get(BASEPATH);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const CreateClient = createAsyncThunk(
  "client/create",
  async (args, { rejectWithValue }) => {
    const {
      firstName,
      lastName,
      title,
      addresses,
      organization,
      phone,
      notes,
      email,
    } = args;

    const newData = {
      firstName: firstName || "",
      lastName: lastName || "",
      email: email || "",
      phone: phone || "",
      title: title || "",
      organization: organization || "",
      notes: notes || "",
      addresses,
    };

    try {
      const response = await eces.post(BASEPATH, newData);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const FetchClient = createAsyncThunk(
  "client/fetch",
  async (args, { rejectWithValue, getState }) => {
    try {
      const response = await eces.get(`${BASEPATH}/${args.id}`, args.value);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const EditClient = createAsyncThunk(
  "client/update",
  async (args, { rejectWithValue }) => {
    const {
      firstName,
      lastName,
      title,
      addresses,
      organization,
      phone,
      notes,
      email,
    } = args.values;

    const newData = {
      firstName: firstName || "",
      lastName: lastName || "",
      email: email || "",
      phone: phone || "",
      title: title || "",
      organization: organization || "",
      notes: notes || "",
      addresses,
    };

    try {
      const response = await eces.patch(`${BASEPATH}/${args.id}`, newData);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const DeleteClient = createAsyncThunk(
  "client/delete",
  async (args, { rejectWithValue, getState }) => {
    try {
      const response = await eces.delete(`${BASEPATH}/${args}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const DeleteClientPictures = createAsyncThunk(
  "client/delete/pictures",
  async (args, { rejectWithValue, getState }) => {
    try {
      const { token } = getState().user.data;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await eces.delete(
        MediaBaseRouteDelete(args.name, args.fileName),
        config
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const clientReducer = createSlice({
  name: "client",
  initialState: INITIALSTATE,
  extraReducers: {
    [FetchClients.pending]: (state) => {
      state.loading = true;
    },
    [FetchClients.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.isSuccess = true;
    },
    [FetchClients.rejected]: (state, action) => {
      state.loading = false;
      state.isSuccess = false;
      state.errMessage = action.payload;
    },
    [FetchClient.pending]: (state) => {
      state.loading = true;
    },
    [FetchClient.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.isSuccess = true;
    },
    [FetchClient.rejected]: (state, action) => {
      state.loading = false;
      state.isSuccess = false;
      state.errMessage = action.payload;
    },
    [CreateClient.fulfilled]: (state, action) => {
      state.loading = false;
      state.isSuccess = true;
      state.errMessage = action.payload;
      onAlert("success", "Client Created");
    },
    [EditClient.fulfilled]: (state, action) => {
      state.loading = false;
      state.isSuccess = true;
      state.errMessage = action.payload;
      onAlert("success", "Client Info Updated");
    },
    [DeleteClient.fulfilled]: (state) => {
      state.loading = false;
      state.isSuccess = true;
      onAlert("success", "Client Info Deleted");
    },
    [DeleteClientPictures.fulfilled]: (state) => {
      state.loading = false;
      state.isSuccess = true;
      onAlert("success", "Image Deleted");
    },
  },
});

export default clientReducer.reducer;
