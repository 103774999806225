import { Text } from "../typography";
import { colors } from "../../assets/styles/parameters";
import styled from "styled-components";

export const CheckboxInput = (props) => {
  const { input, label, ...otherProps } = props;

  return (
    <BaseLabel>
      <Text>{label}</Text>
      <input {...input} {...otherProps} />
      <span />
    </BaseLabel>
  );
};

const BaseLabel = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;

  p {
    margin: 0;
    padding: 0;
    font-size: 0.8rem;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 5px;
    border: 1px solid ${colors.gray400};

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 6.5px;
      top: 3px;
      width: 5px;
      height: 10px;
      border: solid ${colors.white};
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }

  &:hover input ~ span {
    background-color: ${colors.blue};
  }

  input:checked ~ span {
    background-color: ${colors.blue};
  }

  input:checked ~ span:after {
    display: block;
  }
`;
