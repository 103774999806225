import { Text, Title } from "../typography";

import { colors } from "../../assets/styles/parameters";
import styled from "styled-components";

const NotificationItem = ({ message, date, status, ...otherProps }) => {
  return (
    <BaseItemContainer status={status} {...otherProps}>
      <Title number={6}>{message}</Title>
      <div>
        <Text scale="small" color="gray">
          {date}
        </Text>
        <Text title={`Mark as ${status ? "read" : "unread"}`}>
          {status ? "read" : "unread"}
        </Text>
      </div>
    </BaseItemContainer>
  );
};

export default NotificationItem;

const BaseItemContainer = styled.div`
  background-color: ${({ status }) => (!status ? "lightpurple" : colors.white)};
  padding: 0.5rem 1rem;
  border-bottom: 1px solid ${colors.gray400};

  h6 {
    font-size: 14px;
    margin-bottom: 0;
  }

  p {
    font-size: 11px;
  }

  div {
    display: flex;
    justify-content: space-between;
  }
`;
