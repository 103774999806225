import ClientCreateForm from "./ClientForm";
import { CreateClient } from "../../redux/ClientSlice";
import { Section } from "../../components/layout";
import { useDispatch } from "react-redux";

const ContactCreatePage = () => {
  const dispatch = useDispatch();

  const onFormSubmit = (values) => {
    if (window.confirm(`Are you sure you want to add this to partners?`)) {
      dispatch(CreateClient(values));
    }
  };

  return (
    <Section>
      <ClientCreateForm onFormSubmit={onFormSubmit} />
    </Section>
  );
};

export default ContactCreatePage;
