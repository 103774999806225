import DataTable from "react-data-table-component";

export const Table = ({ data, columns, expandableRowsComponent, loading }) => {
  const expand = expandableRowsComponent ? true : false;
  return (
    <DataTable
      columns={columns}
      data={data}
      expandableRows={expand}
      expandableRowsComponent={expandableRowsComponent}
      progressPending={loading}
      persistTableHead
      pagination
      highlightOnHover
      compact
      fixedHeader
      responsive
      striped
    />
  );
};

export default Table;
