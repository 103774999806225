import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { eces } from "../api";
import { onAlert } from "../utils/onAlerts";

const BASEPATH = "testimonialapi/api/v1/testimonial";
const INITIALSTATE = {
  loading: true,
  testimonies: [],
  testimony: {},
  error: "",
};

export const FetchTestimonies = createAsyncThunk(
  "testimony/fetchAll",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.get(BASEPATH);
      return response.data;
    } catch (error) {
      return rejectWithValue({
        error:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        status: error.response.status,
      });
    }
  }
);

export const CreateTestimony = createAsyncThunk(
  "testimony/create",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.post(BASEPATH, args);
      return {
        data: response.data,
      };
    } catch (error) {
      return rejectWithValue({
        error:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        status: error.response.status,
      });
    }
  }
);

export const EditTestimony = createAsyncThunk(
  "testimony/update",
  async (args, { rejectWithValue, getState }) => {
    try {
      const response = await eces.patch(`${BASEPATH}/${args.id}`, args.value);

      const data = getState().testimony.testimonies.filter(
        (item) => item.id !== args.id
      );

      const newData = [...data, response.data];

      return {
        testimony: response.data,
        testimonies: newData,
      };
    } catch (error) {
      return rejectWithValue({
        error:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        status: error.response.status,
      });
    }
  }
);

export const DeleteTestimony = createAsyncThunk(
  "testimony/delete",
  async (args, { rejectWithValue, getState }) => {
    try {
      const testimony = getState().testimony.testimonies;
      const data = testimony.filter((item) => item !== args.data);
      await eces.delete(`${BASEPATH}/${args.id}`);
      return data;
    } catch (error) {
      return rejectWithValue({
        error:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        status: error.response.status,
      });
    }
  }
);

export const testimonySlice = createSlice({
  name: "testimony",
  initialState: INITIALSTATE,
  extraReducers: (builder) => {
    builder
      .addCase(FetchTestimonies.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(FetchTestimonies.fulfilled, (state, action) => {
        state.loading = false;
        state.testimonies = action.payload;
      })
      .addCase(FetchTestimonies.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
      })
      .addCase(CreateTestimony.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(CreateTestimony.fulfilled, (state, action) => {
        state.loading = false;
        onAlert("success", "Testimony Added");
      })
      .addCase(CreateTestimony.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
      })
      .addCase(EditTestimony.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(EditTestimony.fulfilled, (state, action) => {
        state.loading = false;
        state.testimonies = action.payload.testimonies;
        state.testimony = action.payload.testimony;
        onAlert("success", "Testimony Updated");
      })
      .addCase(EditTestimony.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
      })
      .addCase(DeleteTestimony.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(DeleteTestimony.fulfilled, (state, action) => {
        state.loading = false;
        state.testimonies = action.payload;
        onAlert("success", "Testimony Deleted");
      })
      .addCase(DeleteTestimony.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
      });
  },
});

export default testimonySlice.reducer;
