import { Field, Form } from "react-final-form";
import { Text, Title } from "../../components/typography";

import { Button } from "../../components/button";
import { Input } from "../../components/forms";
import { responsiveness } from "../../assets/styles/parameters";
import styled from "styled-components";

const LoginForm = (props) => {
  const { onSubmit, errMessage } = props;

  return (
    <Form
      onSubmit={onSubmit}
      validate={(values) => {
        const errors = {};

        if (!values.email) {
          errors.email = "You must enter your email.";
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
        ) {
          errors.email = "Invalid email address";
        }

        if (!values.password) {
          errors.password = "You must enter your password.";
        }

        return errors;
      }}
    >
      {({ handleSubmit, pristine, submitting, invalid, form }) => (
        <FormContainer onSubmit={handleSubmit}>
          <Title number={11}>Sign In</Title>
          {errMessage && <Text color="red">{errMessage}</Text>}
          <Field
            label="Email"
            name="email"
            type="email"
            component={Input}
            autoComplete={"email"}
            required
          />
          <Field
            label="Password"
            name="password"
            type="password"
            component={Input}
            autoComplete={"current-password"}
            required
          />
          <div>
            <Button type="submit" disabled={submitting || pristine || invalid}>
              {submitting ? "Submitting..." : "Login"}
            </Button>
          </div>
        </FormContainer>
      )}
    </Form>
  );
};

export default LoginForm;

const FormContainer = styled.form`
  width: 70%;
  margin: auto;

  div:last-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;

    p {
      margin: 0;
      width: fit-content;
    }
  }

  @media screen and (max-width: ${responsiveness.mobile1}) {
    width: 95%;
  }
`;
