import { Container, Section } from "../../components/layout";
import { useNavigate } from "react-router-dom";

import { CreateCred } from "../../redux/CompCredSlice";
import { CredBaseRoute } from "../../routes/Paths";
import CredForm from "./CredForm";
import { useDispatch } from "react-redux";

const CredCreatePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onFormSubmit = (values) => {
    if (window.confirm(`Are you sure you want to add this`)) {
      dispatch(CreateCred(values));
      setTimeout(() => {
        navigate(CredBaseRoute);
      }, 2000);
    }
  };

  return (
    <Section>
      <Container width="50">
        <CredForm onFormSubmit={onFormSubmit} />
      </Container>
    </Section>
  );
};

export default CredCreatePage;
