import { CreateService } from "../../redux/ServiceSlice";
import { Section } from "../../components/layout";
import ServiceForm from "./ServiceForm";
import { useDispatch } from "react-redux";

const ServiceCreatePage = () => {
  const dispatch = useDispatch();

  const onFormSubmit = (values) => {
    if (window.confirm(`Are you sure you want to add this service?`)) {
      dispatch(CreateService(values));
    }
  };

  return (
    <Section>
      <ServiceForm onFormSubmit={onFormSubmit} />
    </Section>
  );
};

export default ServiceCreatePage;
