import { Layout, PageHeader } from "../../components/layout";

import { Outlet } from "react-router-dom";

const PartnerPages = (props) => {
  return (
    <>
      <PageHeader title="Partner" />
      <Layout>
        <Outlet />
      </Layout>
    </>
  );
};

export default PartnerPages;
