import { AiOutlineBell, AiOutlineSetting } from "react-icons/ai";
import { Text, Title } from "../typography";
import {
  colors,
  fontSize,
  responsiveness,
  shadow,
} from "../../assets/styles/parameters";

import { Link } from "react-router-dom";
import NotificationLists from "./NotificationList";
import { SettingsBaseRoute } from "../../routes/Paths";
import { motion } from "framer-motion";
import styled from "styled-components";
import { useState } from "react";

const NotificationDropDown = ({ data, ...otherProps }) => {
  const [show, setShow] = useState(false);

  const onShowDropdown = () => {
    return setShow(!show);
  };

  const onClearNotifications = () => {
    // Clear notifications
    // Close notifications container
  };

  const variants = {
    open: { opacity: 1, y: 0 },
    closed: { opacity: 0, y: 0 },
  };

  return (
    <BaseDiv
      title="Notifications"
      {...otherProps}
      // onMouseLeave={() => setShow(false)}
    >
      <AiOutlineBell title="Notifications" onClick={onShowDropdown} />
      {show && (
        <BaseContainer
          variants={variants}
          animate="open"
          initial="closed"
          exit="closed"
        >
          <NotificationHeader>
            <Title number={6}>Notifications</Title>
            <Text>Mark all as read</Text>
          </NotificationHeader>
          <NotificationLists />
          <NotificationFooter>
            <Link to={SettingsBaseRoute}>
              <AiOutlineSetting />
            </Link>

            <Text scale="sm" color="gray" onClick={onClearNotifications}>
              Clear All
            </Text>
          </NotificationFooter>{" "}
        </BaseContainer>
      )}
    </BaseDiv>
  );
};

export default NotificationDropDown;

const BaseDiv = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;

  svg {
    cursor: pointer;
    font-size: 20px;
  }

  @media only screen and (max-width: ${responsiveness.ipadMin}) {
    position: static;
  }
`;

const BaseContainer = styled(motion.div)`
  position: absolute;
  z-index: 1000;
  right: 0;
  top: 100%;
  background: ${colors.white};
  box-shadow: ${shadow.light};
  border-radius: 0 0 5px 5px;
  width: 20rem;
  padding-top: 1rem;

  * {
    font-size: 0.9rem;
  }

  p {
    padding: 0;
    margin: 0;
  }

  @media only screen and (max-width: ${responsiveness.ipadMin}) {
    right: 0;
    top: 80%;
  }
`;

const NotificationHeader = styled.header`
  display: flex;
  padding: 0 1rem;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.gray200};

  h6,
  p {
    padding: 0;
    margin: 0;
  }

  h6 {
    font-weight: 500;
    color: ${colors.black};
  }

  p {
    font-size: ${fontSize.nav};
    max-width: max-content;
  }
`;

const NotificationFooter = styled.footer`
  padding: 0.5rem 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg,
  p {
    color: ${colors.gray600};
    font-size: 12px;
    cursor: pointer;
    max-width: max-content;
  }
`;
