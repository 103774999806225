import { FAQCreatePage, FAQEdit, FAQListPage, FAQPage } from "../pages";

import { Route } from "react-router-dom";

export const FAQRoutes = () => {
  return (
    <Route path="/auth/faq" element={<FAQPage />}>
      <Route index element={<FAQListPage />} />
      <Route path="edit/:id" element={<FAQEdit />} />
      <Route path="create" element={<FAQCreatePage />} />
    </Route>
  );
};
