import "react-toastify/dist/ReactToastify.css";

import { Slide, ToastContainer } from "react-toastify";

import CombinedRoutes from "./routes/CombinedRoutes";
import { GlobalStyles } from "./assets/styles/globalstyles";
import { Meta } from "./components/meta";

const App = () => {
  return (
    <>
      <Meta />
      <GlobalStyles />
      <CombinedRoutes />
      <ToastContainer transition={Slide} />
    </>
  );
};

export default App;
