import { Layout, PageHeader } from "../../components/layout";

import { Outlet } from "react-router-dom";

const GalleryPage = () => {
  return (
    <>
      <PageHeader title="Gallery" />
      <Layout>
        <Outlet />
      </Layout>
    </>
  );
};

export default GalleryPage;
