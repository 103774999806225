import { colors, fontSize } from "../../../assets/styles/parameters";

import { MainPaths } from "./Paths";
import NavigationAction from "./NavigationAction";
import { NavigationHeader } from "./NavigationHeader";
import { NavigationLink } from "./NavigationLink";
import NavigationQuickActions from "./NavigationQuickActions";
import { Notification } from "../../notification";
import styled from "styled-components";

const Navigation = () => {
  return (
    <>
      <BaseNav>
        <NavigationHeader />
        <BaseRight>
          <NavigationLink paths={MainPaths} open="true" />
          <NavigationQuickActions />
          <Notification />
          <NavigationAction />
        </BaseRight>
      </BaseNav>
    </>
  );
};

export default Navigation;

const BaseNav = styled.nav`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem 0;
  color: ${colors.white};
  font-size: ${fontSize.nav};
`;

const BaseRight = styled.div`
  display: flex;

  img {
    display: block;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    object-fit: cover;
    margin: 0 0 0 1rem;
  }
`;
