import { Link } from "react-router-dom";
import { colors } from "../../assets/styles/parameters";
import styled from "styled-components";

export const CardItem = ({
  version,
  img,
  imgDes,
  text,
  status,
  path,
  children,
  state,
  ...otherProps
}) => {
  switch (version) {
    case "1":
      return (
        <BaseListItem1 {...otherProps}>
          <img src={img} alt={imgDes} />
          <Link to={path} state={state}>
            {text}
          </Link>
        </BaseListItem1>
      );
    case "2":
      return (
        <BaseListItem2 {...otherProps}>
          <div>
            <img src={img} alt={imgDes} />
            <Link to={path} state={state}>
              {text}
            </Link>
          </div>
        </BaseListItem2>
      );
    case "3":
      return (
        <BaseListItem3 {...otherProps}>
          <div>{children}</div>
          {path && (
            <div>
              <Link to={path} state={state}>
                {text}
              </Link>
            </div>
          )}
        </BaseListItem3>
      );
    case "4":
      return (
        <BaseListItem4 {...otherProps}>
          <div>{text}</div>
        </BaseListItem4>
      );
    default:
      return <BaseListItem {...otherProps}>{children}</BaseListItem>;
  }
};

const BaseListItem = styled.li`
  color: ${colors.black};
  border: 1px solid ${colors.gray100};
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  padding: 0.5rem 0rem;

  &:last-child {
    border-bottom: 1px solid ${colors.gray100};
  }

  img {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    object-fit: cover;
    margin-right: 0.7rem;
  }

  h6 {
    margin: 1rem 0 0.5rem 0;
    padding: 0;
  }

  p,
  a {
    margin: 0;
    padding: 0;
    color: ${colors.gray600};
  }
`;

const BaseListItem1 = styled(BaseListItem)`
  display: flex;
`;

const BaseListItem2 = styled(BaseListItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;

  div {
    display: flex;
  }

  div:last-child {
    align-self: flex-end;
  }

  &:hover {
    background-color: ${colors.gray300};
  }
`;

const BaseListItem3 = styled(BaseListItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
  }

  div:last-child {
    align-self: flex-end;
  }
`;

const BaseListItem4 = styled(BaseListItem)`
  &:hover {
    background-color: ${colors.white};
  }
`;
