import {
  ServiceCreatePage,
  ServiceEditPage,
  ServiceListPage,
  ServicePage,
} from "../pages";

import { Route } from "react-router-dom";

export const ServicesRoutes = () => {
  return (
    <Route path="/auth/services" element={<ServicePage />}>
      <Route index element={<ServiceListPage />} />
      <Route path="create" element={<ServiceCreatePage />} />
      <Route path="edit/:id" element={<ServiceEditPage />} />
    </Route>
  );
};
