import { useDispatch, useSelector } from "react-redux";

import { BookingCreateRoute } from "../../routes/Paths";
import { Button } from "../../components/button";
import DataTable from "react-data-table-component";
import { FetchBookings } from "../../redux/BookingSlice";
import { Link } from "react-router-dom";
import { Section } from "../../components/layout";
import { Status } from "../../components/status";
import { onAlert } from "../../utils/onAlerts";
import { onFormatDateTime } from "../../utils/onFormatDateTime";
import styled from "styled-components";
import { useEffect } from "react";

const BookingListPage = () => {
  const { bookings, error, loading } = useSelector((state) => state.bookings);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchBookings());
  }, [dispatch]);

  error && onAlert(error);

  const columns = [
    {
      name: "Received Date",
      selector: ({ createdDate }) => onFormatDateTime(createdDate),
      sortable: true,
      hide: "sm",
    },
    {
      name: "Booking ID",
      selector: (row) => row.bookingId,
      sortable: true,
      hide: "sm",
    },

    {
      name: "Customer Name",
      sortable: true,
      selector: (row) =>
        row.client && `${row.client.firstName} ${row.client.lastName}`,
    },
    {
      name: "Phone",
      sortable: true,
      selector: (row) => row.client && row.client.phone,
      hide: "sm",
    },
    {
      name: "Email",
      sortable: true,
      selector: (row) => row.client && row.client.email,
      hide: "md",
    },
    {
      name: "Service Date",
      selector: ({ date }) => onFormatDateTime(date),
      sortable: true,
      hide: "md",
    },
    {
      name: "Service Time",
      selector: ({ time }) => onFormatDateTime(time, "time"),
      sortable: true,
      hide: "md",
    },
    {
      name: "Status",
      sortable: true,
      hide: "sm",
      center: true,
      selector: (row) => (
        <Status
          text={
            row.status === "IN_PROGRESS"
              ? "IN PROGRESS"
              : row.status === "COMPLETED"
              ? "COMPLETED"
              : "PENDING"
          }
          color={
            row.status === "IN_PROGRESS"
              ? "yellow"
              : row.status === "COMPLETED"
              ? "green"
              : "red"
          }
        />
      ),
    },
    {
      name: "Action",
      sortable: true,
      center: true,
      selector: (row) => (
        <Link to={row.id} state={{ booking: row }}>
          View
        </Link>
      ),
    },
  ];

  return (
    <Section>
      <SubNav>
        <Button type="link" path={BookingCreateRoute}>
          Add New Booking
        </Button>
      </SubNav>

      <DataTable
        columns={columns}
        progressPending={loading}
        data={bookings}
        pagination
        persistTableHead="true"
        highlightOnHover
        compact
        fixedHeader
        defaultSortAsc={true}
        responsive={true}
      />
    </Section>
  );
};

export default BookingListPage;

const SubNav = styled.nav`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 2rem;

  li {
    margin-left: 1.5rem;
    display: flex;
    align-items: center;
  }
`;
