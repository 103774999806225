import { CheckboxInput } from "../../components/forms";
import { Field } from "react-final-form";
import { Text } from "../../components/typography";
import altImg from "../../assets/imgs/extreme_logo.svg";
import styled from "styled-components";

export const BookingServiceCard = (props) => {
  const { title, media } = props.data;
  const url = media?.mediaFiles ? media?.mediaFiles[0]?.url : altImg;
  const renderBox = (props) => {
    return (
      <Item>
        <CheckboxInput {...props} />
        <img src={url} alt={`${title}`} />
        <div>
          <Text>{title}</Text>
        </div>
      </Item>
    );
  };

  return (
    <Wrapper>
      <Field
        name="services"
        type="checkbox"
        component={renderBox}
        value={title}
      />
    </Wrapper>
  );
};

export default BookingServiceCard;

const Wrapper = styled.div`
  margin-bottom: 1rem;
`;

const Item = styled.div`
  margin: 0.5rem 0;
  display: flex;
  width: 100%;

  label {
    width: min-content;
    height: min-content;
    margin: 0 1rem;
    top: 0;
  }

  img {
    border-radius: 0;
    width: 100px;
    min-width: 100px;
    height: 100%;
  }

  & > div {
    width: 100%;
    margin-top: 0.1rem;
  }

  svg {
    font-size: 2rem;
    padding: 0;
    margin: 0;
    line-height: 0;
  }
`;
