import styled, { css } from "styled-components";

import { colors } from "../../assets/styles/parameters";

export const Status = ({ text, color }) => {
  return (
    <BaseStatus color={color}>
      {text.toLowerCase().replace(/^\w/, (c) => c.toUpperCase())}
    </BaseStatus>
  );
};

export default Status;

export const BaseStatus = styled.div`
  padding: 0.3rem 0.8rem;
  border-radius: 50px;
  font-size: 0.6rem;

  ${({ color }) => {
    switch (color) {
      case "red":
        return css`
          background-color: ${colors.red};
          color: white;
        `;
      case "yellow":
        return css`
          background-color: ${colors.yellow};
          color: ${colors.black};
        `;
      default:
        return css`
          background-color: ${colors.green};
          color: ${colors.white};
        `;
    }
  }}
`;
