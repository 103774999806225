import { Grid, Section } from "../../components/layout";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "../../components/button";
import CompanyDetailElement from "./CompanyDetailElement";
import { CredCreateRoute } from "../../routes/Paths";
import CredentialsElement from "./CredentialsElement";
import { FetchComp } from "../../redux/CompanySlice";
import LocationElement from "./LocationElement";
import styled from "styled-components";
import { useEffect } from "react";

const CompanyProfile = () => {
  const { data, loading } = useSelector((state) => state.company);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchComp());
  }, [dispatch]);

  return (
    <Section>
      <SubNav>
        <li>
          <Button type="link" path={CredCreateRoute} state={{ org: data[0] }}>
            Add Credentials
          </Button>
        </li>
      </SubNav>
      <Grid col="l15">
        <div>
          <CompanyDetailElement loading={loading} data={data} />
        </div>

        <div>
          <CredentialsElement />
          <LocationElement
            loading={loading}
            locations={data && data[0] && data[0]?.locations}
          />
        </div>
      </Grid>
    </Section>
  );
};

export default CompanyProfile;

const SubNav = styled.nav`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 2rem;

  li {
    margin-left: 1.5rem;
  }
`;
