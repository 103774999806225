import {
  BookingCreatePage,
  BookingDetailPage,
  BookingEditPage,
  BookingPage,
  BookingPageListPage,
} from "../pages";

import { Route } from "react-router-dom";

export const BookingRoutes = () => {
  return (
    <Route path="/auth/bookings" element={<BookingPage />}>
      <Route index element={<BookingPageListPage />} />
      <Route path=":id" element={<BookingDetailPage />} />
      <Route path="edit/:id" element={<BookingEditPage />} />
      <Route path="create" element={<BookingCreatePage />} />
    </Route>
  );
};
