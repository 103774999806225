import {
  BookingBaseRouteId,
  ClientsBaseRoute,
  ContactBaseRoute,
  TestimoniesBaseRoute,
} from "../../routes/Paths";
import { CardItem, Cards } from "../../components/cards";
import { Grid, Header, Layout, Section } from "../../components/layout";
import { useDispatch, useSelector } from "react-redux";

import { AppLinks } from "../../components/links";
import { FetchBookings } from "../../redux/BookingSlice";
import { FetchClients } from "../../redux/ClientSlice";
import { FetchMessages } from "../../redux/ContactSlice";
import { FetchTestimonies } from "../../redux/TestimonySlice";
import ReactStars from "react-rating-stars-component";
import { onFormatDateTime } from "../../utils/onFormatDateTime";
import { useEffect } from "react";

const HomePage = () => {
  const { user, contacts, testimony, bookings, clients } = useSelector(
    (state) => state
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchMessages());
    dispatch(FetchBookings());
    dispatch(FetchTestimonies());
    dispatch(FetchClients());
  }, [dispatch]);

  const data = {
    contact: contacts,
    user: user,
    testimony: testimony,
    bookings: bookings,
    clients: clients,
  };

  return (
    <>
      <Header data={data} />
      <Layout>
        <Section>
          <Grid col="l15">
            <Cards version="2" title="Request for Estiminates - Pending Review">
              {bookings.bookings &&
                bookings.bookings
                  .filter(
                    (s, index) => s && s.status === "PENDING" && index < 10
                  )
                  .map((s, i) => {
                    const date = onFormatDateTime(s.date);

                    return (
                      <CardItem
                        key={i}
                        version="3"
                        text={
                          <div>
                            Client: {s.client && s.client.firstName}{" "}
                            {s.client && s.client.lastName}
                            <br />
                            Project Date: {s.date && date}
                          </div>
                        }
                        path={BookingBaseRouteId(s.id)}
                      />
                    );
                  })}
            </Cards>
            <Cards version="2" title="Request for Estiminates - Reviewed">
              {bookings.bookings &&
                bookings.bookings
                  .filter(
                    (s, index) => s && s.status === "IN_PROGRESS" && index < 10
                  )
                  .map((s, i) => {
                    const date = onFormatDateTime(s.date);

                    return (
                      <CardItem
                        key={i}
                        version="3"
                        text={
                          <div>
                            Client: {s.client && s.client.firstName}{" "}
                            {s.client && s.client.lastName}
                            <br />
                            Project Date: {s.date && date}
                          </div>
                        }
                        path={BookingBaseRouteId(s.id)}
                      />
                    );
                  })}
            </Cards>
          </Grid>
        </Section>
        <Section>
          <Grid col="l15">
            <Cards
              version="2"
              title="Messages"
              action={<AppLinks to={ContactBaseRoute}>View All</AppLinks>}
            >
              {contacts.contacts &&
                contacts.contacts
                  .filter((s, index) => s && index < 5)
                  .map(({ firstName, lastName }, i) => {
                    return (
                      <CardItem
                        key={i}
                        version="4"
                        text={`${firstName} ${lastName}`}
                      />
                    );
                  })}
            </Cards>
            <div>
              <Cards
                version="2"
                title="Testimonials"
                action={<AppLinks to={TestimoniesBaseRoute}>View All</AppLinks>}
              >
                {testimony.testimonies &&
                  testimony.testimonies
                    .filter((s, index) => s && index < 5)
                    .map(({ client, rating }, i) => {
                      return (
                        <CardItem
                          key={i}
                          version="4"
                          text={
                            <Grid col="l15">
                              <p>{client}</p>
                              <ReactStars
                                count={5}
                                size={14}
                                value={rating}
                                a11y={false}
                                edit={false}
                              />
                            </Grid>
                          }
                        />
                      );
                    })}
              </Cards>
              <Cards
                version="2"
                title="Contacts"
                action={<AppLinks to={ClientsBaseRoute}>View All</AppLinks>}
              >
                {clients.data &&
                  clients.data
                    .filter((s, index) => s && index < 5)
                    .map(({ firstName, lastName }, i) => {
                      return (
                        <CardItem
                          key={i}
                          version="4"
                          text={`${firstName} ${lastName}`}
                        />
                      );
                    })}
              </Cards>
            </div>
          </Grid>
        </Section>
      </Layout>
    </>
  );
};

export default HomePage;
