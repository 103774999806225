import { CardItem, Cards } from "../../components/cards";
import { useDispatch, useSelector } from "react-redux";

import DataTable from "react-data-table-component";
import { FetchBookings } from "../../redux/BookingSlice";
import { Link } from "react-router-dom";
import { Loader } from "../../components/loader";
import { Status } from "../../components/status";
import { onFormatDateTime } from "../../utils/onFormatDateTime";
import { useEffect } from "react";

const ClientBookingElement = (props) => {
  const { client } = props;

  const { bookings, loading } = useSelector((state) => state.bookings);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchBookings());
  }, [dispatch]);

  const columns = [
    {
      name: "Service Date",
      selector: ({ date }) => onFormatDateTime(date),
      sortable: true,
    },
    {
      name: "Service Time",
      selector: ({ date }) => onFormatDateTime(date, "time"),
      sortable: true,
    },
    {
      name: "Booking Status",
      sortable: true,
      center: true,
      selector: (row) => (
        <Status
          text={
            row.status === "IN_PROGRESS"
              ? "IN PROGRESS"
              : row.status === "COMPLETED"
              ? "COMPLETED"
              : "PENDING"
          }
          color={
            row.status === "IN_PROGRESS"
              ? "yellow"
              : row.status === "COMPLETED"
              ? "green"
              : "red"
          }
        />
      ),
    },
    {
      name: "Action",
      sortable: true,
      center: true,
      selector: (row) => (
        <Link to={`/auth/bookings/${row?.id}`} state={{ booking: row }}>
          View
        </Link>
      ),
    },
  ];

  if (loading) {
    return <Loader />;
  }
  const ClientData = bookings.filter((s) => s && s?.client?.id === client?.id);

  return (
    <Cards version="3" title={`${client.firstName} bookings`}>
      <CardItem>
        <DataTable
          columns={columns}
          data={ClientData}
          pagination
          highlightOnHover
          compact
          fixedHeader
          defaultSortAsc={true}
          responsive={true}
        />
      </CardItem>
    </Cards>
  );
};

export default ClientBookingElement;
