import { CardItem, Cards } from "../../components/cards";
import {
  CheckboxInput,
  FormButton,
  FormContainer,
  Input,
  RichTextField,
} from "../../components/forms";
import { Field, Form } from "react-final-form";
import { useLocation, useNavigate } from "react-router-dom";

import { Grid } from "../../components/layout";
import { ImageUploadComponent } from "../../components/imageUpload";
import { Label } from "../../components/forms";

const CreateForm = (props) => {
  const { onFormSubmit, initialValues, onHandleImageDelete } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const onSubmit = (values) => {
    onFormSubmit(values);
  };

  const onCloseandReset = (reset) => {
    reset();

    const direction = location.pathname.split("/").includes("edit")
      ? navigate(-1, { replace: true })
      : navigate("/auth/services", { replace: true });
    return direction;
  };

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({
        handleSubmit,
        pristine,
        submitting,
        invalid,
        form: {
          reset,
          mutators: { push },
        },
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid col="l15">
            <Cards title="Service Form" version="2">
              <CardItem version="5">
                <FormContainer>
                  <Field
                    label="Service Name"
                    name="title"
                    type="text"
                    component={Input}
                  />
                </FormContainer>
                <FormContainer>
                  <Field
                    label="Describe service"
                    name="description"
                    type="text"
                    component={RichTextField}
                  />
                </FormContainer>
                <FormContainer>
                  <Field
                    label="Features of service"
                    name="subDescription"
                    type="text"
                    component={RichTextField}
                  />
                </FormContainer>
                <FormContainer>
                  <Field
                    label="Featured"
                    name="featured"
                    type="checkbox"
                    component={CheckboxInput}
                  />
                </FormContainer>
              </CardItem>
            </Cards>
            <FormContainer>
              <Label>Upload Images</Label>
              <Field name="files">
                {(props) => {
                  return (
                    <ImageUploadComponent
                      name={props.input.name}
                      files={props.input.value}
                      onupdatefiles={props.input.onChange}
                      onremoveImage={onHandleImageDelete}
                      extraProp={initialValues?.media}
                    />
                  );
                }}
              </Field>
            </FormContainer>
          </Grid>
          <FormButton
            disabled={submitting || pristine || invalid}
            onCancelFunc={() => onCloseandReset(reset)}
            btnMessage={submitting ? "Saving" : "Save"}
          />
        </form>
      )}
    </Form>
  );
};

export default CreateForm;
