import {
  DeleteBlog,
  DeleteBlogPictures,
  EditBlog,
} from "../../redux/BlogSlice";

import BlogForm from "./BlogForm";
import { IoTrash } from "react-icons/io5";
import { Section } from "../../components/layout";
import { Text } from "../../components/typography";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const BlogEditPage = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();

  const onFormSubmit = (values) => {
    if (window.confirm(`Are you sure you want to save these edits?`)) {
      const args = {
        id: state.blog.id,
        values: values,
      };
      dispatch(EditBlog(args));
    }
  };

  const onDeleteSubmit = () => {
    if (
      window.confirm(`Are you sure you want to delete ${state.blog.title}?`)
    ) {
      dispatch(DeleteBlog(state.blog.id));
    }
  };

  const onHandleImageDelete = (fileName) => {
    const arg = {
      name: state.blog?.media?.name,
      fileName: fileName,
    };
    dispatch(DeleteBlogPictures(arg));
  };

  return (
    <Section>
      <SubNav>
        <li onClick={onDeleteSubmit}>
          <IoTrash />
          <Text>Delete</Text>
        </li>
      </SubNav>
      <BlogForm
        initialValues={state.blog}
        onFormSubmit={onFormSubmit}
        onHandleImageDelete={onHandleImageDelete}
      />
    </Section>
  );
};

export default BlogEditPage;

const SubNav = styled.nav`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 2rem;

  li {
    margin-left: 1.5rem;
    display: flex;
    align-items: center;
    color: red;

    p {
      margin: 0;
      padding: 0 0 0 0.5rem;
      color: red;
    }

    &:hover {
      cursor: pointer;
    }
  }
`;
