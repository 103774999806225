import {
  colors,
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
} from "./parameters";

import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
*, 
*::before, 
*::after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    letter-spacing: 1.2;
}

html{
    font-size: ${fontSize.def};
}

body{
    background: ${colors.gray100};
    font-family: ${fontFamily.body};
    line-height: ${lineHeight.body};
}

h1, h2, h3, h4, h5, h6{
    margin: 0 0 0.5rem;
    font-family: ${fontFamily.headings};
    font-weight: ${fontWeight};
    line-height: ${lineHeight.headings}
}


h1{
    margin-top: 0;
}


a{
    text-decoration: none;
    color: ${colors.black}
}

ol, li, ul{
    list-style: none;
}

img{
    width: 100%;
}


select {
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;   
}

.filepond-style{
    cursor: pointer;
}

.filepond--drop-label {
  background-color: #fff;
  border-radius: 5px;
}

.filepond--wrapper {
  margin-top: 1.6rem
}

.img-uploader-text {
  color: #000
  cursor: pointer;
}
.filepond--credits {
  display: none;
}

`;
