import { colors, responsiveness } from "../../../assets/styles/parameters";

import NavLinkItem from "./NavigationItem";
import styled from "styled-components";

export const NavigationLink = ({ paths, open, ...otherProps }) => {
  return (
    <NavLinkList open {...otherProps}>
      {paths.map((path, index) => {
        return <NavLinkItem key={index} name={path.name} path={path.path} />;
      })}
    </NavLinkList>
  );
};

export default NavigationLink;

const NavLinkList = styled.ul`
  display: flex;
  align-items: center;
  width: 100%;
  border-top: 1px solid transparent;

  li > a {
    color: ${colors.white};
  }

  @media only screen and (max-width: ${responsiveness.ipadMin}) {
    display: none;
  }
`;
