import { Text, Title } from "../../components/typography";
import { colors, responsiveness } from "../../assets/styles/parameters";

import styled from "styled-components";

export const DashCardItem = ({ title, data, ...otherProps }) => {
  return (
    <BaseListItem {...otherProps}>
      <Title number={6}>{title}</Title>
      <Text>{data}</Text>
    </BaseListItem>
  );
};

const BaseListItem = styled.div`
  padding: 0 0.5rem;
  width: 100%;
  margin: 0 0 0 1rem;
  border-radius: 5px;

  h6,
  p {
    margin: 0 0 0.5rem 0;
    padding: 0;
    color: ${colors.white};
    white-space: nowrap;
  }

  @media only screen and (max-width: ${responsiveness.mobile1}) {
    margin: 0;
    padding: 0;
  }
`;
