import { CardItem, Cards } from "../../components/cards";
import {
  CustomTextArea,
  FormButton,
  FormContainer,
  Input,
} from "../../components/forms";
import { Field, Form } from "react-final-form";
import { Text, Title } from "../../components/typography";
import { useLocation, useNavigate } from "react-router-dom";

import { FieldArray } from "react-final-form-arrays";
import { Grid } from "../../components/layout";
import { IoBusiness } from "react-icons/io5";
import arrayMutators from "final-form-arrays";

const ClientCreateForm = (props) => {
  const { onFormSubmit, initialValues } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const onSubmit = (values) => {
    onFormSubmit(values);
  };

  const onCloseandReset = (reset) => {
    reset();

    const direction = location.pathname.split("/").includes("edit")
      ? navigate(-1, { replace: true })
      : navigate("/auth/clients", { replace: true });
    return direction;
  };

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        mutators={{
          ...arrayMutators,
        }}
      >
        {({
          handleSubmit,
          pristine,
          submitting,
          invalid,
          form: {
            reset,
            mutators: { push },
          },
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid col="l15">
              <Cards title="Client Information" version="2">
                <CardItem version="5">
                  <FormContainer>
                    <Grid col={3}>
                      <Field
                        name="firstName"
                        type="text"
                        component={Input}
                        label="First Name"
                      />
                      <Field
                        name="lastName"
                        type="text"
                        component={Input}
                        label="Last Name"
                      />
                      <Field
                        name="title"
                        type="text"
                        component={Input}
                        label="Title"
                      />
                      <Field
                        label="Organization"
                        name="organization"
                        type="text"
                        component={Input}
                      />
                    </Grid>
                  </FormContainer>
                  <FormContainer>
                    <Grid>
                      <Field
                        label="Phone Number"
                        name="phone"
                        type="phone"
                        component={Input}
                      />
                      <Field
                        label="Email"
                        name="email"
                        type="email"
                        component={Input}
                      />
                    </Grid>
                  </FormContainer>
                  <FieldArray name="addresses">
                    {({ fields }) => (
                      <>
                        {fields.map((addresses, index) => (
                          <div key={index}>
                            <FormContainer>
                              <Field
                                name={`${addresses}.street`}
                                type="text"
                                component={Input}
                                label="Street"
                              />
                              <Grid col={3}>
                                <Field
                                  name={`${addresses}.city`}
                                  type="text"
                                  component={Input}
                                  label="City"
                                />
                                <Field
                                  name={`${addresses}.state`}
                                  type="text"
                                  component={Input}
                                  label="State"
                                />
                                <Field
                                  name={`${addresses}.zipcode`}
                                  type="text"
                                  component={Input}
                                  label="Zip Code"
                                />
                              </Grid>
                            </FormContainer>
                            <Text
                              color="red"
                              onClick={() => fields.remove(index)}
                            >
                              Remove Address {index + 1}
                            </Text>
                          </div>
                        ))}
                      </>
                    )}
                  </FieldArray>
                  <Text
                    onClick={() => push("addresses", undefined)}
                    disabled={submitting}
                  >
                    Add Address <IoBusiness />
                  </Text>
                  <FormContainer>
                    <Title number={6}>Notes</Title>
                    <Field
                      label="Write note for future use."
                      name="notes"
                      type="text"
                      component={CustomTextArea}
                    />
                  </FormContainer>
                </CardItem>
              </Cards>
            </Grid>
            <FormButton
              disabled={submitting || pristine || invalid}
              onCancelFunc={() => onCloseandReset(reset)}
              btnMessage={submitting ? "Saving Client" : "Save Client"}
            />
          </form>
        )}
      </Form>
    </>
  );
};

export default ClientCreateForm;
