import { CreateFAQ } from "../../redux/FAQSlice";
import FAQForm from "./FAQForm";
import { Section } from "../../components/layout";
import { useDispatch } from "react-redux";

export const FAQCreatePage = () => {
  const dispatch = useDispatch();

  const onFormSubmit = (values) => {
    dispatch(CreateFAQ(values));
  };

  return (
    <Section>
      <FAQForm onFormSubmit={onFormSubmit} />
    </Section>
  );
};

export default FAQCreatePage;
