import { Text, Title } from "../typography";
import { colors, responsiveness, shadow } from "../../assets/styles/parameters";

import { motion } from "framer-motion";
import styled from "styled-components";

export const PopupContainer = ({
  title,
  description,
  onOpenPopUp,
  onClosePopUp,
  children,
  ...otherProps
}) => {
  return (
    <BasePopUp {...otherProps} open={onOpenPopUp}>
      <PopUpHeader>
        <div>
          <Title number={6}>{title}</Title>
          {description && <Text>{description}</Text>}
        </div>
        <span onClick={onClosePopUp}>X</span>
      </PopUpHeader>
      <PopUpContent>{children}</PopUpContent>
    </BasePopUp>
  );
};

export default PopupContainer;

const BasePopUp = styled(motion.div)`
  display: ${({ open }) => (open ? "block" : "none")};
  background-color: white;
  position: absolute;
  top: 5%;
  border-radius: 5px;
  width: 50%;
  z-index: 1000;
  left: 25%;
  box-shadow: ${shadow.dark};

  @media only screen and (max-width: ${responsiveness.mobile1}) {
    width: 80%;
    left: 10%;
  }
`;

const PopUpHeader = styled.header`
  display: flex;
  justify-content: space-between;

  border-bottom: 1px solid ${colors.gray200};
  padding: 1rem 2rem;

  span {
    height: min-content;
  }

  h6,
  p {
    margin: 0;
  }

  h6 {
    font-size: 1.5rem;
  }
`;

const PopUpContent = styled.div`
  padding: 1rem 2rem;
  max-height: 90%;
  overflow-y: scroll;
`;
