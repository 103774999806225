import {
  CheckboxInput,
  CustomTextArea,
  FormButton,
  FormContainer,
  Input,
  Label,
} from "../../components/forms";
import { Field, Form } from "react-final-form";
import { useLocation, useNavigate } from "react-router-dom";

import { Grid } from "../../components/layout";
import ReactStars from "react-rating-stars-component";
import styled from "styled-components";

const TestimonyForm = (props) => {
  const { onFormSubmit, onClose, initialValues } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const onCloseandReset = (reset) => {
    reset();

    if (!location.pathname.split("/").includes("create")) {
      onClose();
    }

    const direction = location.pathname.split("/").includes("edit")
      ? navigate(-1, { replace: true })
      : navigate("/auth/testimonies", { replace: true });
    return direction;
  };

  const StarsAdapter = ({ input: { onChange, ...props }, label }) => (
    <BaseStar>
      <Label>Rating</Label>
      <ReactStars
        count={5}
        size={15}
        onChange={(val) => onChange(parseInt(val))}
        value={props.value === "" ? 0 : parseInt(props.value)}
        a11y={false}
      />
    </BaseStar>
  );

  return (
    <Form
      onSubmit={(values) => onFormSubmit(values)}
      initialValues={initialValues}
    >
      {({ handleSubmit, pristine, submitting, invalid, form: { reset } }) => (
        <form onSubmit={handleSubmit}>
          <FormContainer>
            <Field
              name="client"
              type="text"
              component={Input}
              label="Client Name"
            />
          </FormContainer>
          <FormContainer>
            <Field
              label="Testimonial"
              name="message"
              type="text"
              component={CustomTextArea}
            />
          </FormContainer>
          <FormContainer>
            <Grid>
              <Field
                name="rating"
                type="number"
                min="0"
                max="5"
                component={StarsAdapter}
                label="rating"
              />
              <Field
                label="Featured"
                name="featured"
                type="checkbox"
                component={CheckboxInput}
              />
            </Grid>
          </FormContainer>
          <FormButton
            disabled={submitting || pristine || invalid}
            onCancelFunc={() => onCloseandReset(reset)}
            btnMessage={submitting ? "Saving" : "Save"}
          />
        </form>
      )}
    </Form>
  );
};

export default TestimonyForm;

const BaseStar = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  label {
    margin: 0 1rem 0 0;
    p {
      padding: 0;
      margin: 0;
    }
  }
`;
