import BlogReducer from "./BlogSlice";
import BookingReducer from "./BookingSlice";
import ClientReducer from "./ClientSlice";
import ContactReducer from "./ContactSlice";
import CredReducer from "./CompCredSlice";
import FAQReducer from "./FAQSlice";
import GalleryReducer from "./GallerySlice";
import MediaReducer from "./MediaSlice";
import NavigationReducer from "./NavigationSlice";
import OrgReducer from "./CompanySlice";
import PartnerReducer from "./PartnerSlice";
import ServicesReducer from "./ServiceSlice";
import StaffReducer from "./StaffSlice";
import TestimonyReducer from "./TestimonySlice";
import UserReducer from "./UserSlice";
import { combineReducers } from "redux";

export const rootReducer = combineReducers({
  sideNav: NavigationReducer,
  bookings: BookingReducer,
  blog: BlogReducer,
  company: OrgReducer,
  clients: ClientReducer,
  cred: CredReducer,
  contacts: ContactReducer,
  partner: PartnerReducer,
  staff: StaffReducer,
  services: ServicesReducer,
  testimony: TestimonyReducer,
  gallery: GalleryReducer,
  faq: FAQReducer,
  user: UserReducer,
  media: MediaReducer,
});
