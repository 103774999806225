import { colors } from "../assets/styles/parameters";

export const CardScroll = `
::-webkit-scrollbar {
  width: 1px;
  height: 10rem;
}

::-webkit-scrollbar-track {
  background: ${colors.gray100};
}

::-webkit-scrollbar-thumb {
background: ${colors.gray400};
}

::-webkit-scrollbar-thumb:hover {
  background: ${colors.gray700};
}


`;
