import { DeleteCred, DeleteCredPic, EditCred } from "../../redux/CompCredSlice";
import { Grid, Section } from "../../components/layout";
import { useLocation, useNavigate } from "react-router-dom";

import { CredBaseRoute } from "../../routes/Paths";
import CredForm from "./CredForm";
import { IoTrash } from "react-icons/io5";
import { Text } from "../../components/typography";
import styled from "styled-components";
import { useDispatch } from "react-redux";

const CredEditPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();

  const onFormSubmit = (values) => {
    if (window.confirm(`Are you sure you want to update this?`)) {
      const args = {
        id: state.cred.id,
        values: values,
      };
      dispatch(EditCred(args));
      setTimeout(() => {
        navigate(CredBaseRoute);
      }, 2000);
    }
  };

  const onDeleteSubmit = () => {
    if (window.confirm(`Are you sure you want to delete this?`)) {
      dispatch(DeleteCred(state.cred));
    }
  };

  const onHandleImageDelete = (fileName) => {
    console.log(state);
    const arg = {
      id: state.cred.id,
      name: state.cred?.media?.name,
      fileName: fileName,
    };

    if (window.confirm(`Are you sure you want to delete this?`)) {
      dispatch(DeleteCredPic(arg));
    }
  };

  return (
    <Section>
      <SubNav>
        <li onClick={onDeleteSubmit}>
          <IoTrash />
          <Text>Delete</Text>
        </li>
      </SubNav>
      <Grid col="l15">
        <CredForm
          initialValues={state.cred}
          onFormSubmit={onFormSubmit}
          onHandleImageDelete={onHandleImageDelete}
        />
      </Grid>
    </Section>
  );
};

export default CredEditPage;

const SubNav = styled.nav`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 2rem;

  li {
    margin-left: 1.5rem;
    display: flex;
    align-items: center;
    color: red;

    p {
      margin: 0;
      padding: 0 0 0 0.5rem;
      color: red;
    }

    &:hover {
      cursor: pointer;
    }
  }
`;
