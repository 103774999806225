import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { eces } from "../api";
import { onAlert } from "../utils/onAlerts";

const BASEPATH = "mediaapi/api/v1/media";
const INITIALSTATE = {
  data: [],
  isSuccess: false,
  error: "",
  loading: true,
};

export const MediaBaseRouteDelete = (name, fileName) =>
  `mediaapi/api/v1/media/byName/${name}/${fileName}`;

export const FetchObjectMedia = createAsyncThunk(
  "media/fetchbyObject",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.get(`${BASEPATH}/byName/${args}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const DeleteMedia = createAsyncThunk(
  "media/delete",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.delete(
        MediaBaseRouteDelete(args.name, args.fileName)
      );
      return response.status;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const mediaReducer = createSlice({
  name: "media",
  initialState: INITIALSTATE,
  extraReducers: {
    [FetchObjectMedia.pending]: (state) => {
      state.loading = true;
    },
    [FetchObjectMedia.fulfilled]: (state, action) => {
      state.loading = false;
      state.isSuccess = true;
      state.data = action.payload;
    },
    [FetchObjectMedia.rejected]: (state, action) => {
      state.loading = false;
      state.isSuccess = true;
      state.error = action.payload;
    },
    [DeleteMedia.pending]: (state) => {
      state.loading = true;
    },
    [DeleteMedia.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.isSuccess = true;
      onAlert("success", "Image Deleted");
    },
    [DeleteMedia.rejected]: (state, action) => {
      state.loading = false;
      state.isSuccess = false;
      state.error = action.payload;
    },
  },
});

export default mediaReducer.reducer;
