import { SettingsInfoPage, SettingsPage } from "../pages";

import { Route } from "react-router-dom";

export const SettingsRoutes = () => {
  return (
    <Route path="/auth/user" element={<SettingsPage />}>
      <Route index element={<SettingsInfoPage />} />
    </Route>
  );
};
