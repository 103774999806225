import { IoCheckboxOutline, IoCloseSharp } from "react-icons/io5";
import { PartnerBaseRouteId, PartnerCreateRoute } from "../../routes/Paths";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "../../components/button";
import { FetchPartners } from "../../redux/PartnerSlice";
import { IoPencilOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { Section } from "../../components/layout";
import { Table } from "../../components/table";
import styled from "styled-components";
import { useEffect } from "react";

const PartnerListPage = () => {
  const { data } = useSelector((state) => state.partner);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchPartners());
  }, [dispatch]);

  const columns = [
    {
      name: "Industries",
      sortable: true,
      selector: (row) => ` ${row.type}`,
    },
    {
      name: "Featured",
      sortable: true,
      center: true,
      selector: (row) => row.featured,
      cell: (row) => (
        <div>{row.featured ? <IoCheckboxOutline /> : <IoCloseSharp />}</div>
      ),
    },

    {
      cell: (row) => (
        <DataRowActionsContainer>
          <Link
            to={PartnerBaseRouteId(row.id)}
            params={{ partner: row }}
            state={{ partner: row }}
          >
            <IoPencilOutline />
          </Link>
        </DataRowActionsContainer>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];

  return (
    <Section>
      <SubNav>
        <Button type="link" path={PartnerCreateRoute}>
          Add Partner Industry
        </Button>
      </SubNav>
      <Table data={data} columns={columns} defaultSortFieldId="createdDate" />
    </Section>
  );
};

export default PartnerListPage;

const DataRowActionsContainer = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0;
    padding: 0;
  }

  svg {
    margin-right: 1rem;
    cursor: pointer;
  }
`;

const SubNav = styled.nav`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 2rem;

  li {
    margin-left: 1.5rem;
    display: flex;
    align-items: center;
  }
`;
