import { createSlice } from "@reduxjs/toolkit";

export const navigationSlice = createSlice({
  name: "navigation",
  initialState: { navOpen: false },
  reducers: {
    OpenSideNav: (state) => {
      state.navOpen = true;
    },
    CloseSideNav: (state) => {
      state.navOpen = false;
    },
  },
});

export const { OpenSideNav, CloseSideNav } = navigationSlice.actions;

export default navigationSlice.reducer;
