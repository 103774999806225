import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { eces } from "../api";
import { onAlert } from "../utils/onAlerts";

const BASEPATH = "contactapi/api/v1/contacts";
const INITIALSTATE = {
  loading: true,
  contacts: [],
  contact: {},
  error: "",
};

export const FetchMessages = createAsyncThunk(
  "contact/messages/fetchAll",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.get(BASEPATH);

      return {
        data:
          response.data && Array.isArray(response.data) ? response.data : [],
      };
    } catch (error) {
      return rejectWithValue({
        error:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  }
);

export const CreateMessage = createAsyncThunk(
  "contact/messages/create",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.post(`${BASEPATH}`, args);

      return {
        data: response.data,
      };
    } catch (error) {
      return rejectWithValue({
        error:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  }
);

export const EditMessage = createAsyncThunk(
  "contact/messages/edit",
  async (args, { rejectWithValue, getState }) => {
    try {
      const currentContacts = getState().contacts.contacts.filter((message) => {
        return message.id !== args.id;
      });

      const route =
        args.metadata.messageRead === true ? "markAsRead" : "markAsUnRead";

      const response = await eces.patch(
        `${BASEPATH}/${args.id}/${route}`,
        args
      );

      const newContact = [...currentContacts, response.data];

      return newContact;
    } catch (error) {
      return rejectWithValue({
        error:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  }
);

export const DeleteMessage = createAsyncThunk(
  "contact/messages/delete",
  async (args, { rejectWithValue, getState }) => {
    const contacts = getState().contacts?.contacts;
    const data = contacts.filter((item) => item !== args.data);

    try {
      await eces.delete(`${BASEPATH}?id=${args.id}`);
      return {
        data: data,
      };
    } catch (error) {
      return rejectWithValue({
        error:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  }
);

export const contactSlice = createSlice({
  name: "contacts",
  initialState: INITIALSTATE,
  extraReducers: (builder) => {
    builder
      .addCase(FetchMessages.pending, (state) => {
        state.loading = true;
      })
      .addCase(FetchMessages.fulfilled, (state, action) => {
        state.loading = false;
        state.contacts = action.payload.data;
      })
      .addCase(FetchMessages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
        onAlert("error", action.payload.error);
      })
      .addCase(CreateMessage.pending, (state) => {
        state.loading = true;
      })
      .addCase(CreateMessage.fulfilled, (state, action) => {
        state.loading = false;
        onAlert("success", "Message Created");
      })
      .addCase(CreateMessage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
        onAlert("error", action.payload.error);
      })
      .addCase(EditMessage.pending, (state) => {
        state.loading = true;
      })
      .addCase(EditMessage.fulfilled, (state, action) => {
        state.loading = false;
        state.contacts = action.payload;
        onAlert("success", "Message Edited");
      })
      .addCase(EditMessage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
        onAlert("error", action.payload.error);
      })
      .addCase(DeleteMessage.pending, (state) => {
        state.loading = true;
      })
      .addCase(DeleteMessage.fulfilled, (state, action) => {
        state.loading = false;
        state.contacts = action.payload.data;
        onAlert("success", "Message Deleted");
      })
      .addCase(DeleteMessage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
        onAlert("error", action?.payload?.error);
      });
  },
});

export default contactSlice.reducer;
