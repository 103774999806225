import { Span, Text, Title } from "../../components/typography";

import styled from "styled-components";

export const Profile = ({ name, email, phone, address }) => {
  return (
    <BaseProfile>
      <div>
        <Title number={6}>{name}</Title>
        <Text scale="sm" color="gray">
          Email: <Span>{email ? email : "No email address"}</Span>
        </Text>
        <Text scale="sm" color="gray">
          Phone: <Span color="black">{phone ? phone : "No phone number"}</Span>
        </Text>
        <Text scale="sm" color="gray">
          Addresses:{" "}
          <Span>
            <address>{address ? address : "No address"}</address>
          </Span>
        </Text>
      </div>
    </BaseProfile>
  );
};

const BaseProfile = styled.div`
  h6 {
    margin-bottom: 1rem;
    padding: 0;
  }
  address {
    font-style: normal;
  }
`;
