import { CredCreateRoute, CredEditRoute } from "../../routes/Paths";
import { DeleteCred, FetchCreds } from "../../redux/CompCredSlice";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "../../components/button";
import { IoPencilOutline } from "react-icons/io5";
import { IoTrashOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { Section } from "../../components/layout";
import { Table } from "../../components/table";
import { onAlert } from "../../utils/onAlerts";
import { onFormatDateTime } from "../../utils/onFormatDateTime";
import styled from "styled-components";
import { useEffect } from "react";

const CredListPage = () => {
  const { creds, loading } = useSelector((state) => state.cred);
  const dispatch = useDispatch();

  const onDeleteWarning = (data) => {
    if (window.confirm(`Are you sure you want to delete this? ${data.id}`)) {
      const args = {
        id: data.id,
        data: data,
      };
      dispatch(DeleteCred(args));
    }
    onAlert("Deleted");
  };

  useEffect(() => {
    dispatch(FetchCreds());
  }, [dispatch]);

  const columns = [
    {
      name: "Contacted Date",
      selector: ({ createdDate }) => onFormatDateTime(createdDate),
      sortable: true,
    },
    {
      name: "Recognition",
      sortable: true,
      selector: (row) => row.title,
    },
    {
      cell: (row) => (
        <DataRowActionsContainer>
          <Link to={CredEditRoute(row.id)} state={{ cred: row }}>
            <IoPencilOutline />
          </Link>
        </DataRowActionsContainer>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
    {
      cell: (row) => (
        <DataRowActionsContainer>
          <IoTrashOutline onClick={() => onDeleteWarning(row)} />
        </DataRowActionsContainer>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];
  return (
    <>
      <Section>
        <SubNav>
          <li>
            <Button type="link" path={CredCreateRoute}>
              Add Credentials
            </Button>
          </li>
        </SubNav>
        <Table
          columns={columns}
          data={creds}
          defaultSortFieldId="createdDate"
        />
      </Section>
    </>
  );
};

export default CredListPage;

const DataRowActionsContainer = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0;
    padding: 0;
  }

  svg {
    margin-right: 1rem;
    cursor: pointer;
  }
`;

const SubNav = styled.nav`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 2rem;

  li {
    margin-left: 1.5rem;
  }
`;
