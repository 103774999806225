import { Grid, Section } from "../../components/layout";
import { Text, Title } from "../../components/typography";
import { colors, fontSize, shadow } from "../../assets/styles/parameters";

import { Link } from "react-router-dom";
import styled from "styled-components";

const ServiceDetailPage = () => {
  return (
    <Section>
      <Grid col="l15">
        <div>
          <Title>Order Detail. No. 13345959</Title>
        </div>
        <div>
          <BaseCard>
            <Title number={5}>Customer Detail</Title>
            <Title number={6}>John Doe</Title>
            <Text>
              Email:
              <a href="mailto:jdoe@gmail.com"> jdoe@gmail.com</a>
            </Text>
            <Text>
              Phone:
              <a href="tel:+2043033043"> 204-303-3043</a>
            </Text>{" "}
            <Text>
              Cell:
              <a href="tel:+2043033043"> 204-303-3043</a>
            </Text>
            <address>
              <Title number={6}>Billing Address</Title>
              <Text>2223 Laurel St</Text>
              <Text>Laurel, MD 203949</Text>
            </address>
            <address>
              <Title number={6}>Service Address</Title>
              <Text>2223 Laurel St</Text>
              <Text>Laurel, MD 203949</Text>
            </address>
          </BaseCard>
          <BaseItemList>
            <Title number={5}>Previous Order</Title>
            <BaseListItem>
              <Text>June 12, 2022</Text>
              <div>
                <Link to="/">Order No. 23293923</Link>
                <Text>Status</Text>
              </div>{" "}
            </BaseListItem>
            <BaseListItem>
              <Text>June 12, 2022</Text>
              <div>
                <Link to="/">Order No. 23293923</Link>
                <Text>Status</Text>
              </div>{" "}
            </BaseListItem>{" "}
            <BaseListItem>
              <Text>June 12, 2022</Text>
              <div>
                <Link to="/">Order No. 23293923</Link>
                <Text>Status</Text>
              </div>{" "}
            </BaseListItem>
          </BaseItemList>
        </div>
      </Grid>
      <div>Order Status</div>
    </Section>
  );
};

export default ServiceDetailPage;

const BaseCard = styled.div`
  background-color: ${colors.white};
  box-shadow: ${shadow.light};
  padding: 1rem 0rem;
  border-radius: 5px;
  margin-bottom: 2rem;
  padding: 1rem 0.9rem;

  & > * {
    margin: 0.7rem 0 0;
  }

  a {
    margin-bottom: 0.4rem;
  }

  address {
    font-style: normal;

    * {
      margin: 0;
    }
  }
`;

const BaseItemList = styled.ul`
  background-color: ${colors.white};
  box-shadow: ${shadow.light};
  padding: 1rem 0rem;
  border-radius: 5px;

  h5 {
    margin-left: 0.5rem;
  }
`;

const BaseListItem = styled.li`
  padding: 0.5rem;
  background-color: ${colors.white};
  border-top: 1px solid ${colors.gray400};

  p {
    color: ${colors.gray600};
    font-size: ${fontSize.nav};
  }

  div {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  * {
    margin: 0;
  }

  &:hover {
    background-color: ${colors.blue};
  }
`;
