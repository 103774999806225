import { CardItem, Cards } from "../../components/cards";
import {
  CheckboxInput,
  CustomTextArea,
  FormButton,
  FormContainer,
  Input,
  Label,
  RichTextField,
} from "../../components/forms";
import { Field, Form } from "react-final-form";
import { useLocation, useNavigate } from "react-router-dom";

import { BlogBaseRoute } from "../../routes/Paths";
import { Grid } from "../../components/layout";
import { ImageUploadComponent } from "../../components/imageUpload";

const BlogForm = (props) => {
  const { onFormSubmit, initialValues, onHandleImageDelete } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const onSubmit = (values) => {
    onFormSubmit(values);
  };

  const onCloseandReset = (reset) => {
    reset();

    const direction = location.pathname.split("/").includes("edit")
      ? navigate(-1, { replace: true })
      : navigate(BlogBaseRoute, { replace: true });
    return direction;
  };

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit, pristine, submitting, invalid, form: { reset } }) => (
        <form onSubmit={handleSubmit}>
          <Grid col="l15">
            <Cards title="Blog Post Form" version="2">
              <CardItem version="5">
                <FormContainer>
                  <Field
                    name="title"
                    type="text"
                    component={Input}
                    label="Title"
                    placeholder="What is the article name?"
                  />
                </FormContainer>
                <FormContainer>
                  <Field
                    name="summary"
                    type="text"
                    component={CustomTextArea}
                    label="Headline"
                    placeholder="Enter featured summary"
                  />
                </FormContainer>
                <FormContainer>
                  <Label>Article Body</Label>
                  <Field name="content" component={RichTextField} />
                </FormContainer>
                <FormContainer>
                  <Grid>
                    <Field
                      name="author"
                      type="text"
                      component={Input}
                      label="Author"
                      placeholder="Name of author"
                    />
                  </Grid>
                </FormContainer>
                <FormContainer>
                  <Grid>
                    <Field
                      label="Feature article on website"
                      name="featured"
                      type="checkbox"
                      component={CheckboxInput}
                    />
                  </Grid>
                </FormContainer>
              </CardItem>
            </Cards>
            <FormContainer>
              <Label>Upload Images</Label>
              <Field name="files">
                {(props) => {
                  return (
                    <ImageUploadComponent
                      name={props.input.name}
                      files={props.input.value}
                      onupdatefiles={props.input.onChange}
                      onremoveImage={onHandleImageDelete}
                      extraProp={initialValues?.media}
                    />
                  );
                }}
              </Field>
            </FormContainer>
          </Grid>
          <FormButton
            disabled={submitting || pristine || invalid}
            onCancelFunc={() => onCloseandReset(reset)}
            btnMessage={submitting ? "Saving data....." : "Save Article"}
          />
        </form>
      )}
    </Form>
  );
};

export default BlogForm;
