import { CardItem, Cards } from "../../components/cards";
import { Grid, Section } from "../../components/layout";
import { useDispatch } from "react-redux";

import { CreateTestimony } from "../../redux/TestimonySlice";
import TestimonyForm from "./TestimonyForm";

const TestimonyCreatePage = () => {
  const dispatch = useDispatch();

  const onFormSubmit = (values) => {
    if (window.confirm(`Are you sure you want to add this testimonial?`)) {
      dispatch(CreateTestimony(values));
    }
  };

  return (
    <Section>
      <Grid>
        <Cards title="Add New Testimonial" version="2">
          <CardItem version="5">
            <TestimonyForm onFormSubmit={onFormSubmit} />
          </CardItem>
        </Cards>
      </Grid>
    </Section>
  );
};

export default TestimonyCreatePage;
