import { CreateGalleryProject } from "../../redux/GallerySlice";
import GalleryForm from "./GalleryForm";
import { Section } from "../../components/layout";
import { useDispatch } from "react-redux";

const GalleryCreatePage = () => {
  const dispatch = useDispatch();

  const onFormSubmit = (values) => {
    if (
      window.confirm(
        `Are you sure you want to add this project to the gallery?`
      )
    ) {
      dispatch(CreateGalleryProject(values));
    }
  };

  return (
    <Section>
      <GalleryForm onFormSubmit={onFormSubmit} />
    </Section>
  );
};

export default GalleryCreatePage;
