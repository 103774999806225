import {
  DeleteTestimony,
  EditTestimony,
  FetchTestimonies,
} from "../../redux/TestimonySlice";
import { Grid, Section } from "../../components/layout";
import {
  IoCheckboxOutline,
  IoCloseSharp,
  IoPencilOutline,
  IoTrashOutline,
} from "react-icons/io5";
import {
  RowActionsContainer,
  Table,
  TableExpandContainer,
} from "../../components/table";
import { Text, Title } from "../../components/typography";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { Button } from "../../components/button";
import Moment from "moment";
import ReactStars from "react-rating-stars-component";
import { TestimonyCreateRoute } from "../../routes/Paths";
import TestimonyEditPopUp from "./TestimonialEditPopup";
import { onFormatDateTime } from "../../utils/onFormatDateTime";
import styled from "styled-components";

const TestimonialListPage = () => {
  const [openEditForm, setOpenEditForm] = useState(false);
  const [selectedTestimony, setSelectedTestimony] = useState({});

  const { testimonies } = useSelector((state) => state.testimony);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchTestimonies());
  }, [dispatch]);

  const onOpenEditPopUp = () => {
    return setOpenEditForm(!openEditForm);
  };

  const onEditingTestimony = (data) => {
    setSelectedTestimony(data);
    onOpenEditPopUp();
  };

  const onUpdatedFeature = (values) => {
    const id = values.id;
    const featured = values.featured;

    const value = { ...values, featured: !featured };
    dispatch(EditTestimony({ id, value }));
  };

  const onDeleteTestimony = (data) => {
    const value = {
      id: data.id,
      data: data,
    };

    if (
      window.confirm(
        `Are you sure you want to delete this testimony from ${data.client}?`
      )
    ) {
      dispatch(DeleteTestimony(value));
    }
  };

  const ExpandeableActionsContainer = (props) => {
    const { data } = props;
    return (
      <TableExpandContainer
        main={
          <div>
            <div>
              <Title number={5}>{data.client}</Title>
              <Grid>
                <div>
                  <Text number={5} scale="small" color="gray">
                    Rating
                  </Text>
                  <ReactStars
                    count={5}
                    size={14}
                    value={data.rating}
                    a11y={false}
                    edit={false}
                  />
                  <br />
                  <div>
                    <Text scale="small" color="gray">
                      Testimony
                    </Text>
                    <Text>{data.message}</Text>
                  </div>
                </div>
                <div>
                  <Text scale="small" color="gray">
                    Created on {onFormatDateTime(data.createdDate)} at
                    {onFormatDateTime(data.createdDate, "time")}
                  </Text>
                </div>
              </Grid>
            </div>
          </div>
        }
        sub={
          <>
            <Text scale="small" color="gray">
              Actions
            </Text>
            <div>
              <div onClick={() => onEditingTestimony(data)}>
                <IoPencilOutline />
                <Text>Edit</Text>
              </div>
            </div>
          </>
        }
      />
    );
  };

  const columns = [
    {
      name: "Contacted Date",
      selector: ({ createdDate }) => Moment(createdDate).format("MMM DD, YYYY"),
      sortable: true,
      reorder: true,
      hide: "sm",
    },
    {
      name: "Customer Name",
      sortable: true,
      selector: (row) => `${row.client}`,
    },
    {
      name: "Rating",
      sortable: true,
      center: true,
      hide: "sm",
      selector: (row) => row.rating,
      cell: (row) => (
        <ReactStars
          count={5}
          size={14}
          value={row.rating}
          a11y={false}
          edit={false}
        />
      ),
    },
    {
      name: "Featured",
      sortable: true,
      center: true,
      selector: (row) => (
        <RowActionsContainer onClick={() => onUpdatedFeature(row)}>
          {row.featured ? <IoCheckboxOutline /> : <IoCloseSharp />}
        </RowActionsContainer>
      ),
      hide: "md",
    },
    {
      cell: (row) => (
        <RowActionsContainer>
          <div onClick={() => onDeleteTestimony(row)}>
            <IoTrashOutline />
          </div>
        </RowActionsContainer>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];

  return (
    <Section>
      <SubNav>
        <Button type="link" path={TestimonyCreateRoute}>
          Add Testimonial
        </Button>
      </SubNav>
      <Table
        columns={columns}
        data={testimonies}
        expandableRowsComponent={ExpandeableActionsContainer}
      />
      <TestimonyEditPopUp
        open={openEditForm}
        close={onOpenEditPopUp}
        initialValues={selectedTestimony}
      />
    </Section>
  );
};

export default TestimonialListPage;

const SubNav = styled.nav`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 2rem;

  li {
    margin-left: 1.5rem;
    display: flex;
    align-items: center;
  }
`;
