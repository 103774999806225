import { colors, responsiveness, shadow } from "../../assets/styles/parameters";
import { useDispatch, useSelector } from "react-redux";

import LoginForm from "./LoginForm";
import { Text } from "../../components/typography";
import { UserLogin } from "../../redux/UserSlice";
import { debounce } from "../../utils/misc";
import img from "../../assets/imgs/backgroundImage1.jpg";
import logo from "../../assets/imgs/extreme_logo.svg";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useEffect } from "react";

const LoginPage = () => {
  const { error, data } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    if (values && !data.user) {
      dispatch(UserLogin(values));
    }
  };

  const showAlert = debounce(() => {
    toast.warning("Logged out due to inactivity");
    window.history.replaceState({}, document.title, "/");
  }, 1000);

  useEffect(() => {
    // This gets the entire string for URL parameters
    const queryString = window.location.search;
    // This parses the parameters, and now they're in a Set
    const urlParams = new URLSearchParams(queryString);
    // Get loggedout
    const loggedout = urlParams.get("loggedout");

    if (loggedout) showAlert();
  }, []);

  return (
    <Wrapper background={img}>
      <div>
        <img src={logo} alt="Logo" />
        <LoginForm onSubmit={onSubmit} errMessage={error} />
        <BaseFooterSub scale="sm">
          &copy; Copyright{" "}
          <span className="year">{new Date().getFullYear()}</span> Extreme Clean
          Environmental Solutions 501(c) (3) - All Rights Reserved
        </BaseFooterSub>
      </div>
    </Wrapper>
  );
};

export default LoginPage;

const Wrapper = styled.section`
  background: url(${({ background }) => background});
  background-size: cover;
  background-position: center;
  display: grid;
  grid-template-columns: 0.6fr 1fr;
  height: 100vh;

  img {
    text-align: center;
    width: 10rem;
  }

  div:first-child {
    background-color: ${colors.gray100};
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  @media screen and (max-width: ${responsiveness.mobile1}) {
    grid-template-columns: 1fr;
    grid-auto-flow: dense;

    div:first-child {
      padding: 1rem;
      box-shadow: ${shadow.dark};
    }
  }
`;

const BaseFooterSub = styled(Text)`
  color: ${colors.gray600};
  font-size: 0.5rem;
  padding: 0;
  margin: 4rem 0 0 0;
`;
