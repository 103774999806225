import { Text, Title } from "../typography";
import {
  colors,
  fontSize,
  responsiveness,
  shadow,
} from "../../assets/styles/parameters";

import { AiOutlineCaretDown } from "react-icons/ai";
import styled from "styled-components";
import { useState } from "react";

export const ActionDropdownLarge = ({
  iconTitle,
  title,
  children,
  icon,
  ...otherProps
}) => {
  const [show, setShow] = useState(false);

  const ShowDropdown = () => {
    return setShow(!show);
  };

  return (
    <BaseDiv {...otherProps} onMouseLeave={() => setShow(false)}>
      {iconTitle && (
        <Title number={6} onClick={() => ShowDropdown()}>
          {iconTitle}
        </Title>
      )}
      {icon && <AiOutlineCaretDown onClick={() => ShowDropdown()} />}
      <BaseContainer show={show}>
        <BaseContainerHeader>
          {title && <Text color="gray">{title}</Text>}
        </BaseContainerHeader>

        <div>{children}</div>
      </BaseContainer>
    </BaseDiv>
  );
};

export default ActionDropdownLarge;

const BaseDiv = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 1rem;

  svg,
  h6 {
    cursor: pointer;
  }

  h6 {
    font-size: 0.8rem;
    padding: 0;
    margin: 0;
    white-space: nowrap;
  }
  svg {
    margin-left: 0.1rem;
    font-size: ${fontSize.h6};
  }
`;

const BaseContainer = styled.div`
  display: ${({ show }) => (show ? "block" : "none")};
  position: absolute;
  z-index: 1000;
  right: 0;
  top: 100%;
  background: ${colors.white};
  box-shadow: ${shadow.light};
  border-radius: 0 0 5px 5px;
  width: 300px;

  div {
    padding: 0.3rem 0.5rem;

    li {
      margin-bottom: 0;
      color: ${colors.black};
    }

    p {
      margin-left: 0;
    }
  }

  @media only screen and (max-width: ${responsiveness.ipadMin}) {
    width: 60vw;
  }
`;

const BaseContainerHeader = styled.header`
  padding: 0.3rem 0 0 0.5rem;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.gray200};
  margin-bottom: 0.3rem;

  p {
    padding: 0;
    margin: 0;
    font-size: ${fontSize.nav};
  }
`;
