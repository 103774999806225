import { Field, Form } from "react-final-form";
import { Reset, UserReset } from "../../redux/UserSlice";
import { Text, Title } from "../../components/typography";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "../../components/button";
import { Input } from "../../components/forms";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const ForgotPasswordForm = () => {
  let { loading, errMessage, isSuccess, data } = useSelector(
    (state) => state.user
  );
  let navigate = useNavigate();
  let dispatch = useDispatch();

  const onSubmitValues = (values) => {
    dispatch(UserReset(values));

    if (!loading && isSuccess && data === "success") {
      navigate(`/password-reset/${values.email}/${data}`);
    }
  };

  const onReturnToLogin = () => {
    navigate("/");
    dispatch(Reset());
  };

  return (
    <>
      <Form
        onSubmit={onSubmitValues}
        validate={(values) => {
          const errors = {};

          if (!values.email || values.email.trim().length === 0) {
            errors.email = "Email address required";
          } else if (
            values.email &&
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }
          return errors;
        }}
      >
        {({ handleSubmit, pristine, submitting, invalid, form }) => (
          <form onSubmit={handleSubmit}>
            <Title number={5}>Reset your password</Title>
            {errMessage ? (
              <Text color="red">{errMessage}</Text>
            ) : (
              <Text scale="small">
                Enter your email, and we’ll send you instructions on how to
                reset your password.
              </Text>
            )}
            <Field label="Email" name="email" type="email" component={Input} />
            <br />
            <InfoFlex>
              <Text
                scale="small"
                color="gray"
                onClick={() => {
                  onReturnToLogin();
                  form.reset();
                }}
                style={{ cursor: "pointer" }}
              >
                Return
              </Text>
              <Button
                type="submit"
                varient="purple"
                disabled={submitting || pristine || invalid}
                text="Login"
              >
                {submitting ? "Submitting....." : "Request Password Reset"}
              </Button>
            </InfoFlex>
          </form>
        )}
      </Form>
    </>
  );
};

export default ForgotPasswordForm;

const InfoFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  p {
    margin: 0;
    width: fit-content;
  }
`;
