import { CardItem, Cards } from "../../components/cards";

import { AddressEditRoute } from "../../routes/Paths";
import { Text } from "../../components/typography";

const LocationElement = (props) => {
  const { locations, loading } = props;

  return (
    <Cards version="2" title="Office Locations">
      {loading ? (
        <Text align="center">Loading</Text>
      ) : (
        locations?.map((location) => {
          return (
            <CardItem
              version="4"
              text={`${location?.street} ${location?.city}, ${location?.state} ${location?.zipcode}`}
              path={AddressEditRoute(location.id)}
              state={{ location: location }}
            />
          );
        })
      )}
    </Cards>
  );
};

export default LocationElement;
