import {
  ClientCreatePage,
  ClientDetailPage,
  ClientEditPage,
  ClientListPage,
  ClientPage,
} from "../pages";

import { Route } from "react-router-dom";

export const ClientRoutes = () => {
  return (
    <Route path="/auth/clients" element={<ClientPage />}>
      <Route index element={<ClientListPage />} />
      <Route path="create" element={<ClientCreatePage />} />
      <Route path=":id" element={<ClientDetailPage />} />
      <Route path="edit/:id" element={<ClientEditPage />} />
    </Route>
  );
};
