import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  ForgotPasswordPage,
  HomePage,
  LogoutPage,
  Page404,
  PasswordResetPage,
  SignUpPage,
} from "../pages";

import { AnimatePresence } from "framer-motion";
import { BlogRoutes } from "./BlogRoutes";
import { BookingRoutes } from "./BookingRoutes";
import { ClientRoutes } from "./ClientRoutes";
import { CompanyRoutes } from "./CompanyRoutes";
import { ContactMessagesRoutes } from "./ContactMessagesRoutes";
import { FAQRoutes } from "./FAQRoutes";
import { GalleryRoutes } from "./GalleryRoutes";
import { PartnersRoutes } from "./PartnersRoutes";
import { ProfileRoute } from "./ProfileRoutes";
import ProtectedResetRoutes from "./ProtectedResets";
import ProtectedRoutes from "./ProtectedRoutes";
import ScrollToTop from "../utils/ScrollToTop";
import { ServicesRoutes } from "./ServicesRoutes";
import { SettingsRoutes } from "./SettingsRoutes";
import { StaffRoutes } from "./StaffRoutes";
import { TestimonyRoutes } from "./TestimonyRoutes";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <AnimatePresence mode="wait">
        <Routes>
          <Route path="/" element={<ProtectedRoutes />}>
            <Route index element={<HomePage />} />
            {BlogRoutes()}
            {BookingRoutes()}
            {ClientRoutes()}
            {CompanyRoutes()}
            {ContactMessagesRoutes()}
            {FAQRoutes()}
            {GalleryRoutes()}
            {PartnersRoutes()}
            {ProfileRoute()}
            {ServicesRoutes()}
            {SettingsRoutes()}
            {StaffRoutes()}
            {TestimonyRoutes()}
            <Route path="/auth" element={<ProtectedResetRoutes />}>
              <Route path="forgot-password" element={<ForgotPasswordPage />} />
              <Route
                path="password-reset/:email/:data"
                element={<PasswordResetPage />}
              />
              <Route path="signup" element={<SignUpPage />} />
            </Route>
          </Route>
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </AnimatePresence>
    </BrowserRouter>
  );
};

export default AppRoutes;
