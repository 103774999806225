import { colors, responsiveness, shadow } from "../../assets/styles/parameters";

import { Field } from "react-final-form";
import { Label } from "./Inputs";
import styled from "styled-components";

export const MultiImageUploadFileField = ({
  name,
  SetImages,
  Images,
  OnRemoveImage,
  ...props
}) => {
  return (
    <Field name={name}>
      {({ input: { value, onChange, ...input } }) => (
        <BaseFileField>
          <div>
            <Label htmlFor={name}>Upload Image</Label>
            <input
              id={name}
              name={name}
              {...input}
              type="file"
              multiple
              accept="image/*"
              hidden
              onChange={({ target }) => {
                SetImages(Images.concat(Object.values(target.files)));
                onChange(Images);
              }}
              {...props}
            />
          </div>

          <ImageList>
            {Images.map((s, index) => {
              return (
                <ImagesListContainer key={index}>
                  <span onClick={() => OnRemoveImage(s)}>X</span>
                  <img
                    src={URL.createObjectURL(s)}
                    alt={s.fileName}
                    img-src={URL.createObjectURL(s)}
                  />
                </ImagesListContainer>
              );
            })}
          </ImageList>
        </BaseFileField>
      )}
    </Field>
  );
};

const ImageList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: ${responsiveness.mobile1}) {
    justify-content: center;
  }
`;

const ImagesListContainer = styled.ol`
  position: relative;
  margin: 1rem;

  img {
    width: 200px;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
  }

  span {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -5%;
    top: -5%;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    color: black;
    background-color: red;
    cursor: pointer;
    transform: translate(5%, 5%);
    padding: 0.1rem 0 0 0.08rem;
  }
`;

export const ImageUploadFileField = ({
  name,
  SetImages,
  Images,
  OnRemoveImage,
  ...props
}) => {
  return (
    <Field name={name}>
      {({ input: { value, onChange, ...input } }) => (
        <BaseFileField>
          {Images && Images.length === 0 && (
            <div>
              <Label htmlFor={name}>Upload Image</Label>
              <input
                id={name}
                name={name}
                {...input}
                type="file"
                accept="image/*"
                hidden
                onChange={({ target }) => {
                  SetImages(Object.values(target.files));
                  onChange(Images);
                }}
                {...props}
              />
            </div>
          )}
          {Images && Images.length > 0 && (
            <ul>
              {Images.map((s, index) => {
                return (
                  <ImagesListContainer key={index}>
                    <span onClick={() => OnRemoveImage(s)}>X</span>
                    <img src={URL.createObjectURL(s)} alt={s.fileName} />
                  </ImagesListContainer>
                );
              })}
            </ul>
          )}
        </BaseFileField>
      )}
    </Field>
  );
};

const BaseFileField = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    text-align: center;
  }

  & > div:first-child {
    padding: 0.5rem 1rem;
    border-radius: 10rem;
    border: 1px solid ${colors.blueDark2};
    box-shadow: ${shadow.light};
    margin: 2rem auto;

    label > p {
      margin: 0;
      padding: 0;
    }

    &:hover {
      background: ${colors.blue};
    }
  }
`;
