import { Layout, PageHeader } from "../../components/layout";

import { Outlet } from "react-router-dom";

const StaffPages = () => {
  return (
    <>
      <PageHeader title="Staff" />
      <Layout>
        <Outlet />
      </Layout>
    </>
  );
};

export default StaffPages;
