import {
  BlogBaseRoute,
  BookingBaseRoute,
  ClientsBaseRoute,
  ContactBaseRoute,
  FAQBaseRoute,
  MediaBaseRoute,
  OrgBaseRoute,
  PartnerBaseRoute,
  ServiceBaseRoute,
  StaffBaseRoute,
  TestimoniesBaseRoute,
} from "../../../routes/Paths";
import {
  IoBookOutline,
  IoBusinessOutline,
  IoGitNetworkOutline,
  IoHomeOutline,
  IoNewspaperOutline,
  IoPeopleOutline,
  IoPersonOutline,
} from "react-icons/io5";

export const MainPaths = [
  {
    name: "Dashboard",
    path: "/",
    icon: <IoHomeOutline />,
  },
  {
    name: "Request for Estimates",
    path: BookingBaseRoute,
    icon: <IoBusinessOutline />,
  },
  {
    name: "Contacts",
    path: ClientsBaseRoute,
    icon: <IoPeopleOutline />,
  },
  {
    name: "Blog",
    path: BlogBaseRoute,
    icon: <IoNewspaperOutline />,
  },
  {
    name: "Testimonials",
    path: TestimoniesBaseRoute,
    icon: <IoBookOutline />,
  },
  {
    name: "Messages",
    path: ContactBaseRoute,
    icon: <IoPeopleOutline />,
  },
];

export const SecondaryPaths = [
  {
    name: "Company",
    path: OrgBaseRoute,
    icon: <IoHomeOutline />,
  },
  {
    name: "Services",
    path: ServiceBaseRoute,
    icon: <IoPeopleOutline />,
  },
  {
    name: "FAQ",
    path: FAQBaseRoute,
    icon: <IoPersonOutline />,
  },
  {
    name: "Staff",
    path: StaffBaseRoute,
    icon: <IoPersonOutline />,
  },
  {
    name: "Gallery",
    path: MediaBaseRoute,
    icon: <IoPersonOutline />,
  },
  {
    name: "Partners",
    path: PartnerBaseRoute,
    icon: <IoGitNetworkOutline />,
  },
];
