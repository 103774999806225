import { fontSize } from "../../assets/styles/parameters";
import styled from "styled-components";

export const Title = ({ number, children, ...otherProps }) => {
  switch (number) {
    case 1:
      return <First {...otherProps}>{children}</First>;
    case 2:
      return <Second {...otherProps}>{children}</Second>;
    case 3:
      return <Third {...otherProps}>{children}</Third>;
    case 4:
      return <Fourth {...otherProps}>{children}</Fourth>;
    case 5:
      return <Fifth {...otherProps}>{children}</Fifth>;
    case 6:
      return <Sixth {...otherProps}>{children}</Sixth>;
    default:
      return <Third {...otherProps}>{children}</Third>;
  }
};

const First = styled.h1`
  font-size: ${fontSize.h1};
`;

const Second = styled.h2`
  font-size: ${fontSize.h2};
`;

const Third = styled.h3`
  font-size: ${fontSize.h3};
`;

const Fourth = styled.h4`
  font-size: ${fontSize.h4};
`;

const Fifth = styled.h5`
  font-size: ${fontSize.h5};
`;

const Sixth = styled.h6`
  font-size: ${fontSize.h6};
`;
