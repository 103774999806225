import { CreateStaff } from "../../redux/StaffSlice";
import { Section } from "../../components/layout";
import StaffForm from "./StaffForm";
import { useDispatch } from "react-redux";

const StaffCreatePage = () => {
  const dispatch = useDispatch();

  const onFormSubmit = (values) => {
    dispatch(CreateStaff(values));
  };

  return (
    <Section>
      <StaffForm onFormSubmit={onFormSubmit} />
    </Section>
  );
};

export default StaffCreatePage;
