import NotificationItem from "./NotificationItem";
import { Text } from "../typography";
import { colors } from "../../assets/styles/parameters";
import styled from "styled-components";

const NotificationLists = ({ data, ...otherProps }) => {
  return (
    <BaseNotificationList {...otherProps}>
      {data ? (
        data.map((s, index) => {
          return (
            <NotificationItem
              key={index}
              message={s.message}
              date={s.date}
              status={s.status}
            />
          );
        })
      ) : (
        <BaseEmpty>
          <Text>No notification</Text>
        </BaseEmpty>
      )}
    </BaseNotificationList>
  );
};

export default NotificationLists;

const BaseNotificationList = styled.div`
  background-color: ${colors.gray200};
`;

const BaseEmpty = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 40vh;
`;
