// Company
export const OrgBaseRoute = "/auth/org";
export const OrgCreateRoute = `${OrgBaseRoute}/create`;
export const OrgEditRoute = `${OrgBaseRoute}/edit`;

// Address
export const AddressBaseRoute = "/auth/org/locations";
export const AddressCreateRoute = `${AddressBaseRoute}/create`;
export const AddressEditRoute = (id) => `${AddressBaseRoute}/edit/${id}`;

// Cred
export const CredBaseRoute = "/auth/org/cert-recognition";
export const CredCreateRoute = `${CredBaseRoute}/create`;
export const CredEditRoute = (id) => `${CredBaseRoute}/edit/${id}`;

// Partner/ Industry
export const PartnerBaseRoute = "/auth/industry-partners";
export const PartnerBaseRouteId = (id) => `${PartnerBaseRoute}/edit/${id}`;
export const PartnerCreateRoute = `${PartnerBaseRoute}/create`;

// Staff
export const StaffBaseRoute = "/auth/staff";
export const StaffBaseRouteId = (id) => `${StaffBaseRoute}/edit/${id}`;
export const StaffCreateRoute = `${StaffBaseRoute}/create`;

// Services
export const ServiceBaseRoute = "/auth/services";
export const ServiceEditRoute = (id) => `${ServiceBaseRoute}/edit/${id}`;
export const ServiceCreateRoute = `${ServiceBaseRoute}/create`;

// Clients
export const ClientsBaseRoute = "/auth/clients";
export const ClientEditRoute = (id) => `${ClientsBaseRoute}/edit/${id}`;
export const ClientCreateRoute = `${ClientsBaseRoute}/create`;
export const ClientDetailRoute = (id) => `${ClientsBaseRoute}/${id}`;

// Testimonies
export const TestimoniesBaseRoute = "/auth/testimonies";
export const TestimonyCreateRoute = `${TestimoniesBaseRoute}/create`;

// Contact
export const ContactBaseRoute = "/auth/messages";

// Booking
export const BookingBaseRoute = "/auth/bookings";
export const BookingBaseRouteId = (id) => `${BookingBaseRoute}/${id}`;
export const BookingEditRoute = (id) => `${BookingBaseRoute}/edit/${id}`;
export const BookingCreateRoute = `${BookingBaseRoute}/create`;

// Blog
export const BlogBaseRoute = "/auth/blog";
export const BlogBaseRouteId = (id) => `${BlogBaseRoute}/${id}`;
export const BlogEditRoute = (id) => `${BlogBaseRoute}/edit/${id}`;
export const BlogCreateRoute = `${BlogBaseRoute}/create`;

// Profile
export const BaseProfileRoute = "/auth/profile";
export const ProfileRouteId = (id) => `${BaseProfileRoute}/${id}`;
export const ProfileEditRoute = (name) => `${BaseProfileRoute}/edit/${name}`;
export const ProfileCreateRoute = `${BaseProfileRoute}/create`;

export const MediaBaseRoute = "/auth/gallery";
export const MediaBaseRouteId = (id) => `${MediaBaseRoute}/${id}`;
export const MediaEditRoute = (id) => `${MediaBaseRoute}/edit/${id}`;
export const MediaCreateRoute = `${MediaBaseRoute}/create`;

// FAQ
export const FAQBaseRoute = "/auth/faq";
export const FAQEditRoute = (id) => `${FAQBaseRoute}/edit/${id}`;
export const FAQCreateRoute = `${FAQBaseRoute}/create`;

// Settings
export const SettingsBaseRoute = "/auth/user";
