import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { MediaBaseRouteDelete } from "./MediaSlice";
import { eces } from "../api";
import { onAlert } from "../utils/onAlerts";

const BASEPATH = "companyapi/api/v1/services";
const INITIALSTATE = {
  isSuccess: false,
  errMessage: "",
  loading: true,
  data: [],
};

export const FetchServices = createAsyncThunk(
  "services/fetches",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.get(BASEPATH);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const CreateService = createAsyncThunk(
  "service/create",
  async (args, { rejectWithValue }) => {
    const { files, title, description, subDescription, featured } = args;

    const formData = new FormData();
    formData.append("title", title || "");
    formData.append("description", description || "");
    formData.append("subDescription", subDescription || "");
    formData.append("featured", featured);
    formData.append("mediaType", "IMAGE");
    if (files) {
      for (const file of files) {
        formData.append("files", file.file);
      }
    }

    try {
      const response = await eces.post(`${BASEPATH}`, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const FetchService = createAsyncThunk(
  "service/fetch",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.get(`${BASEPATH}/${args}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const EditService = createAsyncThunk(
  "service/update",
  async (args, { rejectWithValue }) => {
    const { files, title, description, subDescription, featured } = args.values;

    const formData = new FormData();
    formData.append("title", title || "");
    formData.append("description", description || "");
    formData.append("subDescription", subDescription || "");
    formData.append("featured", featured);
    formData.append("mediaType", "IMAGE");
    if (files) {
      for (const file of files) {
        formData.append("files", file.file);
      }
    }

    try {
      const response = await eces.patch(`${BASEPATH}/${args.id}`, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const DeleteService = createAsyncThunk(
  "service/delete",
  async (args, { rejectWithValue, getState }) => {
    const services = getState().services?.data;
    const data = services.filter((item) => item !== args);

    try {
      await eces.delete(`${BASEPATH}/${args.id}`);
      return data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const DeleteServicePictures = createAsyncThunk(
  "service/delete/pictures",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.delete(
        MediaBaseRouteDelete(args.name, args.fileName)
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const serviceSlice = createSlice({
  name: "service",
  initialState: INITIALSTATE,
  extraReducers: {
    [FetchServices.pending]: (state) => {
      state.loading = true;
    },
    [FetchServices.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.isSuccess = true;
    },
    [FetchServices.rejected]: (state, action) => {
      state.loading = false;
      state.isSuccess = false;
      state.errMessage = action.payload;
    },
    [FetchService.pending]: (state) => {
      state.loading = true;
    },
    [FetchService.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.isSuccess = true;
    },
    [FetchService.rejected]: (state, action) => {
      state.loading = false;
      state.isSuccess = false;
      state.errMessage = action.payload;
    },
    [CreateService.fulfilled]: (state, action) => {
      state.loading = false;
      state.isSuccess = true;
      onAlert("success", "Service Created");
    },
    [EditService.fulfilled]: (state, action) => {
      state.loading = false;
      state.isSuccess = true;
      onAlert("success", "Service Edited");
    },
    [DeleteService.fulfilled]: (state) => {
      state.loading = false;
      state.isSuccess = true;
      onAlert("success", "Service Deleted");
    },
    [DeleteServicePictures.fulfilled]: (state, action) => {
      state.loading = false;
      state.isSuccess = true;
      onAlert("success", "Image Deleted");
    },
  },
});

export default serviceSlice.reducer;
