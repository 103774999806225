import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { MediaBaseRouteDelete } from "./MediaSlice";
import { eces } from "../api";
import { onAlert } from "../utils/onAlerts";

const BASEPATH = "companyapi/api/v1/credentials";
const INITIALSTATE = {
  loading: true,
  creds: [],
  cred: {},
  error: "",
};

export const FetchCreds = createAsyncThunk(
  "cred/fetches",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.get(BASEPATH);
      return {
        data: response.data,
      };
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const CreateCred = createAsyncThunk(
  "cred/create",
  async (args, { rejectWithValue }) => {
    const { title, files } = args;
    const formData = new FormData();

    if (files) {
      for (const file of files) {
        formData.append("files", file.file);
      }
    }
    formData.append("title", title);
    formData.append("mediaType", "IMAGE");

    try {
      const response = await eces.post(BASEPATH, formData);
      return {
        data: response.data,
      };
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const FetchCred = createAsyncThunk(
  "cred/fetch",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.get(`${BASEPATH}/${args}`, args.value);
      return {
        data: response.data,
      };
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const EditCred = createAsyncThunk(
  "cred/update",
  async (args, { rejectWithValue }) => {
    const { title, files } = args.values;

    const formData = new FormData();
    if (files) {
      for (const file of files) {
        formData.append("files", file.file);
      }
    }
    formData.append("title", title);
    formData.append("mediaType", "IMAGE");

    try {
      const response = await eces.patch(`${BASEPATH}/${args.id}`, formData);
      return {
        data: response.data,
      };
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const DeleteCred = createAsyncThunk(
  "cred/delete",
  async (args, { rejectWithValue, getState }) => {
    const creds = getState().cred?.creds;
    const data = creds.filter((item) => item !== args);
    try {
      await eces.delete(`${BASEPATH}/${args.id}`);
      return {
        data: data,
      };
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const DeleteCredPic = createAsyncThunk(
  "client/delete/pictures",
  async (args, { rejectWithValue }) => {
    console.log(args);
    try {
      const response = await eces.delete(
        MediaBaseRouteDelete(args.name, args.fileName)
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const credReducer = createSlice({
  name: "credibility",
  initialState: INITIALSTATE,
  extraReducers: (builder) => {
    builder
      .addCase(FetchCreds.pending, (state) => {
        state.loading = true;
      })
      .addCase(FetchCreds.fulfilled, (state, action) => {
        state.loading = false;
        state.creds = action.payload.data;
      })
      .addCase(FetchCreds.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
        onAlert("error", action.payload.error);
      })
      .addCase(FetchCred.pending, (state) => {
        state.loading = true;
      })
      .addCase(FetchCred.fulfilled, (state, action) => {
        state.loading = false;
        state.cred = action.payload.data;
        onAlert("success", "Recognition & Credential Created");
      })
      .addCase(FetchCred.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
        onAlert("error", action.payload.error);
      })
      .addCase(CreateCred.pending, (state) => {
        state.loading = true;
      })
      .addCase(CreateCred.fulfilled, (state, action) => {
        state.loading = false;
        state.cred = action.payload.data;
        onAlert("success", "Recognition & Credential Created");
      })
      .addCase(CreateCred.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
        onAlert("error", action.payload.error);
      })
      .addCase(EditCred.pending, (state) => {
        state.loading = true;
      })
      .addCase(EditCred.fulfilled, (state, action) => {
        state.loading = false;
        state.cred = action.payload.data;
        onAlert("success", "Recognition & Credential Edited");
      })
      .addCase(EditCred.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
        onAlert("error", action.payload.error);
      })
      .addCase(DeleteCred.pending, (state) => {
        state.loading = true;
      })
      .addCase(DeleteCred.fulfilled, (state, action) => {
        state.loading = false;
        state.creds = action.payload.data;
        onAlert("success", "Recognition & Credential Deleted");
      })
      .addCase(DeleteCred.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
        onAlert("error", action?.payload?.error);
      })
      .addCase(DeleteCredPic.pending, (state) => {
        state.loading = true;
      })
      .addCase(DeleteCredPic.fulfilled, (state, action) => {
        state.loading = false;
        state.creds = action.payload.data;
        onAlert("success", "Recognition & Credential Deleted");
      })
      .addCase(DeleteCredPic.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
        onAlert("error", action?.payload?.error);
      });
  },
});

export default credReducer.reducer;
