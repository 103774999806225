import { CardItem, Cards } from "../../components/cards";
import { Grid, Section } from "../../components/layout";

import { AppLinks } from "../../components/links";
import ClientBookingElement from "./ClientBookingElement";
import { ClientEditRoute } from "../../routes/Paths";
import { Profile } from "./ProfileDetailCard";
import { Text } from "../../components/typography";
import { useLocation } from "react-router-dom";

const ClientDetailPage = () => {
  let { state } = useLocation();
  const { client } = state;

  const rendAddress = () => {
    return (
      <div>
        {client?.addresses.map((s) => {
          return (
            <div key={s.street}>
              <Text>
                {s.street} {s.city}, {s.state} {s.zipcode}
              </Text>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Section>
      <Grid col="l14">
        <div>
          <Cards
            version="2"
            title="Client Contact Information"
            action={
              <AppLinks
                to={ClientEditRoute(client.id)}
                state={{ client: client }}
              >
                Edit
              </AppLinks>
            }
          >
            <CardItem>
              <Profile
                name={`${client.firstName} ${
                  client.middleName === "undefined" ||
                  (client.middleName === undefined ? "" : client.middleName)
                } ${client.lastName}`}
                email={client.email}
                phone={client.phone}
                address={rendAddress()}
              />
            </CardItem>
          </Cards>
          <Cards version="3" title="Notes">
            <CardItem>
              <Text>{client.notes}</Text>
            </CardItem>
          </Cards>
        </div>
        <ClientBookingElement client={client} />
      </Grid>
    </Section>
  );
};

export default ClientDetailPage;
