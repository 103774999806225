import {
  DeleteService,
  DeleteServicePictures,
  EditService,
} from "../../redux/ServiceSlice";

import { IoTrash } from "react-icons/io5";
import { Section } from "../../components/layout";
import ServiceForm from "./ServiceForm";
import { Text } from "../../components/typography";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const ServiceEditPage = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();

  const onFormSubmit = (values) => {
    const args = { id: state.service.id, values: values };
    if (window.confirm(`Are you sure you want to add this service`)) {
      dispatch(EditService(args));
    }
  };

  const onDeleteSubmit = () => {
    if (window.confirm(`Are you sure you want to save these edits? `)) {
      dispatch(DeleteService(state.service));
    }
  };

  const onHandleImageDelete = (fileName) => {
    const arg = {
      name: state.service?.media?.name,
      fileName: fileName,
    };
    dispatch(DeleteServicePictures(arg));
  };

  return (
    <Section>
      <SubNav>
        <li onClick={onDeleteSubmit}>
          <IoTrash /> <Text>Delete</Text>
        </li>
      </SubNav>
      <ServiceForm
        initialValues={state.service}
        onFormSubmit={onFormSubmit}
        onHandleImageDelete={onHandleImageDelete}
      />
    </Section>
  );
};

export default ServiceEditPage;

const SubNav = styled.nav`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 2rem;

  li {
    margin-left: 1.5rem;
    display: flex;
    align-items: center;
    color: red;

    p {
      margin: 0;
      padding: 0 0 0 0.5rem;
      color: red;
    }

    &:hover {
      cursor: pointer;
    }
  }
`;
