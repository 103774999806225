import { Layout, PageHeader } from "../../components/layout";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "../../components/button";
import ContactForm from "./ContactForm";
import { CreateMessage } from "../../redux/ContactSlice";
import { Outlet } from "react-router-dom";
import { PopupContainer } from "../../components/popup";
import styled from "styled-components";
import { useState } from "react";

const ContactPage = () => {
  const [openForm, setopenForm] = useState(false);
  const { error, loading } = useSelector((state) => state.contacts);

  const dispatch = useDispatch();

  const onClose = () => {
    setopenForm(false);
  };

  const onOpenContactForm = () => setopenForm(true);

  const onFormSubmit = (values) => {
    dispatch(CreateMessage(values));
    onClose();
    return;
  };

  return (
    <>
      <PageHeader title="Contacts" />
      <Layout>
        <SubNav>
          <Button onClick={onOpenContactForm}>Create Message</Button>
        </SubNav>
        <PopupContainer
          title="Contact Form"
          onOpenPopUp={openForm}
          onClosePopUp={onClose}
        >
          <ContactForm
            onFormSubmit={onFormSubmit}
            onClose={onClose}
            loading={loading}
            error={error}
          />
        </PopupContainer>
        <Outlet />
      </Layout>
    </>
  );
};

export default ContactPage;

const SubNav = styled.nav`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 2rem;

  li {
    margin-left: 1.5rem;
  }
`;
