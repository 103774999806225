import { Helmet } from "react-helmet";

export const Meta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title} | Extreme Clean Environmental Services, LLC</title>
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keyword" content={keywords} />}
      {title && (
        <meta
          property="og:title"
          content={`${title} | Extreme Clean Environmental Services, LLC`}
        />
      )}
      {description && <meta property="og:description" content={description} />}
      {title && (
        <meta
          name="twitter:title"
          content={`${title} | Extreme Clean Environmental Services, LLC`}
        />
      )}
      {description && <meta name="twitter:description" content={description} />}
      {process.env.NODE_ENV === "production" && (
        <meta
          http-equiv="Content-Security-Policy"
          content="
            worker-src blob:; 
            child-src blob: gap:;
            img-src 'self' blob: data:;
            default-src * 'self' 'unsafe-inline' 'unsafe-eval' data: gap: content:"
        />
      )}
      <meta name="robots" content="noindex,follow" />
    </Helmet>
  );
};

Meta.defaultProps = {
  title: "Extreme Clean Environmental Services, LLC",
};
