import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { MediaBaseRouteDelete } from "./MediaSlice";
import { eces } from "../api";
import { onAlert } from "../utils/onAlerts";

const BASEPATH = "companyapi/api/v1/companies";
const INITIALSTATE = {
  isSuccess: false,
  error: "",
  loading: true,
  data: [],
};

export const FetchComp = createAsyncThunk(
  "company/fetches",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.get(BASEPATH);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const CreateCompany = createAsyncThunk(
  "company/create",
  async (args, { rejectWithValue, getState }) => {
    const { files, name, about, mission, vision, locations } = args;

    const newData = {
      name: name || "",
      about: about || "",
      mission: mission || "",
      vision: vision || "",
      locations,
      mediaType: "IMAGE",
    };

    const formData = new FormData();

    const blob = new Blob([JSON.stringify(newData)], {
      type: "application/json",
    });

    if (files) {
      for (const file of files) {
        formData.append("files", file.file);
      }
    }

    formData.append("data", blob);

    try {
      const response = await eces.post(`${BASEPATH}`, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const FetchCompany = createAsyncThunk(
  "company/fetch",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.get(`${BASEPATH}/${args}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const EditCompany = createAsyncThunk(
  "company/update",
  async (args, { rejectWithValue, getState }) => {
    const { files, name, about, mission, vision, locations } = args.values;

    const newData = {
      name: name || "",
      about: about || "",
      mission: mission || "",
      vision: vision || "",
      locations,
      mediaType: "IMAGE",
    };

    const formData = new FormData();

    const blob = new Blob([JSON.stringify(newData)], {
      type: "application/json",
    });

    if (files) {
      for (const file of files) {
        formData.append("files", file.file);
      }
    }

    formData.append("data", blob);

    try {
      const response = await eces.patch(`${BASEPATH}/${args.id}`, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const DeleteCompany = createAsyncThunk(
  "company/delete",
  async (args, { rejectWithValue, getState }) => {
    try {
      const response = await eces.delete(`${BASEPATH}/${args}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const DeleteCompPictures = createAsyncThunk(
  "company/delete/pictures",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.delete(
        MediaBaseRouteDelete(args.name, args.fileName)
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const companySlice = createSlice({
  name: "company",
  initialState: INITIALSTATE,
  extraReducers: {
    [FetchComp.pending]: (state) => {
      state.loading = true;
    },
    [FetchComp.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.isSuccess = true;
    },
    [FetchComp.rejected]: (state, action) => {
      state.loading = false;
      state.isSuccess = false;
      state.error = action.payload;
    },

    [FetchCompany.pending]: (state) => {
      state.loading = true;
    },
    [FetchCompany.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.isSuccess = true;
    },
    [FetchCompany.rejected]: (state, action) => {
      state.loading = false;
      state.isSuccess = false;
      state.error = action.payload;
    },
    [CreateCompany.fulfilled]: (state, action) => {
      state.loading = false;
      state.isSuccess = true;
      state.error = action.payload;
      onAlert("success", "Company Created");
    },
    [EditCompany.pending]: (state) => {
      state.loading = true;
    },
    [EditCompany.fulfilled]: (state, action) => {
      state.loading = false;
      state.isSuccess = true;
      state.data = action.payload;
      onAlert("success", "Company Updated");
    },
    [EditCompany.rejected]: (state, action) => {
      state.loading = false;
      state.isSuccess = false;
      state.error = action.payload;
    },
    [DeleteCompany.fulfilled]: (state) => {
      state.loading = false;
      state.isSuccess = true;
      onAlert("success", "Company Deleted");
    },
    [DeleteCompPictures.fulfilled]: (state) => {
      state.loading = false;
      state.isSuccess = true;
      onAlert("success", "Image Deleted");
    },
  },
});

export default companySlice.reducer;
