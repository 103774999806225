import {
  colors,
  fontSize,
  responsiveness,
} from "../../assets/styles/parameters";
import styled, { css } from "styled-components";

export const Text = ({
  children,
  scale,
  width,
  color,
  align,
  ...otherProps
}) => {
  return (
    <StyledText
      width={width}
      color={color}
      scale={scale}
      align={align}
      {...otherProps}
    >
      {children}
    </StyledText>
  );
};

const BaseText = styled.p`
  margin-bottom: 1rem;
  width: ${({ width }) => `${width}%`};

  @media screen and (max-width: ${responsiveness.mobile1}) {
    width: 100%;
  }
`;

const StyledText = styled(BaseText)`
  ${({ align }) => {
    switch (align) {
      case "center":
        return css`
          text-align: center;
        `;
      case "right":
        return css`
          text-align: right;
        `;
      default:
        return css`
          text-align: inherit;
        `;
    }
  }};

  ${({ scale }) => {
    switch (scale) {
      case "small":
        return css`
          font-size: ${fontSize.small};
        `;
      case "medium":
        return css`
          font-size: ${fontSize.h4};
        `;
      case "large":
        return css`
          font-size: ${fontSize.h3};
        `;
      default:
        return css`
          font-size: inherit;
        `;
    }
  }}

  ${({ color }) => {
    switch (color) {
      case "white":
        return css`
          color: ${colors.white};
        `;
      case "gray":
        return css`
          color: ${colors.gray600};
        `;
      case "red":
        return css`
          color: red;
        `;
      default:
        return css`
          color: ${colors.black};
        `;
    }
  }}
`;
