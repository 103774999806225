import { IoMenuOutline } from "react-icons/io5";
import { OpenSideNav } from "../../../redux/NavigationSlice";
import { responsiveness } from "../../../assets/styles/parameters";
import styled from "styled-components";
import { useDispatch } from "react-redux";

export const NavigationHeader = () => {
  const dispatch = useDispatch();

  return (
    <BaseWrapper>
      <IoMenuOutline onClick={() => dispatch(OpenSideNav())} />
    </BaseWrapper>
  );
};

export default NavigationHeader;

const BaseWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  svg {
    cursor: pointer;
    font-size: 1.5rem;
  }

  p {
    margin: 0;
  }

  @media only screen and (max-width: ${responsiveness.ipadMin}) {
    svg {
      font-size: 2rem;
    }
  }
`;
