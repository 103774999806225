import { colors } from "../../assets/styles/parameters";
import styled from "styled-components";

const ImageFeature = ({ img, description }) => {
  return (
    <BaseImageFeature>
      <img src={img} alt={description} />
      <figcaption>{description}</figcaption>
    </BaseImageFeature>
  );
};

export default ImageFeature;

const BaseImageFeature = styled.figure`
  object-fit: cover;

  img {
    height: 500px;
    object-fit: cover;
    margin: 1rem 0 0.5rem;
  }

  figcaption {
    color: ${colors.gray600};
    margin-bottom: 3rem;
  }
`;
