import { colors, responsiveness } from "../../../assets/styles/parameters";

import Navigation from "../navigation/Navigation";
import styled from "styled-components";

const PageHeader = ({ title, ...otherProps }) => {
  return (
    <BaseHeader {...otherProps}>
      <Navigation />
    </BaseHeader>
  );
};

export default PageHeader;

const BaseHeader = styled.header`
  grid-area: header;
  background-color: ${colors.blueDark2};
  padding: 0.5rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (max-width: ${responsiveness.ipadMin}) {
    padding: 0.5rem 1rem;
  }
`;
