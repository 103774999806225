import {
  CompanyCreatePage,
  CompanyEditPage,
  CompanyPage,
  CompanyProfile,
} from "../pages";
import { CredCreatePage, CredEditPage, CredListPage } from "../pages";

import { Route } from "react-router-dom";

export const CompanyRoutes = () => {
  return (
    <Route path="/auth/org" element={<CompanyPage />}>
      <Route index element={<CompanyProfile />} />
      <Route path="create" element={<CompanyCreatePage />} />
      <Route path="edit" element={<CompanyEditPage />} />
      <Route path="cert-recognition" element={<CredListPage />} />
      <Route path="cert-recognition/create" element={<CredCreatePage />} />
      <Route path="cert-recognition/edit/:id" element={<CredEditPage />} />
    </Route>
  );
};
