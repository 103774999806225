import {
  DeleteClient,
  DeleteClientPictures,
  EditClient,
} from "../../redux/ClientSlice";
import { useLocation, useNavigate } from "react-router-dom";

import ClientForm from "./ClientForm";
import { ClientsBaseRoute } from "../../routes/Paths";
import { IoTrash } from "react-icons/io5";
import { Section } from "../../components/layout";
import { Text } from "../../components/typography";
import styled from "styled-components";
import { useDispatch } from "react-redux";

const ContactEditPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onFormSubmit = (values) => {
    if (window.confirm(`Are you sure you want to save these edits?`)) {
      const args = {
        id: state.client.id,
        values: values,
      };
      dispatch(EditClient(args));
    }
  };

  const onDeleteSubmit = () => {
    if (window.confirm(`Are you sure you want to save these edits? `)) {
      dispatch(DeleteClient(state.client.id));
    }
    navigate(ClientsBaseRoute);
  };

  const onHandleImageDelete = (fileName) => {
    const arg = {
      name: state.client?.media?.name,
      fileName: fileName,
    };
    dispatch(DeleteClientPictures(arg));
  };

  return (
    <Section>
      <SubNav>
        <li onClick={onDeleteSubmit}>
          <IoTrash /> <Text>Delete</Text>
        </li>
      </SubNav>

      <ClientForm
        initialValues={state.client}
        onFormSubmit={onFormSubmit}
        onHandleImageDelete={onHandleImageDelete}
      />
    </Section>
  );
};

export default ContactEditPage;

const SubNav = styled.nav`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 2rem;

  li {
    margin-left: 1.5rem;
    display: flex;
    align-items: center;
    color: red;

    p {
      margin: 0;
      padding: 0 0 0 0.5rem;
      color: red;
    }

    &:hover {
      cursor: pointer;
    }
  }
`;
