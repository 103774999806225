import { AnimatePresence, motion } from "framer-motion";
import { Text, Title } from "../../components/typography";
import { colors, fontSize, shadow } from "../../assets/styles/parameters";
import { useEffect, useState } from "react";

import styled from "styled-components";

export const PopUpRight = ({
  title,
  children,
  onOpenPopUpRight,
  onClosePopUpRight,
}) => {
  const [windowHeight, setWindowHeight] = useState();

  useEffect(() => {
    const height = document.documentElement.scrollHeight;
    setWindowHeight(height);
  }, []);

  const variants = {
    open: {
      display: "block",
      opacity: 1,
      right: 0,
      transition: { duration: 0.5, ease: "easeInOut" },
    },
    closed: {
      display: "none",
      opacity: 0,
      right: -100,
      transition: { duration: 0.5, ease: "easeInOut" },
    },
  };

  const overlayVariants = {
    open: {
      display: "block",
      opacity: 0.1,
      transition: { duration: 0.4, ease: "easeInOut" },
    },
    closed: {
      display: "none",
      opacity: 0,
      transition: { duration: 0.5, ease: "easeInOut" },
    },
  };

  return (
    <AnimatePresence initial={false}>
      {onOpenPopUpRight && (
        <>
          <Overlay
            variants={overlayVariants}
            animate="open"
            initial="closed"
            exit="closed"
            height={windowHeight}
          />
          <PopUp
            // positionTransition
            variants={variants}
            animate="open"
            initial="closed"
            exit="closed"
          >
            <PopUpHeader>
              <Title number={6}>{title}</Title>
              <Text onClick={onClosePopUpRight}>Close</Text>
            </PopUpHeader>
            <PopUpContent>{children}</PopUpContent>
          </PopUp>
        </>
      )}
    </AnimatePresence>
  );
};

export default PopUpRight;

const Overlay = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${colors.gray600};
  height: ${({ height }) => `${height}px`};
  z-index: 1000;
`;

const PopUp = styled(motion.div)`
  position: fixed;
  border-radius: 10px 0 0 10px;
  padding: 2rem 0;
  background-color: ${colors.white};
  top: 5%;
  width: 30%;
  height: 90%;
  box-shadow: ${shadow.dark};
  z-index: 1000;
`;

const PopUpHeader = styled.header`
  display: flex;
  padding: 0 1.5rem 0.5rem;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.gray200};

  h6,
  p {
    padding: 0;
    margin: 0;
  }

  h6 {
    font-weight: 500;
  }

  p {
    font-size: ${fontSize.nav};

    &:hover {
      cursor: pointer;
      color: red;
    }
  }
`;

const PopUpContent = styled.div`
  padding: 0 1.5rem;
  overflow-y: scroll;
  height: 90%;
`;
