import { DeleteFAQ, FetchFAQs } from "../../redux/FAQSlice";
import { FAQCreateRoute, FAQEditRoute } from "../../routes/Paths";
import { Table, TableExpandContainer } from "../../components/table";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "../../components/button";
import { IoPencilOutline } from "react-icons/io5";
import { IoTrashOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { Loader } from "../../components/loader";
import Moment from "moment";
import { Section } from "../../components/layout";
import { Text } from "../../components/typography";
import styled from "styled-components";
import { useEffect } from "react";

const FAQPage = () => {
  const { faqs, loading } = useSelector((state) => state.faq);
  const dispatch = useDispatch();

  const onDeleteFAQ = (data) => {
    const value = {
      id: data.id,
      data: data,
    };

    if (window.confirm(`Are you sure you want delete this?`)) {
      dispatch(DeleteFAQ(value));
    }
  };

  const ExpandeableActionsContainer = (props) => {
    const { data, ...otherProps } = props;

    return (
      <TableExpandContainer
        {...otherProps}
        main={
          <div>
            <div>
              <Text scale="small" color="gray">
                Question: {data.question}
              </Text>
            </div>
            <div>
              <Text scale="small" color="gray">
                Answer
              </Text>
              <Text>{data.answer}</Text>
            </div>
          </div>
        }
        sub={
          <>
            <Text scale="small" color="gray">
              Actions
            </Text>
            <div>
              <Link to={FAQEditRoute(data.id)} state={{ faq: data }}>
                <IoPencilOutline />
                <Text>Update</Text>
              </Link>
            </div>
          </>
        }
      />
    );
  };

  useEffect(() => {
    dispatch(FetchFAQs());
  }, [dispatch]);

  if (loading) {
    return <Loader />;
  }

  const columns = [
    {
      id: "createdDate",
      name: "Contacted Date",
      selector: ({ createdDate }) => Moment(createdDate).format("MMM DD, YYYY"),
      sortable: true,
      reorder: true,
      hide: "sm",
    },
    {
      name: "Question",
      sortable: true,
      selector: (row) => row.question,
    },
    {
      cell: (row) => (
        <DataRowActionsContainer>
          <IoTrashOutline onClick={() => onDeleteFAQ(row)} />
        </DataRowActionsContainer>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];

  return (
    <Section>
      <SubNav>
        <Button type="link" path={FAQCreateRoute}>
          Add FAQ
        </Button>
      </SubNav>
      <Table
        columns={columns}
        data={faqs}
        defaultSortFieldId="createdDate"
        expandableRowsComponent={({ data }) => {
          return (
            <ExpandeableActionsContainer data={data} onDelete={onDeleteFAQ} />
          );
        }}
      />
    </Section>
  );
};

export default FAQPage;

const DataRowActionsContainer = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0;
    padding: 0;
  }

  svg {
    margin-right: 1rem;
    cursor: pointer;

    &:first-child {
      color: red;
    }
  }
`;

const SubNav = styled.nav`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 2rem;

  li {
    margin-left: 1.5rem;
    display: flex;
    align-items: center;
  }
`;
