import { UserRefreshToken } from "../redux/UserSlice";
import axios from "axios";

const BaseRoute = "/userapi/api/v1";

export const eces = axios.create({
  baseURL: "https://api.excleanenviro.com/",
});

export const Interceptor = (store) => {
  const { dispatch, getState } = store;

  eces.interceptors.request.use(
    (config) => {
      const token = getState().user.data.token;

      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  eces.interceptors.response.use(
    (res) => {
      return res;
    },

    async (err) => {
      const originalConfig = err.config;

      if (originalConfig?.url !== `${BaseRoute}/auth/signin` && err.response) {
        if (
          (err.response.status === 401 || err.response.status === 403) &&
          !originalConfig._retry
        ) {
          originalConfig._retry = true;

          const refreshToken = getState()?.user?.data.refreshToken;

          try {
            if (refreshToken) {
              const response = await eces.post(
                `${BaseRoute}/auth/refreshToken`,
                {
                  refreshToken: refreshToken,
                }
              );

              dispatch(UserRefreshToken(response.data));
              return eces(originalConfig);
            }
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
      }
    }
  );
};
