import {
  DeleteStaff,
  DeleteStaffPictures,
  EditStaff,
} from "../../redux/StaffSlice";
import { useLocation, useNavigate } from "react-router-dom";

import { IoTrash } from "react-icons/io5";
import { Section } from "../../components/layout";
import { StaffBaseRoute } from "../../routes/Paths";
import StaffForm from "./StaffForm";
import { Text } from "../../components/typography";
import { onAlert } from "../../utils/onAlerts";
import styled from "styled-components";
import { useDispatch } from "react-redux";

const StaffProfileEdit = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onFormSubmit = (values) => {
    if (window.confirm(`Are you sure you want to save these edits?`)) {
      const args = {
        id: state.user.id,
        values: values,
      };
      dispatch(EditStaff(args));
    }
    onAlert("Edited Staff");
    navigate(StaffBaseRoute);
  };

  const onDeleteSubmit = () => {
    if (
      window.confirm(
        `Are you sure you want delete ${state.user.firstName} ${state.user.lastName}?`
      )
    ) {
      dispatch(DeleteStaff(state.user.id));
    }
  };

  const onHandleImageDelete = (fileName) => {
    const arg = {
      name: state.user?.media?.name,
      fileName: fileName,
    };
    dispatch(DeleteStaffPictures(arg));
  };
  return (
    <Section>
      <SubNav>
        <li onClick={onDeleteSubmit}>
          <IoTrash /> <Text>Delete</Text>
        </li>
      </SubNav>

      <StaffForm
        initialValues={state.user}
        onFormSubmit={onFormSubmit}
        onHandleImageDelete={onHandleImageDelete}
      />
    </Section>
  );
};

export default StaffProfileEdit;

const SubNav = styled.nav`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 2rem;

  li {
    margin-left: 1.5rem;
    display: flex;
    align-items: center;
    color: red;

    p {
      margin: 0;
      padding: 0 0 0 0.5rem;
      color: red;
    }

    &:hover {
      cursor: pointer;
    }
  }
`;
