import styled, { css } from "styled-components";

import { colors } from "../../assets/styles/parameters";

export const Span = ({ color, size, children, ...otherProps }) => {
  return (
    <SpanStyle color={color} size={size} {...otherProps}>
      {children}
    </SpanStyle>
  );
};

const SpanStyle = styled.span`
  margin-bottom: 1rem;

  ${({ size }) => {
    switch (size) {
      case "small":
        return css`
          font-size: 0.7rem;
        `;
      default:
        return css`
          font-size: inherit;
        `;
    }
  }}

  ${({ color }) => {
    switch (color) {
      case "white":
        return css`
          color: ${colors.white};
        `;
      case "gold":
        return css`
          color: ${colors.gold};
        `;
      case "gray":
        return css`
          color: ${colors.gray600};
        `;
      case "red":
        return css`
          color: red;
        `;
      default:
        return css`
          color: ${colors.black};
        `;
    }
  }}
`;
