import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { eces } from "../api";
import { onAlert } from "../utils/onAlerts";
import { toast } from "react-toastify";

const BASEPATH = "userapi/api/v1";

const INITIALSTATE = {
  loading: false,
  data: {},
  error: "",
};

const UserLoginFunc = async ({ usernameOrEmail, password }) => {
  const response = await eces.post(`${BASEPATH}/auth/signin`, {
    usernameOrEmail,
    password,
  });

  return response;
};

const GetLoggedInUserFunc = async ({ token, email }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const response = await eces.get(`${BASEPATH}/user/${email}`, config);

  return response;
};

export const UserLogin = createAsyncThunk(
  "user/login",
  async (args, { rejectWithValue, getState }) => {
    const { email, password } = args;

    try {
      const newFormValues = {
        usernameOrEmail: email,
        password: password,
      };

      const { headers } = await UserLoginFunc(newFormValues);
      const response = await GetLoggedInUserFunc(headers);

      const data = {
        token: headers.token,
        refreshToken: headers.refreshtoken,
        user: response.data,
      };

      return data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const UserSignUp = createAsyncThunk(
  "user/signup",
  async (args, { rejectWithValue, getState }) => {
    try {
      const response = await eces.post(`${BASEPATH}/auth/signup`, args);

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const UserReset = createAsyncThunk(
  "user/password/reset",
  async (args, { rejectWithValue, getState }) => {
    try {
      const response = await eces.post(
        `${BASEPATH}/user/initiate-password-reset`,
        args
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const UserResetConfirm = createAsyncThunk(
  "user/password/reset/confirm",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.patch(
        `${BASEPATH}/user/reset-password`,
        args
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const UserProfileUpdate = createAsyncThunk(
  "user/profile/update",
  async (args, { rejectWithValue, getState }) => {
    const { user } = getState();
    console.log(user);
    console.log("Updating User Information");

    const { firstName, lastName, email } = args.values;

    try {
      const response = await eces.patch(`${BASEPATH}/user/${args.email}`, {
        firstName,
        lastName,
        email,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const UserSlice = createSlice({
  name: "user",
  initialState: INITIALSTATE,
  reducers: {
    UserRefreshToken: (state, action) => {
      state.data.refreshtoken = action.payload;
      state.data.token = action.payload;
    },
    LogOut: (state) => {
      state.loading = false;
      state.error = "";
      state.data = [];
      localStorage.clear("persist:s98-2de-jds-99s-d");
    },
    Reset: () => INITIALSTATE,
  },
  extraReducers: {
    [UserLogin.pending]: (state) => {
      state.loading = true;
    },
    [UserLogin.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      toast.success(`Welcome ${action.payload.user.firstName}`);
    },
    [UserLogin.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [UserSignUp.pending]: (state) => {
      state.loading = true;
    },
    [UserSignUp.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
      toast.success(`Signed Up Successfully. Directing you to login page.`);
      setTimeout(() => {
        window.location.replace("/");
      }, "5000");
    },
    [UserSignUp.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.data = [];
      onAlert("Something bad happened. Try again!");
    },
    [UserProfileUpdate.pending]: (state) => {
      state.loading = true;
    },
    [UserProfileUpdate.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        user: action.payload,
      };
      toast.success(`User Profile Updated`);
    },
    [UserProfileUpdate.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [UserReset.pending]: (state) => {
      state.loading = true;
    },
    [UserReset.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.isSuccess = true;
      state.error = "";
    },
    [UserReset.rejected]: (state, action) => {
      state.loading = false;
      state.isSuccess = false;
      state.error = action.payload;
      state.data = [];
    },
  },
});

export const { LogOut, Reset, UserRefreshToken } = UserSlice.actions;

export default UserSlice.reducer;
