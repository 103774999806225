import { Text, Title } from "../typography";

import { ImageSquare } from "../images";
import { Link } from "react-router-dom";
import { colors } from "../../assets/styles/parameters";
import styled from "styled-components";

export const TeamCard = ({ name, title, image, imageAlt, path }) => {
  return (
    <BaseProfileCard>
      <Link to={path}>
        <ImageSquare img={image} imageAlt={imageAlt} scale="true" />
        <Title number={5}>{name}</Title>
        {title && <Text>{title}</Text>}
      </Link>
    </BaseProfileCard>
  );
};

const BaseProfileCard = styled.div`
  background-color: ${colors.white};
  margin-bottom: 1rem;
  transition: all 2s ease-in-out;

  a {
    color: ${colors.black};

    h5 {
      margin: 0.7rem 0 0.5rem;
    }
  }
  img {
    height: 458px;
    object-fit: cover;
  }
`;
