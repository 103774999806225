import { CardItem, Cards } from "../../components/cards";
import { Grid, Section } from "../../components/layout";
import { Text, Title } from "../../components/typography";

import { AppLinks } from "../../components/links";
import { ProfileEditRoute } from "../../routes/Paths";
import { useSelector } from "react-redux";

const ProfileDetailPage = () => {
  const { data } = useSelector((state) => state.user);
  const { firstName, lastName, media, email, role, username } = data?.user;

  return (
    <Section>
      <Grid col="l14">
        <Cards title="Extreme Clean Environmental Services" version="2" />
        <Cards
          title={`${firstName} ${lastName}`}
          version="2"
          action={
            <AppLinks
              to={ProfileEditRoute(`${firstName}-${lastName}`)}
              state={{ user: data?.user }}
            >
              Update Profile
            </AppLinks>
          }
        >
          <Grid col="l14">
            <CardItem version="5">
              <Title number={6}>Role</Title>
            </CardItem>
            <CardItem version="5">
              <Text>{role?.name?.toLowerCase()}</Text>
            </CardItem>
          </Grid>
          <Grid col="l14">
            <CardItem version="5">
              <Title number={6}>Username</Title>
            </CardItem>
            <CardItem version="5">
              <Text>{username}</Text>
            </CardItem>
          </Grid>
          <Grid col="l14">
            <CardItem version="5">
              <Title number={6}>Contact Information</Title>
            </CardItem>
            <CardItem version="5">
              <Grid>
                <Text>Email</Text>
                <Text>{email}</Text>
              </Grid>
            </CardItem>
          </Grid>
          <Grid col="l14">
            <CardItem version="5">
              <Title number={6}>Password</Title>
            </CardItem>
            <CardItem version="5">
              <Text>********</Text>
            </CardItem>
          </Grid>
        </Cards>
      </Grid>
    </Section>
  );
};

export default ProfileDetailPage;
