import {
  DeleteGalleryProject,
  EditGalleryProject,
  FetchGallery,
} from "../../redux/GallerySlice";
import {
  IoCheckboxOutline,
  IoCloseSharp,
  IoTrashOutline,
} from "react-icons/io5";
import { MediaCreateRoute, MediaEditRoute } from "../../routes/Paths";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "../../components/button";
import { IoPencilOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { Loader } from "../../components/loader";
import { Section } from "../../components/layout";
import { Table } from "../../components/table";
import { onFormatDateTime } from "../../utils/onFormatDateTime";
import styled from "styled-components";
import { useEffect } from "react";

const GalleryListPage = () => {
  const { data, loading } = useSelector((state) => state.gallery);
  const dispatch = useDispatch();

  const onUpdatedFeature = (values) => {
    const id = values.id;
    const featured = values.featured;

    const value = { ...values, featured: !featured };
    dispatch(EditGalleryProject({ id, value }));
  };

  const onDeleteItem = (id) => {
    if (window.confirm("Are you sure you want to delete?")) {
      dispatch(DeleteGalleryProject(id));
    }
  };

  useEffect(() => {
    dispatch(FetchGallery());
  }, [dispatch]);

  if (loading) {
    return <Loader />;
  }

  const columns = [
    {
      id: "created_date",
      name: "Created Date",
      selector: ({ createdDate }) => onFormatDateTime(createdDate),
      sortable: true,
    },
    {
      name: "Project Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Featured",
      sortable: true,
      center: true,
      selector: (row) => row.featured,
      cell: (row) => (
        <div onClick={() => onUpdatedFeature(row)}>
          {row.featured ? <IoCheckboxOutline /> : <IoCloseSharp />}
        </div>
      ),
    },
    {
      cell: (row) => (
        <DataRowActionsContainer>
          <Link to={MediaEditRoute(row.id)} state={{ gallery: row }}>
            <IoPencilOutline />
          </Link>
        </DataRowActionsContainer>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
    {
      cell: (row) => (
        <DataRowActionsContainer>
          <IoTrashOutline onClick={() => onDeleteItem(row.id)} />
        </DataRowActionsContainer>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];

  return (
    <Section>
      <SubNav>
        <Button type="link" path={MediaCreateRoute}>
          Add New Gallery
        </Button>
      </SubNav>
      <Table data={data} columns={columns} defaultSortFieldId="createdDate" />
    </Section>
  );
};

export default GalleryListPage;

const SubNav = styled.nav`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 2rem;

  li {
    margin-left: 1.5rem;
    display: flex;
    align-items: center;
  }
`;
const DataRowActionsContainer = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0;
    padding: 0;
  }

  svg {
    margin-right: 1rem;
    cursor: pointer;
  }
`;
