import BlogForm from "./BlogForm";
import { CreateBlog } from "../../redux/BlogSlice";
import { Section } from "../../components/layout";
import { useDispatch } from "react-redux";

const BlogCreatePage = () => {
  const dispatch = useDispatch();

  const onFormSubmit = (values) => {
    if (window.confirm(`Are you sure you want to add this article`)) {
      dispatch(CreateBlog(values));
    }
  };

  return (
    <Section>
      <BlogForm onFormSubmit={onFormSubmit} />
    </Section>
  );
};

export default BlogCreatePage;
