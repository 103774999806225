import { AutoLogout } from "./AutoLogout";
import { CredentialsPage } from "../pages";
import { Loader } from "../components/loader";
import { Meta } from "../components/meta";
import { Outlet } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Sidebar } from "../components/layout";
import { persistStore } from "redux-persist";
import { store } from "../redux/store";
import styled from "styled-components";
import { useSelector } from "react-redux";

const ProtectedRoutes = () => {
  const { data } = useSelector((state) => state.user);

  const persistor = persistStore(store);

  const isAuthorized = data.token;

  return isAuthorized ? (
    <AutoLogout>
      <PersistGate loading={<Loader />} persistor={persistor}>
        <Meta title="Dashboard" />
        <Sidebar />
        <Layout>
          <Outlet />
        </Layout>
      </PersistGate>
    </AutoLogout>
  ) : (
    <CredentialsPage />
  );
};

export default ProtectedRoutes;

const Layout = styled.div`
  display: grid;
  grid-template-areas:
    "header header header header"
    "main main main main"
    "main main main main"
    "main main main main";
  grid-template-columns: repeat(4, 1fr);
`;
