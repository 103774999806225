import { Layout, PageHeader } from "../components/layout";

import { Outlet } from "react-router-dom";

const ProtectedResetRoutes = () => {
  return (
    <>
      <PageHeader title="Protected Resets" />
      <Layout>
        <Outlet />
      </Layout>
    </>
  );
};

export default ProtectedResetRoutes;
