import { useDispatch, useSelector } from "react-redux";

import { ProfileForm } from "./ProfileForm";
import { Section } from "../../components/layout";
import { UserProfileUpdate } from "../../redux/UserSlice";

const ProfileEditPage = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.user);

  const onFormSubmit = (values) => {
    if (window.confirm(`Are you sure you want to save these edits?`)) {
      const args = {
        email: data.user.email,
        values,
      };
      dispatch(UserProfileUpdate(args));
    }
  };

  return (
    <Section>
      <ProfileForm initialValues={data.user} onFormSubmit={onFormSubmit} />
    </Section>
  );
};

export default ProfileEditPage;
