import {
  BlogCreatePage,
  BlogEditPage,
  BlogPage,
  BlogPageListPage,
} from "../pages";

import { Route } from "react-router-dom";

export const BlogRoutes = () => {
  return (
    <Route path="/auth/blog" element={<BlogPage />}>
      <Route index element={<BlogPageListPage />} />
      <Route path="create" element={<BlogCreatePage />} />
      <Route path="edit/:id" element={<BlogEditPage />} />
    </Route>
  );
};
