import { useDispatch, useSelector } from "react-redux";

import { EditTestimony } from "../../redux/TestimonySlice";
import { PopUpRight } from "../../components/popup";
import TestimonyForm from "./TestimonyForm";
import { onAlert } from "../../utils/onAlerts";
import { useEffect } from "react";

const DepartmentEditPopup = (props) => {
  const { open, close, initialValues } = props;
  const { status, error } = useSelector((state) => state.testimony);

  const dispatch = useDispatch();

  const onFormSubmit = (values) => {
    if (window.confirm(`Are you sure you want to save these edits?`)) {
      const newData = {
        ...values,
        client: values.client || "",
        message: values.message || "",
      };
      const args = { id: initialValues.id, value: newData };

      dispatch(EditTestimony(args));
    }
    close();
  };

  useEffect(() => {
    if (status === 201) {
      onAlert("success", "Testimony Edited");
    }

    if (error) {
      onAlert("error", error);
    }
  });

  return (
    <PopUpRight
      title="Edit Department Information"
      onOpenPopUpRight={open}
      onClosePopUpRight={close}
    >
      <TestimonyForm
        initialValues={initialValues}
        onFormSubmit={onFormSubmit}
        onClose={close}
      />
    </PopUpRight>
  );
};

export default DepartmentEditPopup;
