import { Layout } from "../../components/layout";
import SignupForm from "./SignUpForm";
import { useSelector } from "react-redux";

const SignUpPage = () => {
  const { errMessage } = useSelector((state) => state.user);

  return (
    <Layout>
      <SignupForm error={errMessage} />
    </Layout>
  );
};

export default SignUpPage;
