import { Field, Form } from "react-final-form";
import { FormContainer, Input } from "../../components/forms";
import { Grid, Section } from "../../components/layout";
import { Reset, UserResetConfirm } from "../../redux/UserSlice";
import { Text, Title } from "../../components/typography";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "../../components/button";
import { onAlert } from "../../utils/onAlerts";
import { useNavigate } from "react-router-dom";

const PasswordResetForm = () => {
  let { errMessage, isSuccess } = useSelector((state) => state.user);
  let navigate = useNavigate();
  let dispatch = useDispatch();

  const onSubmit = (values) => {
    dispatch(UserResetConfirm(values));

    if (isSuccess) {
      onAlert("success", "User Reset");
      dispatch(Reset());
      navigate(`/`);
    }
    if (errMessage) {
      onAlert("error", errMessage);
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={(values) => {
        const errors = {};

        if (!values.token || values.token.trim().length === 0) {
          errors.token = "Token required";
        }
        if (!values.password || values.password.trim().length === 0) {
          errors.password = "Password Required";
        } else if (values.password !== values.confirmPassword) {
          errors.confirmPassword = "Password must match";
        }

        return errors;
      }}
    >
      {({ handleSubmit, pristine, submitting, invalid }) => (
        <form onSubmit={handleSubmit}>
          <Title number={4}>Password Reset</Title>
          <Text scale="small">
            Authorized personnel only. All others will be prosecuted to the
            extent of the law.
          </Text>
          <FormContainer>
            <Field label="Token" name="token" type="text" component={Input} />
            <Grid>
              <Field
                label="New Password"
                name="password"
                type="password"
                component={Input}
                autoComplete="new-password"
                required
              />
              <Field
                label="Confirm New Password"
                name="confirmPassword"
                type="password"
                component={Input}
                autoComplete="new-password"
                required
              />
            </Grid>
          </FormContainer>
          <div>
            <Button type="submit" disabled={submitting || pristine || invalid}>
              {submitting ? "Submitting....." : "Reset Password"}
            </Button>
          </div>
        </form>
      )}
    </Form>
  );
};
const PasswordResetPage = () => {
  return (
    <Section>
      <PasswordResetForm />
    </Section>
  );
};

export default PasswordResetPage;
