import { Layout, NavLinkItem, PageHeader } from "../../components/layout";

import { Outlet } from "react-router-dom";

const SettingsPage = () => {
  return (
    <>
      <PageHeader title="Settings">
        <ul>
          <NavLinkItem path="/auth/user/settings" name="User Settings" />
          <NavLinkItem path="/auth/user/profile" name="User Profile" />
          <NavLinkItem path="/auth/user/update" name="Update Profile" />
        </ul>
      </PageHeader>
      <Layout>
        <Outlet />
      </Layout>
    </>
  );
};

export default SettingsPage;
