import {
  CustomTextArea,
  FormButton,
  FormContainer,
  Input,
} from "../../components/forms";
import { Field, Form } from "react-final-form";

import { Grid } from "../../components/layout";
import { Title } from "../../components/typography";

const CreateForm = (props) => {
  const { onFormSubmit, onClose } = props;

  const onCloseAndReset = (reset) => {
    onClose();
    reset();
  };

  return (
    <Form
      onSubmit={(values) => onFormSubmit(values)}
      validate={(formValues) => {
        const errors = {};
        if (!formValues.firstName || formValues.firstName.trim().length === 0) {
          errors.firstName = "First name required";
        }
        if (!formValues.lastName || formValues.lastName.trim().length === 0) {
          errors.lastName = "Last name required";
        }
        if (!formValues.email || formValues.email.trim().length === 0) {
          errors.email = "Email address required";
        } else if (
          formValues.email &&
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)
        ) {
          errors.email = "Invalid email address";
        }

        if (!formValues.phone || formValues.phone.trim().length === 0) {
          errors.phone = "Phone number required";
        } else if (
          formValues.phone &&
          !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i.test(
            formValues.phone
          )
        ) {
          errors.phone = "Invalid phone number";
        }
        if (!formValues.message || formValues.message.trim().length === 0) {
          errors.message = "A message required";
        }
        return errors;
      }}
    >
      {({ handleSubmit, pristine, submitting, invalid, form: { reset } }) => (
        <form onSubmit={handleSubmit}>
          <FormContainer>
            <Grid>
              <Field
                name="firstName"
                type="text"
                component={Input}
                label="First Name"
              />
              <Field
                name="lastName"
                type="text"
                component={Input}
                label="Last Name"
              />
            </Grid>
          </FormContainer>
          <FormContainer>
            <Field
              name="organization"
              type="text"
              component={Input}
              label="Organization"
            />
          </FormContainer>
          <FormContainer>
            <Title number={6}>Contact Information</Title>
            <Grid>
              <Field
                label="Phone Number"
                name="phone"
                type="phone"
                component={Input}
              />
              <Field
                label="Email"
                name="email"
                type="email"
                component={Input}
              />
            </Grid>
          </FormContainer>
          <FormContainer>
            <Title number={6}>Message</Title>
            <Field
              label="Reason for this message"
              name="message"
              type="text"
              component={CustomTextArea}
            />
          </FormContainer>

          <FormButton
            disabled={submitting || pristine || invalid}
            onCancelFunc={() => onCloseAndReset(reset)}
            btnMessage={submitting ? "Saving message" : "Save message"}
          />
        </form>
      )}
    </Form>
  );
};

export default CreateForm;
