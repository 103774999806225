import { CardItem, Cards } from "../../components/cards";
import {
  CustomTextArea,
  FormButton,
  FormContainer,
  Input,
  Label,
  RichTextField,
} from "../../components/forms";
import { Field, Form } from "react-final-form";
import { useLocation, useNavigate } from "react-router-dom";

import { FieldArray } from "react-final-form-arrays";
import { Grid } from "../../components/layout";
import { ImageUploadComponent } from "../../components/imageUpload";
import { IoBusiness } from "react-icons/io5";
import { OrgBaseRoute } from "../../routes/Paths";
import { Text } from "../../components/typography";
import arrayMutators from "final-form-arrays";

const OrgForm = (props) => {
  const { onFormSubmit, initialValues, onHandleImageDelete } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const onSubmit = (values) => {
    onFormSubmit(values);
  };

  const onCloseandReset = (reset) => {
    reset();

    const direction = location.pathname.split("/").includes("edit")
      ? navigate(-1, { replace: true })
      : navigate(OrgBaseRoute, { replace: true });
    return direction;
  };

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        mutators={{
          ...arrayMutators,
        }}
      >
        {({
          handleSubmit,
          submitting,
          pristine,
          invalid,
          form: {
            reset,
            mutators: { push },
          },
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid col="l15">
              <Cards title="Organization Information" version="2">
                <CardItem version="5">
                  <FormContainer>
                    <FormContainer>
                      <Field
                        name="name"
                        type="text"
                        component={Input}
                        label="Organization Name"
                        required
                      />
                    </FormContainer>
                    <FormContainer>
                      <Label htmlFor="mission" text="Mission" />
                      <Field name="mission" component={CustomTextArea} />
                    </FormContainer>
                    <FormContainer>
                      <Label htmlFor="vision" text="Vision" />
                      <Field name="vision" component={CustomTextArea} />
                    </FormContainer>
                    <FormContainer>
                      <Label htmlFor="about" text="About Us" />
                      <Field name="about" component={RichTextField} />
                    </FormContainer>
                  </FormContainer>
                  <FieldArray name="locations">
                    {({ fields }) => (
                      <>
                        {fields.map((location, index) => (
                          <div key={index}>
                            <FormContainer>
                              <Field
                                name={`${location}.street`}
                                type="text"
                                component={Input}
                                label="Street"
                              />
                              <Grid col={3}>
                                <Field
                                  name={`${location}.city`}
                                  type="text"
                                  component={Input}
                                  label="City"
                                />
                                <Field
                                  name={`${location}.state`}
                                  type="text"
                                  component={Input}
                                  label="State"
                                />
                                <Field
                                  name={`${location}.zipcode`}
                                  type="text"
                                  component={Input}
                                  label="Zip Code"
                                />{" "}
                              </Grid>
                            </FormContainer>
                            <Text
                              color="red"
                              onClick={() => fields.remove(index)}
                            >
                              Remove Address {index + 1}
                            </Text>
                          </div>
                        ))}
                      </>
                    )}
                  </FieldArray>
                  <Text
                    onClick={() => push("locations", undefined)}
                    disabled={submitting}
                  >
                    Add Company Location <IoBusiness />
                  </Text>
                </CardItem>
              </Cards>
              <FormContainer>
                <Label>Upload Images</Label>
                <Field name="files">
                  {(props) => {
                    return (
                      <ImageUploadComponent
                        name={props.input.name}
                        files={props.input.value}
                        onupdatefiles={props.input.onChange}
                        onremoveImage={onHandleImageDelete}
                        extraProp={initialValues?.media}
                      />
                    );
                  }}
                </Field>
              </FormContainer>
            </Grid>
            <FormButton
              disabled={submitting || pristine || invalid}
              onCancelFunc={() => onCloseandReset(reset)}
              btnMessage={submitting ? "Saving" : "Save"}
            />
          </form>
        )}
      </Form>
    </>
  );
};

export default OrgForm;
