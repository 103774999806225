import { GridGap, responsiveness } from "../../../assets/styles/parameters";

import styled from "styled-components";

const Grid = ({ col, children, ...otherProps }) => {
  switch (col) {
    case "l15":
      return <Col15 {...otherProps}>{children}</Col15>;
    case "l14":
      return <Col14 {...otherProps}>{children}</Col14>;
    case 4:
      return <Grid4 {...otherProps}>{children}</Grid4>;
    case 3:
      return <Grid3 {...otherProps}>{children}</Grid3>;
    default:
      return <Grid2 {...otherProps}>{children}</Grid2>;
  }
};

export default Grid;

const BaseStyle = styled.div`
  display: grid;
  gap: ${GridGap.default};

  @media screen and (max-width: ${responsiveness.mobile1}) {
    gap: 0;
  }
`;

const Col15 = styled(BaseStyle)`
  grid-template-columns: 1fr 0.5fr;

  @media screen and (max-width: ${responsiveness.mobile1}) {
    grid-template-columns: 1fr;
  }
`;

const Col14 = styled(BaseStyle)`
  grid-template-columns: 0.4fr 1fr;

  @media screen and (max-width: ${responsiveness.mobile1}) {
    grid-template-columns: 1fr;
  }
`;

const Grid4 = styled(BaseStyle)`
  grid-template-columns: repeat(4, 1fr);

  @media only screen and (min-width: ${responsiveness.ipadMin}) and (max-width: ${responsiveness.ipadMax}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: ${responsiveness.mobile1}) {
    grid-template-columns: 1fr;
  }
`;

const Grid3 = styled(BaseStyle)`
  grid-template-columns: repeat(3, 1fr);

  @media only screen and (min-width: ${responsiveness.ipadMin}) and (max-width: ${responsiveness.ipadMax}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: ${responsiveness.mobile1}) {
    grid-template-columns: 1fr;
  }
`;

const Grid2 = styled(BaseStyle)`
  grid-template-columns: repeat(2, 1fr);

  @media only screen and (max-width: ${responsiveness.mobile1}) {
    grid-template-columns: 1fr;
  }
`;
