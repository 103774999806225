import { GridGap, responsiveness } from "../../../assets/styles/parameters";

import styled from "styled-components";

const Layout = ({ layout, children, ...otherProps }) => {
  switch (layout) {
    case 3:
      return <Layout3 {...otherProps}>{children}</Layout3>;
    case 2:
      return <Layout2 {...otherProps}>{children}</Layout2>;
    default:
      return <Layout1 {...otherProps}>{children}</Layout1>;
  }
};

export default Layout;

const BaseLayout = styled.main`
  grid-area: main;
  padding: 2rem;

  @media screen and (max-width: ${responsiveness.mobile1}) {
    padding: 1rem;
  }
`;

const Layout1 = styled(BaseLayout)`
  display: grid;
  grid-template-columns: 1fr;

  @media screen and (max-width: ${responsiveness.mobile1}) {
    grid-template-columns: 1fr;
  }
`;

const Layout2 = styled(BaseLayout)`
  display: grid;
  grid-template-columns: 0.4fr 1fr;
  gap: ${GridGap.default};

  @media screen and (min-width: ${responsiveness.maxWidth}) {
    grid-template-columns: 0.3fr 1fr;
  }

  @media screen and (max-width: ${responsiveness.mobile1}) {
    grid-template-columns: 1fr;
  }
`;

const Layout3 = styled(BaseLayout)`
  display: grid;
  grid-template-columns: 0.35fr 1fr 0.35fr;

  @media screen and (max-width: ${responsiveness.mobile1}) {
    grid-template-columns: 1fr;
  }
`;
