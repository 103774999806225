import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { MediaBaseRouteDelete } from "./MediaSlice";
import { eces } from "../api";
import { onAlert } from "../utils/onAlerts";

const BASEPATH = "galleryapi/api/v1/galleries";
const INITIALSTATE = {
  data: [],
  isSuccess: false,
  error: "",
  loading: true,
};

// Loading Count Function
const onLoadingCount = (progressEvent) => {
  const percentCompleted = Math.round(
    (progressEvent.loaded / progressEvent.total) * 100
  );

  console.log(progressEvent.loaded);
  console.log("Loading Percentage", percentCompleted);

  if (percentCompleted === 100) {
    console.log("completed");
  }
};

export const FetchGallery = createAsyncThunk(
  "gallery/fetches",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.get(BASEPATH);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const FetchGalleryProject = createAsyncThunk(
  "gallery/fetch",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.get(`${BASEPATH}/${args.id}`, args.value);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const CreateGalleryProject = createAsyncThunk(
  "gallery/create",
  async (args, { rejectWithValue }) => {
    const { description, name, featured, files } = args;

    const formData = new FormData();
    formData.append("description", description || "");
    formData.append("name", name || "");
    formData.append("featured", featured);
    formData.append("mediaType", "IMAGE");
    if (files) {
      for (const file of files) {
        formData.append("files", file.file);
      }
    }

    try {
      const response = await eces.post(BASEPATH, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const EditGalleryProject = createAsyncThunk(
  "gallery/edit",
  async (args, { rejectWithValue, getState }) => {
    const { description, name, featured, files } = args.values;

    console.log(args);
    const formData = new FormData();
    formData.append("description", description || "");
    formData.append("name", name || "");
    formData.append("featured", featured);
    formData.append("mediaType", "IMAGE");
    if (files) {
      for (const file of files) {
        formData.append("files", file.file);
      }
    }
    const { token } = getState().user.data;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      onUploadProgress: onLoadingCount,
    };
    try {
      const response = await eces.patch(
        `${BASEPATH}/${args.id}`,
        formData,
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const DeleteGalleryProject = createAsyncThunk(
  "gallery/delete",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.delete(`${BASEPATH}/${args}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const DeleteGalleryPictures = createAsyncThunk(
  "client/delete/pictures",
  async (args, { rejectWithValue }) => {
    console.log(args);
    try {
      const response = await eces.delete(
        MediaBaseRouteDelete(args.name, args.fileName)
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const galleryReducer = createSlice({
  name: "gallery",
  initialState: INITIALSTATE,
  extraReducers: {
    [FetchGallery.pending]: (state) => {
      state.loading = true;
    },
    [FetchGallery.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.isSuccess = true;
    },
    [FetchGallery.rejected]: (state, action) => {
      state.loading = false;
      state.isSuccess = false;
      state.error = action.payload;
    },
    [FetchGalleryProject.pending]: (state) => {
      state.loading = true;
    },
    [FetchGalleryProject.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.isSuccess = true;
    },
    [FetchGalleryProject.rejected]: (state, action) => {
      state.loading = false;
      state.isSuccess = false;
      state.error = action.payload;
    },
    // [CreateGalleryProject.pending]: (state) => {
    //   state.loading = true;
    // },
    // [CreateGalleryProject.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.data = action.payload;
    //   state.isSuccess = true;
    // },
    // [CreateGalleryProject.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.isSuccess = false;
    //   state.error = action.payload;
    // },
    [DeleteGalleryProject.fulfilled]: (state) => {
      state.loading = false;
      state.isSuccess = true;
    },
    [DeleteGalleryPictures.fulfilled]: (state) => {
      state.loading = false;
      state.isSuccess = true;
      onAlert("success", "Image Deleted");
    },
  },
});

export default galleryReducer.reducer;
