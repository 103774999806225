import { Button } from "../../components/button";
import { Text } from "../../components/typography";
import styled from "styled-components";

export const FormButton = ({ btnMessage, onCancelFunc, disabled }) => {
  return (
    <FlexContent>
      <Text scale="small" onClick={onCancelFunc}>
        Cancel
      </Text>
      <Button type="submit" disabled={disabled}>
        {btnMessage}
      </Button>
    </FlexContent>
  );
};

const FlexContent = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  p {
    margin-right: 1rem;
    margin-bottom: 0;
  }

  & > button {
    margin-bottom: 0;
    margin-left: 1rem;
  }
`;
