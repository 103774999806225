import { Layout, PageHeader } from "../../components/layout";

import { Outlet } from "react-router-dom";

const ProfilePage = () => {
  return (
    <>
      <PageHeader title="Profile" />
      <Layout>
        <Outlet />
      </Layout>
    </>
  );
};

export default ProfilePage;
