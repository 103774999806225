import { DeleteFAQ, EditFAQ } from "../../redux/FAQSlice";

import FAQForm from "./FAQForm";
import { IoTrash } from "react-icons/io5";
import { Section } from "../../components/layout";
import { Text } from "../../components/typography";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

export const FAQEdit = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();

  const onFormSubmit = (values) => {
    if (window.confirm(`Are you sure you want to save these edits?`)) {
      const args = {
        id: state.faq.id,
        values: values,
      };
      dispatch(EditFAQ(args));
    }
  };

  const onDeleteSubmit = () => {
    const value = {
      id: state.faq.id,
      data: state.faq,
    };

    if (window.confirm(`Are you sure you want delete this?`)) {
      dispatch(DeleteFAQ(value));
    }
  };

  return (
    <Section>
      <SubNav>
        <li onClick={onDeleteSubmit}>
          <IoTrash /> <Text>Delete</Text>
        </li>
      </SubNav>

      <FAQForm initialValues={state.faq} onFormSubmit={onFormSubmit} />
    </Section>
  );
};

export default FAQEdit;

const SubNav = styled.nav`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 2rem;

  li {
    margin-left: 1.5rem;
    display: flex;
    align-items: center;
    color: red;

    p {
      margin: 0;
      padding: 0 0 0 0.5rem;
      color: red;
    }

    &:hover {
      cursor: pointer;
    }
  }
`;
