import { ContactListPage, ContactPage } from "../pages";

import { Route } from "react-router-dom";

export const ContactMessagesRoutes = () => {
  return (
    <Route path="/auth/messages" element={<ContactPage />}>
      <Route index element={<ContactListPage />} />
    </Route>
  );
};
