import { Text } from "../typography";
import { colors } from "../../assets/styles/parameters";
import styled from "styled-components";

const Loader = () => {
  return (
    <BaseLoader>
      <BaseWrapper>
        <p>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
        </p>
      </BaseWrapper>
      <Text>Loading</Text>
    </BaseLoader>
  );
};

export default Loader;

const BaseLoader = styled.ul`
  background: ${colors.blueDark2};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    color: ${colors.white};
    font-size: 1.2rem;
  }
`;

const inner = 10;
const border = 6;
const spacing = 1;
const color = ["white", "white", "#f8b739", colors.blueDark2, "green", "white"];

const size = inner + color.length * (border + spacing) * 2;

const BaseWrapper = styled.li`
  & > p {
    animation: rotate 10s infinite linear;
    position: relative;
    display: block;
    margin: auto;
    width: ${size}px;
    height: ${size}px;

    & > i {
      animation: rotate 3s infinite cubic-bezier(0.09, 0.6, 0.8, 0.03);
      position: absolute;
      display: inline-block;
      top: 50%;
      left: 50%;
      border: ${border}px solid transparent;
      border-bottom: none;
      transform-origin: 50% 100% 0;

      ${() => {
        let templateLiteral = " ";

        color.forEach((element, i) => {
          const size = inner + i * (border + spacing) * 2;

          templateLiteral += `
          &:nth-child(${i}) {
            animation-timing-function: cubic-bezier(0.09, calc(0.3 * ${i}), calc(0.3 * ${i}), 0.01);
            width: ${size}px;
            height: calc(${size}px / 2);
            margin-top: calc(-${size}px / 2);
            margin-left: calc(-${size}px / 2);
            border-color: ${color[i]};
            border-top-left-radius: calc(${inner}px + ${i} * ${border}px);
            border-top-right-radius: calc(${inner}px + ${i} * ${border}px);
            }
          `;
        });

        return templateLiteral;
      }}
    }
  }

  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
`;
