import { CardItem, Cards } from "../../components/cards";
import {
  CustomCalendar,
  CustomSelect,
  CustomTextArea,
  FormButton,
  FormContainer,
  Input,
} from "../../components/forms";
import { Field, Form } from "react-final-form";
import {
  IoCalendarSharp,
  IoCheckmarkCircleSharp,
  IoHome,
  IoPersonAddSharp,
  IoTimeSharp,
} from "react-icons/io5";
import { Span, Text, Title } from "../../components/typography";
import { useLocation, useNavigate } from "react-router-dom";

import { BookingBaseRoute } from "../../routes/Paths";
import BookingServiceCard from "./BookingServiceCard";
import { Grid } from "../../components/layout";
import { colors } from "../../assets/styles/parameters";
import { onFormatDateTime } from "../../utils/onFormatDateTime";
import styled from "styled-components";

const BookingForm = (props) => {
  const { onFormSubmit, initialValues, onServicesList } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const onCloseandReset = (reset) => {
    reset();

    const direction = location.pathname.split("/").includes("edit")
      ? navigate(-1, { replace: true })
      : navigate(BookingBaseRoute, { replace: true });
    return direction;
  };

  return (
    <>
      <Form onSubmit={onFormSubmit} initialValues={initialValues}>
        {({
          handleSubmit,
          pristine,
          submitting,
          invalid,
          form: { reset },
          ...otherProps
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid col="l15">
              <Cards title="Booking Form" version="2">
                <CardItem version="5">
                  {initialValues?.status && (
                    <FormContainer>
                      <Field
                        name="status"
                        component={CustomSelect}
                        label="Project Status"
                      >
                        <option value="PENDING">Pending</option>
                        <option value="IN_PROGRESS">In Progress</option>
                        <option value="COMPLETED">Completed</option>
                      </Field>
                    </FormContainer>
                  )}
                  <FormContainer>
                    <div>
                      <label>Select Services</label>
                      {onServicesList &&
                        onServicesList?.map((s) => {
                          return (
                            <CardItem key={s.title}>
                              <BookingServiceCard data={s} />
                            </CardItem>
                          );
                        })}
                    </div>
                  </FormContainer>
                  <FormContainer>
                    <Grid>
                      <Field
                        name="date"
                        type="text"
                        component={CustomCalendar}
                        label="Select date and time of service."
                      />
                    </Grid>
                  </FormContainer>
                  <FormContainer>
                    <Title number={6}>Service address</Title>

                    <Field
                      label="Street Address"
                      name="street"
                      type="text"
                      component={Input}
                    />
                    <Grid col={3}>
                      <Field
                        label="City"
                        name="city"
                        type="text"
                        component={Input}
                      />
                      <Field
                        label="state"
                        name="state"
                        type="text"
                        component={Input}
                      />
                      <Field
                        label="zipcode"
                        name="zipcode"
                        type="text"
                        component={Input}
                      />
                    </Grid>
                  </FormContainer>
                  <FormContainer>
                    <Title number={6}>Customer detail</Title>
                    <FormContainer>
                      <Grid>
                        <Field
                          name="firstName"
                          type="text"
                          component={Input}
                          label="First Name"
                        />
                        <Field
                          name="lastName"
                          type="text"
                          component={Input}
                          label="Last Name"
                        />
                      </Grid>
                      <Grid>
                        <Field
                          name="organization"
                          type="text"
                          component={Input}
                          label="Organization"
                        />
                        <Field
                          name="title"
                          type="text"
                          component={Input}
                          label="Title"
                        />
                      </Grid>
                      <Grid>
                        <Field
                          label="Email"
                          name="email"
                          type="email"
                          component={Input}
                        />{" "}
                        <Field
                          label="Phone Number"
                          name="phone"
                          type="phone"
                          component={Input}
                        />
                      </Grid>
                    </FormContainer>
                  </FormContainer>
                  <FormContainer>
                    <Title number={6}>Instructions to Extreme Team</Title>
                    <Field
                      label="Special Instructions for this project"
                      name="additionalComments"
                      type="text"
                      component={CustomTextArea}
                    />
                  </FormContainer>
                </CardItem>
              </Cards>

              <div>
                <Cards title="Booking Summary" version="2">
                  <CardItem title="Selected Services(s)">
                    <SummaryServices>
                      {otherProps.values.services &&
                        otherProps.values.services.map((s, index) => {
                          return (
                            <div key={index}>
                              <IoCheckmarkCircleSharp />
                              <Text>{s && s !== undefined && s}</Text>
                            </div>
                          );
                        })}
                    </SummaryServices>
                  </CardItem>
                  <CardItem title="Service Date and Time">
                    <SummaryServices>
                      <div>
                        <IoCalendarSharp />

                        {otherProps.values.date &&
                          onFormatDateTime(otherProps.values.date)}
                      </div>
                      <div>
                        <IoTimeSharp />
                        {otherProps.values.date &&
                          onFormatDateTime(otherProps.values.date, "time")}
                      </div>
                    </SummaryServices>
                  </CardItem>
                  <CardItem title="Service Address">
                    <SummaryServices>
                      <div>
                        <IoHome />
                        <Text>
                          {
                            <>
                              {otherProps.values &&
                                `${
                                  otherProps.values.street
                                    ? otherProps.values.street
                                    : ""
                                } 
                        `}
                              <br />
                              {otherProps.values &&
                                ` 
                        ${
                          otherProps.values.city
                            ? `${otherProps.values.city},`
                            : ""
                        } ${
                                  otherProps.values.state
                                    ? otherProps.values.state
                                    : ""
                                } ${
                                  otherProps.values.zipcode
                                    ? otherProps.values.zipcode
                                    : ""
                                }`}
                            </>
                          }
                        </Text>
                      </div>
                    </SummaryServices>
                  </CardItem>
                  <CardItem title="Your Information">
                    <SummaryServices>
                      <div>
                        <IoPersonAddSharp />
                        <Text>
                          {
                            <>
                              <Span>
                                {otherProps.values &&
                                  otherProps.values.firstName}{" "}
                                {otherProps.values &&
                                  otherProps.values.lastName}
                              </Span>
                              <br />
                              <Span>
                                {otherProps.values && otherProps.values.email}{" "}
                              </Span>{" "}
                              <br />
                              <Span>
                                {otherProps.values && otherProps.values.phone}{" "}
                              </Span>
                              <br />
                            </>
                          }
                        </Text>
                      </div>
                    </SummaryServices>
                  </CardItem>
                  <CardItem>
                    <FormButton
                      disabled={submitting || pristine || invalid}
                      onCancelFunc={() => onCloseandReset(reset)}
                      btnMessage={
                        submitting ? "Saving Booking" : "Save Booking"
                      }
                    />
                  </CardItem>
                </Cards>
              </div>
            </Grid>
          </form>
        )}
      </Form>
    </>
  );
};

export default BookingForm;

const SummaryServices = styled.div`
  div {
    display: flex;
    margin-bottom: 0.5rem;
    align-items: flex-start;

    svg {
      fill: ${colors.green};
      margin: 0.2rem 0.6rem 0 0;
      align-self: flex-start;
    }

    p {
      margin: 0;
      padding: 0;
    }
  }
`;
