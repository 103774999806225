import { connect, useDispatch, useSelector } from "react-redux";

import BookingForm from "./BookingForm";
import { CreateBooking } from "../../redux/BookingSlice";
import { FetchServices } from "../../redux/ServiceSlice";
import { Loader } from "../../components/loader";
import { Section } from "../../components/layout";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const BookingCreatePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(FetchServices());
  }, [dispatch]);

  let { services, clients } = useSelector((state) => state);

  const onFormSubmit = (values) => {
    if (window.confirm(`Are you sure you want to add this booking`)) {
      const {
        firstName,
        lastName,
        email,
        phone,
        title,
        organization,
        street,
        city,
        state,
        zipcode,
        services,
        date,
        additionalComments,
      } = values;

      const newValues = {
        client: { firstName, lastName, email, phone, title, organization },
        location: { street, city, state, zipcode },
        services,
        date,
        additionalComments,
      };

      dispatch(CreateBooking(newValues));
    }
  };

  return (
    <Section>
      {services.loading && !services.data && !clients.data ? (
        <Loader />
      ) : (
        <BookingForm
          onFormSubmit={onFormSubmit}
          onServicesList={services.data}
          onClientList={clients.data}
        />
      )}
    </Section>
  );
};

const mapStateToProps = (state) => {
  return { booking: state.booking };
};

export default connect(mapStateToProps, { CreateBooking })(BookingCreatePage);
