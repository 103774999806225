import {
  GalleryCreatePage,
  GalleryDetailPage,
  GalleryEditPage,
  GalleryListPage,
  GalleryPage,
} from "../pages";

import { Route } from "react-router-dom";

export const GalleryRoutes = () => {
  return (
    <Route path="/auth/gallery" element={<GalleryPage />}>
      <Route index element={<GalleryListPage />} />
      <Route path="create" element={<GalleryCreatePage />} />
      <Route path="edit/:id" element={<GalleryEditPage />} />
      <Route path=":id" element={<GalleryDetailPage />} />
    </Route>
  );
};
