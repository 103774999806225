import { ClientCreateRoute, ClientDetailRoute } from "../../routes/Paths";
import { useDispatch, useSelector } from "react-redux";

import { AppLinks } from "../../components/links";
import { Button } from "../../components/button";
import DataTable from "react-data-table-component";
import { FetchClients } from "../../redux/ClientSlice";
import { Section } from "../../components/layout";
import { onFormatDateTime } from "../../utils/onFormatDateTime";
import styled from "styled-components";
import { useEffect } from "react";

const ClientListPage = () => {
  const { data } = useSelector((state) => state.clients);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchClients());
  }, [dispatch]);

  const columns = [
    {
      id: "created_date",

      name: "Created Date",
      sortable: true,
      selector: ({ createdDate }) => onFormatDateTime(createdDate),
      hide: "sm",
    },
    {
      name: "First Name",
      sortable: true,
      selector: (row) => row.firstName,
    },
    {
      name: "Last Name",
      sortable: true,
      selector: (row) => row.lastName,
      hide: "sm",
    },
    {
      name: "Phone",
      sortable: true,
      selector: (row) => row.phone,
      hide: "md",
    },
    {
      name: "Email",
      sortable: true,
      selector: (row) => row.email,
      hide: "md",
    },
    {
      name: "Actions",
      right: true,
      selector: (row) => (
        <AppLinks to={ClientDetailRoute(row.id)} state={{ client: row }}>
          View
        </AppLinks>
      ),
    },
  ];

  return (
    <>
      <Section>
        <SubNav>
          <Button type="link" path={ClientCreateRoute}>
            Add New Contact
          </Button>
        </SubNav>
        <DataTable
          columns={columns}
          data={data}
          persistTableHead="true"
          pagination
          highlightOnHover
          compact
          fixedHeader
          defaultSortAsc={false}
          defaultSortFieldId="created_date"
          responsive={true}
        />
      </Section>
    </>
  );
};

export default ClientListPage;

const SubNav = styled.nav`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 2rem;

  li {
    margin-left: 1.5rem;
  }
`;
