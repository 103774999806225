import { CardItem, Cards } from "../../components/cards";
import { Field, Form } from "react-final-form";
import { FormButton, FormContainer, Input } from "../../components/forms";
import { useLocation, useNavigate } from "react-router-dom";

import { CredBaseRoute } from "../../routes/Paths";
import { ImageUploadComponent } from "../../components/imageUpload";

const CredCreateForm = (props) => {
  const { onFormSubmit, initialValues, onHandleImageDelete } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const onSubmit = (values) => {
    onFormSubmit(values);
  };

  const onCloseandReset = (reset) => {
    reset();

    const direction = location.pathname.split("/").includes("edit")
      ? navigate(-1, { replace: true })
      : navigate(CredBaseRoute, { replace: true });
    return direction;
  };
  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit, pristine, submitting, invalid, form: { reset } }) => (
        <form onSubmit={handleSubmit}>
          <Cards title="Recognition and Credentials Form" version="2">
            <CardItem version="5">
              <FormContainer>
                <Field name="files">
                  {(props) => {
                    return (
                      <ImageUploadComponent
                        name={props.input.name}
                        files={props.input.value}
                        onupdatefiles={props.input.onChange}
                        onremoveImage={onHandleImageDelete}
                        extraProp={initialValues?.media}
                        maxUpload={1}
                      />
                    );
                  }}
                </Field>
              </FormContainer>
              <FormContainer>
                <Field
                  name="title"
                  type="text"
                  component={Input}
                  label="Title of Recognition or Certification"
                />
              </FormContainer>
            </CardItem>
          </Cards>
          <FormButton
            disabled={submitting || pristine || invalid}
            onCancelFunc={() => onCloseandReset(reset)}
            btnMessage={submitting ? "Saving" : "Save"}
          />
        </form>
      )}
    </Form>
  );
};

export default CredCreateForm;
